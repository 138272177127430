import { Component, OnInit } from '@angular/core';
import { InquiryService } from 'src/app/modules/inquiry/service/inquiry.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { flatten } from '@angular/compiler';
import { ActionMode } from 'src/app/models/enum';
import { DataTransferDTO } from 'src/app/models/DataTransferDTO.model';
import { KeyValuePair } from 'src/app/models/KeyValuePair.model';
import { ListUser } from 'src/app/models/listModel.model';
import { CommonDropdownModel } from 'src/app/models/CommonDropdownModel.model';
import * as _ from 'lodash';
import swal from 'sweetalert';
import { UserService } from '../../user/user.service';
@Component({
  selector: 'app-assign-lead',
  templateUrl: './assign-lead.component.html',
  styleUrls: ['./assign-lead.component.css']
})
export class AssignLeadComponent implements OnInit {
  public userList: Array<ListUser>;
  public filterData: ListUser = new ListUser();
  public inquiryData: any = [];
  p: number = 1;
   public showError = false;
  public firmList: Array<CommonDropdownModel>;
  id: number;
  inquiryIds: any = [];
  AssignedById: any = [];
  FirmId: any = [];
  users: any = [];
  selectedValue: any = 0;
  noCheck: boolean = false;
  checks: boolean = false;

  constructor(private _inquiryService: InquiryService,
    private formBuilder: FormBuilder,
    private _commonService: CommonService,
    private _toasterService: ToasterService,
    private _userService: UserService,
    private router: Router) { }

  ngOnInit(): void {
    this.BindUser();
   
    this.AssignedById = localStorage.getItem("Id");
  }
  BindUser() {
    var self = this;
    let filterData: KeyValuePair = { key: "id", value: null };
    self._inquiryService.getInquiry(filterData).subscribe(response => {
      if (response.IsSuccess) {
        this.inquiryData = response.Data;
      }
      else {
        this.inquiryData = [];
      }
    });
  }
  bulk(e) {
    var checkboxes = document.getElementsByClassName('custom-control-input');
    var vals = "";
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i].id) {
        this.checks = false;
        var matches = checkboxes[i].id.match(/(\d+)/);
        if (matches && this.inquiryIds.indexOf(parseInt(matches[0])) === -1) {
          this.checks = true;
          this.inquiryIds.push(parseInt(matches[0]));
        }

      }
    }
    // console.log(e.target.checked);
    if (e.target.checked == true) {
      this.checks = true;
      this.noCheck = true;
    }
    else {
      this.checks = false;
      this.noCheck = false;
    }
  }
  getInquiryId(e, id) {
    if (e.target.checked == true && this.inquiryIds.indexOf(id) === -1) {
     //('enter');
      //  this.noCheck = true;
      this.inquiryIds.push(id);
    }
    if (e.target.checked == false && this.inquiryIds.indexOf(id) === -1) {
      this.noCheck = false;
    }
    else if (e.target.checked == false && this.inquiryIds.indexOf(id) > -1) {
     // console.log('enter2');
      this.checks = false;
      this.noCheck = false;
      this.inquiryIds = this.inquiryIds.filter(function (value, index, arr) {
        return value !== id;
      });
    }


  }
  BindRoleList(UserRoleId: number) {
    let dataToPass: KeyValuePair = { key: "UserRoleId", value: String(UserRoleId) };
    this._userService.getUsersByRole(dataToPass).subscribe(response => {
      if (response.IsSuccess) {
        this.users = response.Data;
      }
    });
  }
  assignlead() {
    this.showError = false;
    let detail = {
      'AssignedToUserId': this.selectedValue,
      'LeadIdList': this.inquiryIds,
      'AssignedBy': this.AssignedById
    };
    this._inquiryService.assignData(detail).subscribe(res => {
      if (res.IsSuccess) {
        this.BindUser();
        this.selectedValue = 0;

        this._toasterService.pop('success', "Success", res.Message);
      }
      else {
        this._toasterService.pop('error', 'Error', res.Message);

      }
    });
  }
  PreviewRecord(currentRow: ListUser) {
    if (currentRow && currentRow.Id > 0) {
      let dataTransferDTO: DataTransferDTO = new DataTransferDTO();
      dataTransferDTO.Id = currentRow.Id;
      dataTransferDTO.ActionMode = ActionMode.Preview;
      this._inquiryService.setUsersToService(dataTransferDTO);
      this.router.navigateByUrl('inquiry/add-inquiry');
    }
  }
  EditRecord(currentRow: ListUser) {
    if (currentRow && currentRow.Id > 0) {
      let dataTransferDTO: DataTransferDTO = new DataTransferDTO();
      dataTransferDTO.Id = currentRow.Id;
      dataTransferDTO.ActionMode = ActionMode.Edit;
      this._inquiryService.setUsersToService(dataTransferDTO);
      this.router.navigateByUrl('inquiry/add-inquiry');
    }

  }
  DeleteRecord(Id: number) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      dangerMode: true
    })
      .then((willDelete) => {
        if (willDelete) {
          let dataToPass: KeyValuePair = { key: "id", value: String(Id) };
          this._inquiryService.deleteUserById(dataToPass).subscribe(response => {
            if (response.IsSuccess) {
              this._toasterService.pop('success', "Success", response.Message);
              this.BindUser();
            }

            else {
              this._toasterService.pop('error', 'Error', response.Message);
            }
          });
        } else {
          swal("Your file is safe!");
        }
      });
  }
}
