import { Component, OnInit } from '@angular/core';
import { ActionMode } from 'src/app/models/enum';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { InquiryService } from 'src/app/modules/inquiry/service/inquiry.service';
import { ToasterService } from 'angular2-toaster';
import { CommonDropdownModel } from 'src/app/models/CommonDropdownModel.model';
import { ListUser } from 'src/app/models/listModel.model';
import { DataTransferDTO } from 'src/app/models/DataTransferDTO.model';

import { KeyValuePair } from 'src/app/models/KeyValuePair.model';
@Component({
  selector: 'app-add-inquiry',
  templateUrl: './add-inquiry.component.html',
  styleUrls: ['./add-inquiry.component.css']
})
export class AddInquiryComponent implements OnInit {
  public inquiryForm: FormGroup;
  public userLeadSourace: Array<CommonDropdownModel>;
  public userList: Array<ListUser>;
  public filterData: ListUser = new ListUser();
  public submitted = false;
  public actionMode: ActionMode = ActionMode.Add;
  dataTransferDTO: DataTransferDTO;
  user: any;
  constructor(public formbuilder: FormBuilder, private _toasterService: ToasterService, public inquiryService: InquiryService) { }

  ngOnInit(): void {
    this.InitializeForm();
    this.BindUserLeadSource();
    this.ReceiveTransmittedData();
  }
  get f() { return this.inquiryForm.controls; }
  public get ActionModeEnum(): typeof ActionMode {
    return ActionMode;
  }
  InitializeForm() {
    this.inquiryForm = new FormGroup({
      FirstName: new FormControl('', Validators.required),
      LastName: new FormControl('', Validators.required),
      Email: new FormControl('',[Validators.required,,Validators.email]),
      Mobile: new FormControl('',[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      Address: new FormControl('', Validators.nullValidator),
      City: new FormControl('', Validators.required),
      Source: new FormControl('', (Validators.required)),
      Remark: new FormControl('', Validators.required),
    });
  }
  BindUserLeadSource() {
    var self = this;
    self.inquiryService.getLeadSourace().subscribe((response) => {
      self.userLeadSourace = (response.IsSuccess && response.Data != null && response.Data.length > 0) ? response.Data : [];
      
    });
  }
  Reset() {
    this.submitted = false;
    this.inquiryForm.reset();
    this.InitializeForm();
  }
  onSubmit() {
    this.submitted = true;
    if (this.inquiryForm.invalid) {
      this.inquiryForm.markAsTouched();
      return;
    }
    else {
      this.inquiryService.addinquiry(this.inquiryForm.value).subscribe(response => {
       // console.log(response);

        if (response.IsSuccess) {
          this._toasterService.pop('success', "Success", response.Message);
          this.Reset();
        }
        else {
          this._toasterService.pop('error', 'Error', response.Message);

        }
      });
    }

  }
  EditRecord(Id: number) {
    var self = this;
    let filterData: KeyValuePair = { key: "id", value: String(Id) };
    self.inquiryService.getInquiry(filterData).subscribe((response) => {
      if (response.IsSuccess) {
        self.user = response.Data[0];
        self.inquiryForm.patchValue({
          FirstName: self.user.FirstName,
          LastName: self.user.LastName,
          Email: self.user.Email,
          Mobile: self.user.Mobile,
          Address: self.user.Address,
          City: self.user.City,
          Source: parseInt(self.user.Source),
          Remark: self.user.Remark,
        });
        self.actionMode = ActionMode.Edit;
      }
    });
  }
  SetValueAndUpdateValidation() {
    this.inquiryForm.setControl("FirstName", new FormControl({ value: this.user.FirstName, disabled: true }, { validators: [Validators.nullValidator] }));
    this.inquiryForm.setControl("LastName", new FormControl({ value: this.user.LastName, disabled: true }, { validators: [Validators.required] }));
  }


  PreviewRecord(Id: number) {
    var self = this;
    let filterData: KeyValuePair = { key: "id", value: String(Id) };
    self.inquiryService.getInquiry(filterData).subscribe((response) => {
      //console.log('PreviewRecord : ', response);
      if (response.IsSuccess) {
        self.user = response.Data[0];
      //  console.log('self.user', self.user)

        self.inquiryForm.patchValue({
          FirstName: self.user.FirstName,
          LastName: self.user.LastName,
          Email: self.user.Email,
          Mobile: self.user.Mobile,
          Address: self.user.Address,
          City: self.user.City,
          Source: parseInt(self.user.Source),
          Remark: self.user.Remark,
        });
        //this.SetValueAndUpdateValidation();
        this.inquiryForm.disable();
        self.actionMode = ActionMode.Preview;
      }
    });
  }
  ReceiveTransmittedData() {
    this.dataTransferDTO = this.inquiryService.getUsersFromService();
    if (this.dataTransferDTO) {
      this.inquiryService.resetUsersToService();
      switch (this.dataTransferDTO.ActionMode) {
        case ActionMode.Edit:
          this.EditRecord(this.dataTransferDTO.Id);
          break;
        case ActionMode.Preview:
          this.PreviewRecord(this.dataTransferDTO.Id);
          break;

        default:

          break;
      }
    }
  }
}

