<div class="container-fluid">
<div class="banner-img">
    <img src="../../../../../assets/images/dashboard-banner.png"/>

</div>
 <div class="row" >
    <div  class="col-lg-4 col-6" *ngFor="let user of dashBoardData;" >
     
      <div class="small-box" [ngStyle]="{'background-color': user.BackgroundColor}">
        <div  class="inner">
          <h3>{{user.TotalRecords}}</h3>

          <p  >{{user.StageName}} </p>
        </div>
        <div class="footer">
    
<p class="bt-4" (click)="dashboard(user.LeadStageId)"> <i class="fa fa-link" aria-hidden="true"></i>
  Click here to view List </p>
        </div>
        <div class="icon">
          <i [ngClass]="user.BackgroundIcon"></i>
        </div>
      </div>
    </div>
   
  </div>
    <!-- <div class="row bg-clr">
        <div class="col-md-3 disply"   *ngFor="let user of dashBoardData;" >

            <div class=" top-alin">
                <p class="mar-bottom"><i class="far fa-star m-r-8"></i> <b>{{user.StageName}} </b></p>
                <p class="b-top"> <b>{{user.TotalRecords}} </b></p>
            </div>
        </div>
    </div> -->
</div>
 