<!-- Main content -->
<section class="content add-detail">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body d-inline">
                        <div class="row ">

                            <div class="col-md-6 border-box">

                            </div>


                            <div class="col-md-6 border-box">
                                <h6 class="top-head"><strong>Prepaid/COD Order</strong></h6>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="card card-style">
                                            <div class="card-body"><img class="img"  src="../../../assets/images/AABBCCDDEEFF.png"> </div>
                                        </div>

                                        <h6><strong>ORDER DATE</strong></h6>

                                        <p>November,21,2021</p>

                                        <h4 class="haed-bottom">Ship To</h4>
                                        <h6>Usha</h6>

                                        <h6>plot no 7, Sri Chakra Enclave,
                                            Sainikpuri,Jaipur
                                            Rajasthan
                                        </h6>

                                        <h6> Pin: 203922</h6>
                                        <h6> Mo. : 9493020506</h6>
                                    </div>


                                    <div class="col-md-6 ">
                                        <div class="card card-style">
                                            <div class="card-body">tracking bar code </div>
                                        </div>
                                        <h6><strong>SHIP DATE</strong></h6>
                                        <p>November,21,2021</p>

                                        <h5 class="top-margin">From/Return To</h5>
                                        <h6> Usha</h6>

                                        <h6>plot no 7, Sri CHakra Enclave,
                                            Sainikpuri,Jiapur</h6>
                                        <h6>Pin :765689</h6>
                                        <h6>Mob.:764355689</h6>
                                    </div>
                                </div>
                                <hr top-border>
                                <div class="row">
                                    <div class="  col-md-6">
                                        <h5>Package includes.</h5>
                                        <h5>5 Item</h5>
                                    </div>
                                    <div class="  col-md-6">
                                        <h5>COD Amount.</h5>
                                        <h5>500</h5>
                                    </div>
                                </div>
                                <hr class="top-border">
                                <h5 class="top-head">Thanks!</h5>
                                <p><strong>Notes:</strong> These are normally smaller shipments that are sent via DHL,
                                    UPS, FedEx.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
    </div>
    </div>
</section>