import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TestManageRolesComponent } from './test-manage-roles/test-manage-roles.component';
import { BrowserModule } from '@angular/platform-browser';
import { TestRoleRoutingModule } from './test-role-routing.module'
import { NgxPaginationModule } from 'ngx-pagination';


@NgModule({
  declarations: [ TestManageRolesComponent ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    TestRoleRoutingModule,
    NgxPaginationModule
  ],
  exports :[TestManageRolesComponent]

})
export class TestRoleModule { }
