import { concat } from "rxjs";

export class UrlData {
    URL: string;
    HttpMethod: string;
}
export const URLList = {
    Accounts: {
        login: { URL: 'Account/Login', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        userlogout: { URL: 'Account/Logout', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        forgotPassword: { URL: 'Account/ForgotPassword', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        OTP: { URL: 'Account/VerifyOTP', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
    },
    Roles: {
        getUserRoles: { URL: 'Common/GetUserRoles', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        saveUserRoles: { URL: 'Common/SaveUserRoles', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        deleteUserRoles: { URL: 'Common/DeleteUserRoles', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,

    },

    Company: {
                
        getExpenseTypeLists: { URL: 'Company/GetExpenseType', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        saveExpenseType: { URL: 'Company/SaveExpenseType', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        deleteExpenseType: { URL: 'Company/DeleteExpenseType', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
    

        getCompanyExpenses: { URL: 'Company/GetCompanyExpenses', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        saveCompanyExpenses: { URL: 'Company/SaveCompanyExpenses', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        deleteCompanyExpenses: { URL: '', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        
        GetCompanyPaymentAccount: { URL: 'Company/GetCompanyPaymentAccount', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        GetCompanyPaymentAccountForddl: { URL: 'Company/GetCompanyPaymentAccountForddl', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        SaveCompanyPaymentAccount: { URL: 'Company/SaveCompanyPaymentAccount', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        DeleteCompanyPaymentAccount: { URL: 'Company/DeleteCompanyPaymentAccount', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        
        GetAccountType: { URL: 'Company/GetAccountType', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        GetAccountTypeForddl: { URL: 'Company/GetAccountTypeForddl', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        SaveAccountType: { URL: 'Company/SaveAccountType', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        DeleteAccountType: { URL: 'Company/DeleteAccountType', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        
        SavePaymentTransactions: { URL: 'Company/SavePaymentTransactions', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
    },
    Common: {

        UpdateMenuPermission: { URL: 'Common/UpdateMenuPermission', HttpMethod: 'POST' } as UrlData,
        GetAllMenus: { URL: 'Common/GetAllMenus', HttpMethod: 'GET' } as UrlData,
        GetMenus: { URL: 'Common/GetMenus', HttpMethod: 'GET' } as UrlData,
        GetMenuById: { URL: 'Common/GetMenuById', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        GetUnitForddl: { URL: 'Common/GetUnitMasterForddl', HttpMethod: 'GET' } as UrlData,
        GetVariationForddl: { URL: 'Common/GetVariationsForddl', HttpMethod: 'GET' } as UrlData,
        GetTaxForddl: { URL: 'Common/GetTaxForddl', HttpMethod: 'GET' } as UrlData,
        GetVendorForddl: { URL: 'Common/GetVendorForddl', HttpMethod: 'GET' } as UrlData,
        GetBrandForddl: { URL: 'Common/GetBrandsForddl', HttpMethod: 'GET' } as UrlData,
        GetPaymentMethodForddl: { URL: 'Common/GetPaymentMethodForddl', HttpMethod: 'GET' } as UrlData,
        GetProductsForddl: { URL: 'Common/GetProductsForddl', HttpMethod: 'GET' } as UrlData,
        GetProductsCategoryForddl: { URL: 'Common/GetProductsCategoryForddl', HttpMethod: 'GET' } as UrlData,
        GetProductsCategoryForddlForOrder: { URL: 'Common/GetProductsCategoryForddlForOrder', HttpMethod: 'GET' } as UrlData,
        GetFirmsForddl: { URL: 'Common/GetFirmsForddl', HttpMethod: 'GET' } as UrlData,
        GetCustomerTypesForddl: { URL: 'Common/GetCustomerTypesForddl', HttpMethod: 'GET' } as UrlData,
        GetParentCustomersForddl: { URL: 'Common/GetParentCustomersForddl', HttpMethod: 'GET' } as UrlData,
        GetCustomersForddl: { URL: 'Common/GetCustomersForddl', HttpMethod: 'GET' } as UrlData,
        GetCourierCompanyForddl: { URL: 'Common/GetCourierCompanyForddl', HttpMethod: 'GET' } as UrlData,
        GetWareHouseForddl: { URL: 'Common/GetWareHouseForddl', HttpMethod: 'GET' } as UrlData,
        GetOrderStatusForddl: { URL: 'Common/GetOrderStatusForddl', HttpMethod: 'GET' } as UrlData,
        GetPaymentStatusForddl: { URL: 'Common/GetPaymentStatusForddl', HttpMethod: 'GET' } as UrlData,
        GetUserRolesForddl: { URL: 'Common/GetUserRolesForddl', HttpMethod: 'GET' } as UrlData,
        DecryptBarCode: { URL: 'Common/DecryptBarCode', HttpMethod: 'GET' } as UrlData,
        LeadCountDashboard: { URL: 'CRM/GetLeadCountDashboard', HttpMethod: 'GET' } as UrlData,
        GetCountryForddl: { URL: 'Common/GetCountryForddl', HttpMethod: 'GET' } as UrlData,
        GetStateForddl: { URL: 'Common/GetStateForddl', HttpMethod: 'GET' } as UrlData,
    },
    Masters: {

        deleteUserById: { URL: 'CRM/DeleteLeadStages', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,

        GetOtpModes: { URL: 'Common/GetOtpModes', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        SaveOtpMode: { URL: 'Common/SaveOtpMode', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        deleteOtpMode: { URL: 'Common/DeleteOtpMode', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,

        GetCourierPartners: { URL: 'Common/GetCourierPartners', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        SaveCourierPartners: { URL: 'Common/SaveCourierCompany', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        deleteCourierPartner: { URL: 'Common/DeleteCourierCompany', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,

        GetOrderStatus: { URL: 'Common/GetOrderStatus', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        SaveOrderStatus: { URL: 'Common/SaveOrderStatus', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        deleteOrderStatus: { URL: 'Common/DeleteOrderStatusById', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,

        GetPaymentMethods: { URL: 'Common/GetPaymentMethods', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        SavePaymentMethods: { URL: 'Common/SavePaymentMethods', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        DeletePaymentMethods: { URL: 'Common/DeletePaymentMethods', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        GetPaymentMethodForddl: { URL: 'Common/GetPaymentMethodForddl', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,

        GetPaymentStatus: { URL: 'Common/GetPaymentStatus', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        SavePaymentStatus: { URL: 'Common/SavePaymentStatus', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,

        GetPaymentType: { URL: 'Common/GetPaymentType', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        SavePaymentType: { URL: 'Common/SavePaymentType', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,

        GetProductCategory: { URL: 'Common/GetProductCategory', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        SaveProductCategory: { URL: 'Common/SaveProductCategory', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        DeleteProductCategory: { URL: 'Common/DeleteProductCategory', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        
        GetProducts: { URL: 'Common/GetProducts', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        GetProductsBySKU: { URL: 'Common/GetProductsBySKU', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        SaveProducts: { URL: 'Common/SaveProducts', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        DeleteProducts: { URL: 'Common/DeleteProducts', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        GetProductImages: { URL: 'Common/GetProductImages', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        GetProductVariations: { URL: 'Common/GetProductVariations', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        
        GetStockHistory: { URL: 'Common/GetStockHistory', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,

        GetStockActions: { URL: 'Common/GetStockActions', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        SaveStockActions: { URL: 'Common/SaveStockActions', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,

        GetWareHouse: { URL: 'Common/GetWareHouse', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        SaveWareHouse: { URL: 'Common/SaveWareHouse', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,

        GetLeadSource: { URL: 'CRM/GetLeadSource', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        SaveLeadSource: { URL: 'CRM/SaveLeadSource', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,

        GetLeadStages: { URL: 'CRM/GetLeadStages', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        SaveLeadStages: { URL: 'CRM/SaveLeadStages', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,

        AttributeList: { URL: 'Common/GetAttributeMaster', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        deleteAttributeMaster: { URL: 'Common/DeleteAttributeMaster', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        AttributeMasterById: { URL: 'Common/GetAttributeMaster', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        saveAttributeMasterName: { URL: 'Common/SaveAttributeMaster', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        getAttributeMasterForddl: { URL: 'Common/GetAttributeMasterForddl', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
    
        bindAttributeList: { URL: 'Common/GetAttribute', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        saveAttribute: { URL: 'Common/SaveAttribute', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        getattributeById: { URL: 'Common/GetAttribute', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        deleteAttribute: { URL: 'Common/DeleteAttribute', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
    
        getWarrantieLists: { URL: 'Common/GetWarranties', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        saveWarrantie: { URL: 'Common/SaveWarranties', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        deleteWarrantie: { URL: 'Common/DeleteWarranties', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,

        getUnitIdLists: { URL: 'Common/GetUnitMasterForddl', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        getUnitMasterLists: { URL: 'Common/GetUnitMaster', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        saveUnitMaster: { URL: 'Common/SaveUnitMaster', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        deleteUnitMaster: { URL: 'Common/DeleteUnitMaster', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
    
        getBrandsLists: { URL: 'Common/GetBrands', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        saveBrands: { URL: 'Common/SaveBrands', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        deleteBrands: { URL: 'Common/DeleteBrands', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
    
        getTaxLists: { URL: 'Common/GetTax', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        saveTax: { URL: 'Common/SaveTax', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        deleteTax: { URL: 'Common/DeleteTax', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,

        getVariationsLists: { URL: 'Common/GetVariations', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        saveVariations: { URL: 'Common/SaveVariations', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        deleteVariations: { URL: 'Common/DeleteVariations', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
    
        getAttributeMasterLists: { URL: 'Common/GetAttributeMasterForddl', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        
        deletePaymenttype: { URL: 'Common/DeletePaymentType', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        deleteStockaction: { URL: 'Common/DeleteStockAction', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        deleteWarehouse: { URL: 'Common/DeleteWareHouse', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
    },
    
    Vendors: {
        
        deleteUserById: { URL: 'Vendor/DeleteVendors', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        GetVendors: { URL: 'Vendor/GetVendors', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        SaveVendors: { URL: 'Vendor/SaveVendors', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        DeleteVendors: { URL: 'Vendor/DeleteVendors', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,

        GetVendorOrders: { URL: 'Vendor/GetVendorOrders', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        SaveVendorOrders: { URL: 'Vendor/SaveVendorOrders', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        DeleteVendorOrders: { URL: 'Vendor/DeleteVendorOrders', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,

        GetVendorProducts: { URL: 'Vendor/GetVendorProducts', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        SaveVendorProducts: { URL: 'Vendor/SaveVendorProducts', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        DeleteVendorProducts: { URL: 'Vendor/DeleteVendorProducts', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,

        GetVendorPaymentTransactions: { URL: 'Vendor/GetVendorPaymentTransactions', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        SaveVendorPaymentTransactions: { URL: 'Vendor/SaveVendorPaymentTransactions', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        DeleteVendorPaymentTransactions: { URL: 'Vendor/DeleteVendorPaymentTransactions', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,

        GetVendorsBalance: { URL: 'Vendor/GetVendorsBalance', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
    },
    Customers: {
        GetCustomers: { URL: 'Customer/GetCustomers', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        GetCustomerByMobile: { URL: 'Customer/GetCustomerByMobile', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        GetCustomersUserRoleWiseForddl: { URL: 'Customer/GetCustomersUserRoleWiseForddl', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        SaveCustomers: { URL: 'Customer/SaveCustomers', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        DeleteCustomers: { URL: 'Customer/DeleteCustomers', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,

        GetCustomerTypes: { URL: 'Customer/GetCustomerTypes', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        DeleteCoutomerType: { URL: 'Customer/DeleteCustomerTypes', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,

        SaveCustomerTypes: { URL: 'Customer/SaveCustomerTypes', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        GetOrdersSummaryInfo: { URL: 'Customer/GetOrdersSummaryInfo', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        GetOrders: { URL: 'Customer/GetOrders', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        GetOrdersByStage: { URL: 'Customer/GetOrdersByStage', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        SaveOrders: { URL: 'Customer/SaveOrders', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        ProcessOrder: { URL: 'Customer/ProcessOrder', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        DispatchOrder: { URL: 'Customer/DispatchOrder', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        GetOrdersDetails: { URL: 'Customer/GetOrdersDetails', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        GetDispatchOrderDetails: { URL: 'Customer/GetDispatchOrderDetails', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        DeleteOrder: { URL: 'Customer/DeleteOrder', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        GetCustomerWallet: { URL: 'Customer/GetCustomerWallet', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        SaveCustomerWallet: { URL: 'Customer/SaveCustomerWallet', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        UpdateOrderPayment: { URL: 'Customer/UpdateOrderPayment', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        GetOrderProductStages: { URL: 'Customer/GetOrderProductStages', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        GetPrintOrderForddl: { URL: 'Customer/GetPrintOrderForddl', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        GetPrintOrderDetails: { URL: 'Customer/GetPrintOrderDetails', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        GetPrintProcessOrderDetails: { URL: 'Customer/GetPrintProcessOrderDetails', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
    },
    Reports: {
        leadData: { URL: 'CRM/GetLeadsFollowUpReport', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        DDl: { URL: 'CRM/GetLeadStagesForddl', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        GetStockProductCategory: { URL: 'Report/GetStockProductCategory', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        GetStockProducts: { URL: 'Report/GetStockProducts', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        GetStockVendorProducts: { URL: 'Report/GetStockVendorProducts', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
    },
    Inquiry: {
        filterbind: { URL: 'CRM/GetLeadFilter', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        addinquiry: { URL: 'CRM/SaveLeads', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        getInquiry: { URL: 'CRM/GetLeads', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        assignData: { URL: 'CRM/LeadAssign', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        getLeadSourace: { URL: 'CRM/GetLeadSourceForddl', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        deleteUserById: { URL: 'CRM/DeleteLead', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
    },
    User: {
        getUserRoles: { URL: 'Common/GetUserRolesForddl', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        saveUser: { URL: 'Common/SaveUser', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        getUsers: { URL: 'Common/GetUsers', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        getUsersByRole: { URL: 'Common/GetUsersByRole', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        deleteUser: { URL: 'Common/DeleteUser', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        getUserLog: { URL: 'Common/GetUserLog', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        changePassword: { URL: 'Common/ChangePassword', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
    },
    menu: {
        //filterdata:{ URL: '', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        getMenuById: { URL: 'Common/GetMenuById', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        deleteMenuById: { URL: 'Common/GetMenus', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        savePermission: { URL: 'Common/SaveMenuPermission', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        allMenuPermission: { URL: 'Common/GetMenus', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        addMenu: { URL: 'Common/SaveMenu', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        getMenusForddl: { URL: 'Common/GetMenusForddl', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        menulist: { URL: 'Common/GetMenus', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        getMenu: { URL: 'Common/GetMenus', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,

    },
    crm: {
        DDl: { URL: 'CRM/GetLeadStagesForddl', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        saveLead: { URL: 'CRM/SaveLeadsFollowUp ', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        getlead: { URL: 'CRM/GetLeadFilter', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
        getLeadById: { URL: 'CRM/GetLeadsFollowUp', HttpMethod: 'GET', UseTicketingUrl: false } as UrlData,
        getLeadsForFollowUp: { URL: 'CRM/GetLeadsForFollowUp', HttpMethod: 'POST', UseTicketingUrl: false } as UrlData,
    }
}