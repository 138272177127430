import { Injectable } from '@angular/core';
import { HttpDataService } from 'src/app/services/http-data.service';
import { ResponseModel } from 'src/app/models/ResponseModel';
import { URLList } from 'src/app/common/Utility/URL';
import { Observable } from 'rxjs';
import { DataTransferDTO } from 'src/app/models/DataTransferDTO.model';
import { KeyValuePair } from 'src/app/models/KeyValuePair.model';
import { ListUser } from 'src/app/models/listModel.model';
@Injectable({
  providedIn: 'root'
})
export class InquiryService {
  userDataTransferDTO: DataTransferDTO;

  constructor(private _httpDataService: HttpDataService) { }


  getDeatails(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Common.LeadCountDashboard);
  }
  filterbind(requestData): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Inquiry.filterbind, requestData);
  }
  addinquiry(requestData: ListUser): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Inquiry.addinquiry, requestData);
  }
  getInquiry(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Inquiry.getInquiry, requestData);
  }
  deleteUserById(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Inquiry.deleteUserById, requestData);

  }

  assignData(requestData): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Inquiry.assignData, requestData);

  }
  getLeadSourace(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Inquiry.getLeadSourace);

  }
  setUsersToService(details: DataTransferDTO) {
    this.userDataTransferDTO = details;
  }
  getUsersFromService(): DataTransferDTO {
    return this.userDataTransferDTO;
  }
  resetUsersToService(): void {
    this.userDataTransferDTO = null;
  }

}
