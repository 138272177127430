export class UserRoles {
    Id: number;
    UserRoleName: string;
    IsActive: boolean;
    CreatedDate: Date | string | null;
}
export class FilterUserRoles
{
    Id: number;
    UserRoleName  : string;
    Status : boolean;
}