import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CRMRoutingModule } from './crm-routing.module';
import { FollowupLeadComponent } from './followup-lead/followup-lead.component';
import { NgxPaginationModule } from 'ngx-pagination';
import {  LocationStrategy, HashLocationStrategy, DatePipe, CurrencyPipe } from '@angular/common';



@NgModule({
  declarations: [FollowupLeadComponent, ],
  imports: [
    CommonModule,
    CRMRoutingModule,
    ReactiveFormsModule,
    NgxPaginationModule,
  ]
})
export class CRMModule { }
