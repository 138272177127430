import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/modules/user/user.service';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { ActionMode } from 'src/app/models/enum';
import { DataTransferDTO } from 'src/app/models/DataTransferDTO.model';
import { KeyValuePair } from 'src/app/models/KeyValuePair.model';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { Users, FilterUsers } from 'src/app/models/commonDTO/users.model';
import { CommonDropdownModel } from 'src/app/models/CommonDropdownModel.model';
import { CommonService } from 'src/app/services/common.service';

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

    public userList: Array<Users>;
    public filterData: FilterUsers = new FilterUsers();
    public actionMode: ActionMode = ActionMode.Add;
    public searchForm: FormGroup;
    public submitted = false;
    p: number = 1;
    PageSize: number = 20;
    public userRoleTypeList: Array<CommonDropdownModel>;

    constructor(
        private _userService: UserService,
        private formBuilder: FormBuilder,
        private _toasterService: ToasterService,
        private _commonService: CommonService,
        private router: Router) {

    }

    ngOnInit(): void {
        this.InitializeSearchForm();
        this.BindUsersList();
        this.filterBindUser();
        this.BindUserRole();
    }
    InitializeSearchForm() {
        this.searchForm = this.formBuilder.group({
            UserRoleId: ['', Validators.nullValidator],
            IsActive: ['', Validators.nullValidator],
            UserName: ['', Validators.nullValidator],
            Email: ['', Validators.nullValidator],
            Mobile: ['', Validators.nullValidator],
            Name: ['', Validators.nullValidator]
        });
    }
    BindUsersList() {
        var self = this;
        self._userService.getUsers(this.filterData).subscribe((response) => {
            console.log('BindUsersList : ', response);

            if (response.IsSuccess) {
                self.userList = response.Data;
            }
            else {
                self.userList = [];
            }
        });
    }
    BindUserRole() {
        this._userService.getUserRoles().subscribe(response => {
            if (response.IsSuccess) {
                this.userRoleTypeList = response.Data;
                console.log(' this.userRoleTypeList', this.userRoleTypeList);
            }
            else {
                this.userRoleTypeList = [];
            }
        });
    }
    filterBindUser() {

        let detail = {
            'UserRoleId': this.filterData.UserRoleId,
            'Name': this.filterData.Name,
            'Email': this.filterData.Email,
            'Mobile': this.filterData.Mobile,
            'IsActive': this.filterData.IsActive,
            'UserName': this.filterData.UserName,
        };
        this._userService.getUsers(detail).subscribe(response => {
            if (response.IsSuccess) {

                this.userList = response.Data;
                console.log(' this.userList', this.userList);

            }
            else {

                this.userList = [];
            }
        });
    }

    SearchFilter() {
        var self = this;
        self.filterData = self.setFormDataToModel(self.filterData);
        self.filterBindUser();
    }
    setFormDataToModel(filterUsers: FilterUsers) {
        filterUsers.Id = this.searchForm.get('Id') != null ? this.searchForm.get('Id').value : null;
        filterUsers.UserRoleId = this.searchForm.get('UserRoleId') != null ? this.searchForm.get('UserRoleId').value : null;
        filterUsers.Name = this.searchForm.get('Name') != null ? this.searchForm.get('Name').value : null;
        filterUsers.Email = this.searchForm.get('Email') != null ? this.searchForm.get('Email').value : null;
        filterUsers.Mobile = this.searchForm.get('Mobile') != null ? this.searchForm.get('Mobile').value : null;
        filterUsers.IsActive = this.searchForm.get('IsActive') != null ? this.searchForm.get('IsActive').value : null;
        filterUsers.UserName = this.searchForm.get('UserName') != null ? this.searchForm.get('UserName').value : null;
        console.log('filterUsers', filterUsers)
        return filterUsers;
    }
    ResetFiter() {
        this.submitted = false;
        this.searchForm.reset();
        this.InitializeSearchForm();
        this.filterData = new FilterUsers();
        this.filterBindUser();

    }

    get f() {
        return this.searchForm.controls;
    }
    PreviewRecord(currentRow: Users) {
        if (currentRow && currentRow.Id > 0) {
            let dataTransferDTO: DataTransferDTO = new DataTransferDTO();
            dataTransferDTO.Id = currentRow.Id;
            dataTransferDTO.ActionMode = ActionMode.Preview;
            this._userService.setUsersToService(dataTransferDTO);
            this.router.navigateByUrl('user/add-user');
        }
    }
    EditRecord(currentRow: Users) {
        if (currentRow && currentRow.Id > 0) {
            let dataTransferDTO: DataTransferDTO = new DataTransferDTO();
            dataTransferDTO.Id = currentRow.Id;
            dataTransferDTO.ActionMode = ActionMode.Edit;
            this._userService.setUsersToService(dataTransferDTO);
            this.router.navigateByUrl('user/add-user');
        }
    }

    ViewLog(UserId: number) {
        localStorage.setItem("UserId", String(UserId));
        this.router.navigateByUrl('/reports/user-log');
    }

    DeleteRecord(id: number) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this record !",
            icon: "warning",
            
            dangerMode: true
        })
            .then((willDelete) => {
                if (willDelete) {
                    let dataToPass: KeyValuePair = { key: "id", value: String(id) };
                    this._userService.deleteUserById(dataToPass).subscribe(response => {
                        if (response.IsSuccess) {
                            this._toasterService.pop('success', "Success", response.Message);
                            this.BindUsersList();
                        }

                        else {
                            this._toasterService.pop('error', 'Error', response.Message);
                        }
                    });
                    // swal("Functionality Not Implemented !", {
                    //   icon: "success",
                    // });

                } else {
                    swal("Your file is safe!");
                }
            });
    }

}
