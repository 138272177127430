import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationStrategy, HashLocationStrategy, DatePipe, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { CompanyRoutingModule } from './company-routing.module';
import { CompanyExpensesComponent } from './company-expenses/company-expenses.component';
import { HttpDataService } from 'src/app/services/http-data.service';
import { routes } from 'src/app/app-routing.module';
import { RouterModule, Routes } from '@angular/router';
import { CompanyPaymentAccountComponent } from './company-payment-account/company-payment-account.component';
import { AccountTypeComponent } from './account-type/account-type.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AccountBookComponent } from './account-book/account-book.component';

@NgModule({
  declarations: [CompanyExpensesComponent, CompanyPaymentAccountComponent, AccountTypeComponent, AccountBookComponent],
  imports: [
    CommonModule,
    CompanyRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgbModule,
  ]
})
export class CompanyModule { }
