import { Injectable } from '@angular/core';
import { HttpDataService } from 'src/app/services/http-data.service';
import { ResponseModel } from 'src/app/models/ResponseModel';
import { URLList } from 'src/app/common/Utility/URL';
import { Observable } from 'rxjs';
import { FilterUserRoles } from 'src/app/models/commonDTO/userRoles.model';
import { KeyValuePair } from 'src/app/models/KeyValuePair.model';
import { rolemodel } from 'src/app/models/role.model';


@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private _httpDataService: HttpDataService) { }

  getUserRoles(requestData: FilterUserRoles): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Roles.getUserRoles, requestData);
  }
  saveUserRoles(requestData: rolemodel): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Roles.saveUserRoles, requestData);
  }
  deleteUserRoles(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Roles.deleteUserRoles, requestData);
  }
}
