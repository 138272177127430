<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Leads</h1>
      </div> 
    </div>
  </div>
</section>

<section class="content add-detail">
  <div class="container-fluid">

    <div class="col-12">
      <div [formGroup]="searchForm" class="card collapsed-card">
        <div class="card-header b-t-info">
          <h3 class="card-title" data-card-widget="collapse" data-toggle="tooltip">Search</h3>

          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
              title="Collapse">
              <i class="fas fa-search"></i></button>
          </div>
        </div>
        <div class="card-body" style="display: block;">
          <div class="row">


            <div class="col-4">
              <div class="form-group">
                <label> Name</label>
                <input type="text" class="form-control" placeholder=" Name" formControlName="Name" maxlength="50">
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label>Mobile</label>
                <input type="text" class="form-control" placeholder="Mobile" formControlName="Mobile" maxlength="10">
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label>Email</label>
                <input type="email" class="form-control" placeholder="Email" formControlName="Email" maxlength="50">
              </div>
            </div>

          </div>
          <div class="row float-right">
            <div class="col-12">
              <div class="form-group">
                <button type="button" (click)="ResetFiter()" class="btn btn-secondary float-left mr-lg-2"><i
                    class="fas fa-times"></i>
                  Reset</button>
                <button type="button" class="btn btn-info" (click)="SearchFilter()"> <i class="fas fa-search"></i>
                  Search</button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.card-body -->
        <!-- <div class="card-footer">
                    <button type="submit" class="btn btn-info float-right"> <i class="fas fa-search"></i> Search</button>
                    <button type="submit" class="btn btn-default float-right">Reset</button>
                </div> -->
      </div>

      <div class="card">
        <div class="card-header b-t-olive">
          <h3 class="card-title">Leads List</h3>
          <div class="card-tools">
            <a [routerLink]="'/lead/add-lead'" class="nav-link">
              <button type="button" class="btn btn-block bg-gradient-info"><i class="fas fa-plus"></i>
                Add Lead</button>
            </a>
          </div>
        </div>
        <div class="card-body">

          <table class="table table-bordered" *ngIf="inquiryData?.length>0">
            <thead>
              <tr class="text-center">
                <th>Sr.No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>City</th>
                <th>Source</th>
                <th>Remark</th>
                <th>LastStageName</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of inquiryData | paginate:{itemsPerPage: PageSize, currentPage:p};let i = index"
                class="text-center">
                <td>{{PageSize*(p-1)+ i+1}}</td>
                <td>{{data.FirstName}}
                  {{data.LastName}}</td>
                <td>{{data.Email}}</td>
                <td>{{data.Mobile}}</td>
                <td>{{data.City}}</td>
                <td>{{data.SourceName}}</td>
                <td title="{{data.Remark}}">{{(data.Remark.length>50)? (data.Remark | slice:0:50)+'..':(data.Remark)}}
                </td>
                <td>{{data.LastStageName}}</td>
                <td class="text-right py-0 align-middle">
                  <!-- [routerLink]="['/inquiry/edit', data.Id]"  -->
                  <div class="btn-group btn-group-sm">
                    <a href="javascript:void(0);" (click)="PreviewRecord(data)" title="Preview"
                      class="btn btn-secondary"><i class="fas fa-eye"></i></a>
                    <a href="javascript:void(0);" (click)="EditRecord(data)" title="Edit" class="btn btn-info"><i
                        class="fas fa-edit"></i></a>
                    <a href="javascript:void(0);" (click)="DeleteRecord(data.Id)" title="Delete"
                      class="btn btn-danger"><i class="fas fa-trash"></i></a>
                  </div>

                </td>
              </tr>

            </tbody>

          </table>
          <pagination-controls class="table-pagination" (pageChange)="p=$event" maxSize="9" directionLinks="true"
            autoHide="true" responsive="true" previousLabel="Previous" nextLabel="Next"
            screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
            screenReaderCurrentLabel="You're on page" *ngIf="inquiryData?.length > 0"></pagination-controls>
          <div class="dtastyle" *ngIf="inquiryData?.length == 0">
            <span class="search_no_results  ">
              No data found
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>