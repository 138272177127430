import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { CustomersModule } from './modules/customers/customers.module';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { LoginComponent } from './modules/authentication/component/login/login.component';
import { AdminGuardGuard } from './guard/admin-guard.guard';
import { CRMModule } from 'src/app/modules/crm/crm.module';
import { InquiryModule } from './modules/inquiry/inquiry.module';
export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/Login' },
  { path: 'Login', component: LoginComponent },
  { path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AdminGuardGuard] },
  { path: 'customer', loadChildren: () => import('./modules/customers/customers.module').then(m => m.CustomersModule), canActivate: [AdminGuardGuard] },
  { path: 'company', loadChildren: () => import('./modules/company/company.module').then(m => m.CompanyModule), canActivate: [AdminGuardGuard] },
  { path: 'auth', loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule), canActivate: [AdminGuardGuard] },
  { path: 'masters', loadChildren: () => import('./modules/masters/masters.module').then(m => m.MastersModule), canActivate: [AdminGuardGuard] },
  { path: 'vendors', loadChildren: () => import('./modules/vendors/vendors.module').then(m => m.VendorsModule), canActivate: [AdminGuardGuard] },
  { path: 'products', loadChildren: () => import('./modules/products/products.module').then(m => m.ProductsModule), canActivate: [AdminGuardGuard] },
  { path: 'reports', loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule), canActivate: [AdminGuardGuard] },
  { path: 'inquiry', loadChildren: () => import('./modules/inquiry/inquiry.module').then(m => m.InquiryModule), canActivate: [AdminGuardGuard] },
  { path: 'crm', loadChildren: () => import('src/app/modules/crm/crm.module').then(m => m.CRMModule), canActivate: [AdminGuardGuard] },

];

export class AppModule { }
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
