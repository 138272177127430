<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Menu-Permission</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Menu-Permission</li>
                </ol>
            </div>
        </div>
    </div>
</section>


<div class="container-fluid">

    <div [formGroup]="menuForm" class="card m-t-2">
        <div class="row col-12">

            <div class="col-12">
                <div class="form-group">
                    <label>Select User Role</label><span class="redStar">*</span>
                    <select class="form-control" formControlName="UserRoleId" id="UserRoleId" (change)="changeMenu($event)">
                        
                        <!-- [ngClass]="{ 'is-invalid': submitted && f.UserRoleId.errors }" -->
                        <option value="0">-- Select --</option>
                        <option *ngFor="let x of userRoleList;" [value]="x.Id">
                            {{x.Name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row ">
                <div *ngFor="let x of menuTree;" class="col-md-12 dis-cls">
                    <label class="label-cls">
                        <input type="checkbox" [checked]="x.IsAlreadyAccess" name="menuName" [value]="x.Id"
                            (change)="onCheckboxChange($event)">
                        {{x.MenuName}}</label>
                    <div class=" dis-cls1" *ngIf="x.ChildMenu.length > 0">
                        <div *ngFor="let childItem of x.ChildMenu">
                            <label class="label-cls">
                                <input type="checkbox" [checked]="childItem.IsAlreadyAccess" name="ChildMenu"
                                    [value]="childItem.Id" (change)="onCheckboxChange($event)">
                                {{childItem.MenuName}}</label>
                            <!-- <div *ngIf="childItem.ChildMenu.length == 0">
                                <ul class="dis-cls3">
                                    <li class="dis-cls2"><label class="label-cls"><input type="checkbox"
                                                [checked]="checks" name="Add" value="Add">
                                            Add</label>
                                    </li>
                                    <li class="dis-cls2"><label class="label-cls"><input type="checkbox"
                                                [checked]="checks" name="Edit" value="Edit">
                                            Edit</label>
                                    </li>
                                    <li class="dis-cls2"><label class="label-cls"><input type="checkbox"
                                                [checked]="checks" name="View" value="View">
                                            View</label>
                                    </li>
                                    <li class="dis-cls2"><label class="label-cls"><input type="checkbox"
                                                [checked]="checks" name="Delete" value="Delete">
                                            Delete</label>
                                    </li>
                                    <li class="dis-cls2"><label class="label-cls"><input type="checkbox"
                                                [checked]="checks" name="StatusUpdate" value="StatusUpdate">
                                            StatusUpdate</label>
                                    </li>
                                </ul>
                            </div> -->
                            <ul class="child-cls">
                                <li class="dis-cls2" *ngFor="let lastChild of childItem.ChildMenu">
                                    <label class="label-cls-1"><input type="checkbox"
                                            [checked]="lastChild.IsAlreadyAccess" name="lastChild"
                                            [value]="lastChild.Id" (change)="onCheckboxChange($event)">
                                        {{lastChild.MenuName}}</label>
                                    <!-- <div>
                                        <ul class="dis-cls3">
                                            <li class="dis-cls2"><label class="label-cls"><input type="checkbox"
                                                        [checked]="checks" name="Add" value="Add">
                                                    Add</label>
                                            </li>
                                            <li class="dis-cls2"><label class="label-cls"><input type="checkbox"
                                                        [checked]="checks" name="Edit" value="Edit">
                                                    Edit</label>
                                            </li>
                                            <li class="dis-cls2"><label class="label-cls"><input type="checkbox"
                                                        [checked]="checks" name="View" value="View">
                                                    View</label>
                                            </li>
                                            <li class="dis-cls2"><label class="label-cls"><input type="checkbox"
                                                        [checked]="checks" name="Delete" value="Delete">
                                                    Delete</label>
                                            </li>
                                            <li class="dis-cls2"><label class="label-cls"><input type="checkbox"
                                                        [checked]="checks" name="StatusUpdate" value="StatusUpdate">
                                                    StatusUpdate</label>
                                            </li>
                                        </ul>
                                    </div> -->
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr>
            </div>
            <div class=" row  float-right " *ngIf="menuTree?.length > 0">
                <button type="button" (click)="Save()" class="btn btn-primary float-right">
                    <i class="fas fa-save"></i>
                    Save</button>
            </div>
        </div>
    </div>
</div>