import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/modules/dashboard/service/dashboard.service';
import { Menu } from 'src/app/models/commonDTO/Menu.model';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {
  public menu: Array<Menu>;

  constructor(private _dashboardService: DashboardService) { }

  ngOnInit() {
    this.GetMenusList();
  }

  GetMenusList() {
    this._dashboardService.GetMenus().subscribe(res => {
      if (res.IsSuccess) {
        this.menu = res.Data;
      }
    });

    // //TODO:Need to changes-Only for Test Purpose.
    // let userModelSelected = new UserModel();
    // userModelSelected.UserName = "Chandan";
    // userModelSelected.Password = "123";
    // this.service.Login(userModelSelected).subscribe(res => {
    //   if (res.IsSuccess) {
    //     localStorage.setItem('Token', res.Data.Token);
    //     localStorage.setItem('Authorization', res.Data.Token);

    //   }
    // });

  }

}
