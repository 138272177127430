import { Injectable } from '@angular/core';
import { HttpDataService } from 'src/app/services/http-data.service';
import { ResponseModel } from 'src/app/models/ResponseModel';
import { URLList } from 'src/app/common/Utility/URL';
import { Observable } from 'rxjs';
import { KeyValuePair } from 'src/app/models/KeyValuePair.model';
import { FilterUsers, Users } from 'src/app/models/commonDTO/users.model';
import { DataTransferDTO } from 'src/app/models/DataTransferDTO.model';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  userDataTransferDTO: DataTransferDTO;
  public UserName:any;
  public Password:any;
  constructor(private _httpDataService: HttpDataService) { }

  getUserRoles(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.User.getUserRoles);
  }
  getUsers(requestData): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.User.getUsers, requestData);
  }
  getUsersByRole(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.User.getUsersByRole, requestData);
  }
  saveUsers(requestData: Users): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.User.saveUser, requestData);
  }
  deleteUserById(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.User.deleteUser, requestData);
  }
  getUserLog(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.User.getUserLog, requestData);
  }
  changePassword(requestData): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.User.changePassword, requestData);
  }
  //================== Data Transfer from one page to another ================================================================================
  setUsersToService(details: DataTransferDTO) {
    this.userDataTransferDTO = details;
  }
  getUsersFromService(): DataTransferDTO {
    return this.userDataTransferDTO;
  }
  resetUsersToService(): void {
    this.userDataTransferDTO = null;
  }
  //=======================================================================================================================

}
