export class followlead {

  Id: 0;
  FirstName: string;
  LastName: string;
  Name: string;

  Mobile: string;
  Email: string;
  Address: string;
  City: string;
  Source: string;
  Remark: string;

}
export class filterFollowup {
  Id: Number;
  Name: string;
  City: string;
  Mobile: number;
  Email: string;
  Source: string;
}