<!-- Navbar -->
<!-- <nav class="main-header navbar navbar-expand navbar-white navbar-light">
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
    </li>
    <li class="nav-item d-none d-sm-inline-block">
      <a  [routerLink]="'vendors/vendor-orders-add'" class="nav-link">Add Stock</a>
    </li>
    <li class="nav-item d-none d-sm-inline-block">
      <a  [routerLink]="'vendors/generate-barcode'" class="nav-link">Generate BarCode</a>
    </li>
    <li class="nav-item d-none d-sm-inline-block">
      <a [routerLink]="'/customer/order-add'" class="nav-link">Create Order</a>
    </li>
    <li class="nav-item d-none d-sm-inline-block">
      <a  [routerLink]="'customer/dispatch-order'" class="nav-link">Dispatch Order</a>
    </li>
  </ul>

  <form class="form-inline ml-3">
    <div class="input-group input-group-sm">
      <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
      <div class="input-group-append">
        <button class="btn btn-navbar" type="submit">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
  </form>

  <ul class="navbar-nav ml-auto">
    <li class="nav-item dropdown">
      <a class="nav-link" data-toggle="dropdown" href="#">
        <i class="far fa-comments"></i>
        <span class="badge badge-danger navbar-badge">3</span>
      </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <a href="#" class="dropdown-item">
          <div class="media">
            <img src="" alt="User Avatar" class="img-size-50 mr-3 img-circle">
            <div class="media-body">
              <h3 class="dropdown-item-title">
                Brad Diesel
                <span class="float-right text-sm text-danger"><i class="fas fa-star"></i></span>
              </h3>
              <p class="text-sm">Call me whenever you can...</p>
              <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
            </div>
          </div>
        </a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item">
          <div class="media">
            <img src="" alt="User Avatar" class="img-size-50 img-circle mr-3">
            <div class="media-body">
              <h3 class="dropdown-item-title">
                John Pierce
                <span class="float-right text-sm text-muted"><i class="fas fa-star"></i></span>
              </h3>
              <p class="text-sm">I got your message bro</p>
              <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
            </div>
          </div>
        </a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item">
          <div class="media">
            <img src="" alt="User Avatar" class="img-size-50 img-circle mr-3">
            <div class="media-body">
              <h3 class="dropdown-item-title">
                Nora Silvester
                <span class="float-right text-sm text-warning"><i class="fas fa-star"></i></span>
              </h3>
              <p class="text-sm">The subject goes here</p>
              <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
            </div>
          </div>
        </a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item dropdown-footer">See All Messages</a>
      </div>
    </li>
    <li class="nav-item dropdown">
      <a class="nav-link" data-toggle="dropdown" href="#">
        <i class="far fa-bell"></i>
        <span class="badge badge-warning navbar-badge">15</span>
      </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <span class="dropdown-item dropdown-header">15 Notifications</span>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item">
          <i class="fas fa-envelope mr-2"></i> 4 new messages
          <span class="float-right text-muted text-sm">3 mins</span>
        </a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item">
          <i class="fas fa-users mr-2"></i> 8 friend requests
          <span class="float-right text-muted text-sm">12 hours</span>
        </a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item">
          <i class="fas fa-file mr-2"></i> 3 new reports
          <span class="float-right text-muted text-sm">2 days</span>
        </a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a>
      </div>
    </li>
    <li class="nav-item">

      <a (click)="logout()" class="nav-link logoutBtn"><i class="fa fa-power-off" aria-hidden="true"></i> Sign Out</a>
    </li>
  </ul>
</nav> -->
<!-- /.navbar -->


<!-- <nav class="main-header navbar navbar-expand navbar-white navbar-light">

   <ul class="navbar-nav ml-auto">
    <li class="nav-item">
      <a [routerLink]="'user/change-password'" class="nav-link">Change Password</a>
    </li>
    <li class="nav-item">
      <a (click)="logout()" class="nav-link logoutBtn"><i class="fa fa-power-off" aria-hidden="true"></i> Sign Out</a>
    </li>
  </ul> 
</nav>   -->

<nav class="main-header navbar navbar-expand navbar-white navbar-light">
  <div class="nav-item dropdown  ml-auto">
    <a href="#" data-toggle="dropdown" class="nav-link user-action" aria-expanded="false">
      <!-- <img src ="https://www.kindpng.com/imgv/iwoxbb_user-profile-default-image-png-clipart-png-download/" class="userprofile">  -->
      <i class="far fa-lg	fa-user"></i> {{user}}
    </a>
    <div class="dropdown-menu">
      <a [routerLink]="'user/profile'"  class="dropdown-item"><i class="fas fa-cog"></i> Settings</a>
      <a [routerLink]="'user/change-password'" class="dropdown-item"><i class="fas fa-key"></i> Change Password</a>
      <div class="dropdown-divider"></div>
      <a href="#" (click)="logout()" class="dropdown-item"><i class="fas fa-sign-out-alt"></i> Logout</a>
    </div>
  </div>
</nav>