<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Account Book</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Payment Transactions </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content add-detail">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div [formGroup]="searchForm" class="card collapsed-card">
                    <div class="card-header b-t-info">
                        <h3 class="card-title" data-card-widget="collapse" data-toggle="tooltip">Search</h3>

                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                                <i class="fas fa-search"></i></button>
                        </div>
                    </div>
                    <div class="card-body" style="display: block;">
                        <div class="row">
                            <div class="col-3">
                                <div class="form-group">
                                    <label>Select Payment Account</label>
                                    <select class="form-control" formControlName="PaymentAccountId" id="PaymentAccountId">
                                        <option value="">-- Select --</option>
                                        <option *ngFor="let o of paymentAccountList" [ngValue]="o.Id">
                                            {{o.Name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    <label>Select Payment Method</label>
                                    <select class="form-control" formControlName="PaymentMethodId" id="PaymentMethodId">
                                        <option value="">-- Select --</option>
                                        <option *ngFor="let o of paymentMethodList" [ngValue]="o.Id">
                                            {{o.Name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    <label>Payment Date</label>
                                    <input type="text" class="form-control" placeholder="PaymentDate" formControlName="PaymentDate" maxlength="10" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()">
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    <button type="button" (click)="ResetFiter()" class="btn btn-secondary float-left mr-lg-2"><i class="fas fa-times"></i>
                                        Reset</button>
                                    <button type="button" class="btn btn-info" (click)="SearchFilter()"> <i
                                            class="fas fa-search"></i>
                                        Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- ============= Grid/List Wallet Payment Section [Start] =================== -->
                <div class="card">
                    <div class="card-header b-t-olive">
                        <h3 class="card-title">List</h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col" class="text-center">S No</th>
                                    <th scope="col" class="text-center">Customer Name</th>
                                    <th scope="col" class="text-center">Mobile</th>
                                    <th scope="col" class="text-center">Payment Method</th>
                                    <th scope="col" class="text-center">Payment Account</th>
                                    <th scope="col" class="text-center">Payment Type</th>
                                    <th scope="col" class="text-center">Amount</th>
                                    <th scope="col" class="text-center">Payment Details</th>
                                    <th scope="col" class="text-center">Paid On</th>
                                    <th scope="col" class="text-center">File</th>
                                    <th scope="col" class="text-center">Created Date</th>
                                    <th scope="col" class="text-center">Action</th>
                                    <th scope="col" class="text-center">Retailor</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let x of paymentTransactionList | paginate:{itemsPerPage: PageSize, currentPage:p};let i = index" class="text-center">
                                    <td>{{PageSize*(p-1)+ i+1}}</td>
                                    <td>{{x.CustomerName}}</td>
                                    <td>{{x.Mobile}}</td>
                                    <td>{{x.PaymentMethodName}}</td>
                                    <td>{{x.PaymentAccountName}}</td>
                                    <td>{{x.PaymentType}}</td>
                                    <td>{{x.Amount| currency:'INR'}}</td>
                                    <td>{{x.PaymentDetails}}</td>
                                    <td>{{x.sPaymentDate | date :'mediumDate'}}</td>
                                    <td> <img class="imageBorderDesign" id="imageUrl" [src]="x.PaymentFileUrl" *ngIf="x.PaymentFile.length>0" width="80" height="40"></td>
                                    <td>{{x.CreatedDate | date :'mediumDate'}}</td>
                                    <td class="text-right py-0 align-middle">
                                        <div class="btn-group btn-group-sm">
                                            <a href="javascript:void(0);" (click)="PreviewRecord(x)" title="Preview" class="btn btn-secondary"><i class="fas fa-eye"></i></a>
                                            <a href="javascript:void(0);" (click)="EditRecord(x)" title="Edit" class="btn btn-info"><i class="fas fa-edit"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="paymentTransactionList == null || paymentTransactionList == undefined || (paymentTransactionList && paymentTransactionList.length==0)">
                                <tr>
                                    <td colspan="12" class="text-center">
                                        No Records Found.
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <!-- /.card-body -->
                    <pagination-controls class="table-pagination" (pageChange)="p=$event" maxSize="9" directionLinks="true" autoHide="true" responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page">
                    </pagination-controls>
                </div>
                <!-- ============= Grid/List Wallet Payment Section [End] =================== -->

            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>
<!-- /.content -->