import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RoleRoutingModule } from './role-routing.module';

import { ManageRoles } from './manage-roles/manage-roles.component';
import { NgxPaginationModule } from 'ngx-pagination';
import {  LocationStrategy, HashLocationStrategy, DatePipe, CurrencyPipe } from '@angular/common';


@NgModule({
  declarations: [ManageRoles],
  imports: [
    CommonModule,
    FormsModule,
    RoleRoutingModule,
    ReactiveFormsModule,
    NgxPaginationModule

  ]
})
export class RoleModule { }
