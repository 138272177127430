import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import{ParcelSlipRoutingModule} from 'src/app/parcel-slip/parcel-routing.module';
import { ParcelPrintComponent } from './parcel-print/parcel-print.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [ParcelPrintComponent],
  imports: [
    CommonModule,
    ParcelSlipRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    
  ]
})
export class ParcelSlipModule { }
