import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms'; 
import { InquiryRoutingModule } from './inquiry-routing.module';
import { AddInquiryComponent } from './add-inquiry/add-inquiry.component';
import { ListInquiryComponent } from './list-inquiry/list-inquiry.component';
import {NgxPaginationModule} from 'ngx-pagination'; // <-- import the module
import {  LocationStrategy, HashLocationStrategy, DatePipe, CurrencyPipe } from '@angular/common';
import { RoleAccessComponent } from './role-access/role-access.component';
import { AssignLeadComponent } from './assign-lead/assign-lead.component';
import { TestContactFormComponent } from './test-contact-form/test-contact-form.component';


@NgModule({
  declarations: [AddInquiryComponent, ListInquiryComponent, RoleAccessComponent, AssignLeadComponent, TestContactFormComponent],
  imports: [
    CommonModule,
    InquiryRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ]
})
export class InquiryModule {  }
