import { Injectable } from '@angular/core';
import { HttpDataService } from 'src/app/services/http-data.service';
import { ResponseModel } from 'src/app/models/ResponseModel';
import { Observable } from 'rxjs';
import { URLList } from 'src/app/common/Utility/URL';
import { KeyValuePair } from 'src/app/models/KeyValuePair.model';
import { DataTransferDTO } from 'src/app/models/DataTransferDTO.model';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  getMenu() {
    throw new Error('Method not implemented.');
  }
  userDataTransferDTO: DataTransferDTO;

  constructor(private _httpDataService: HttpDataService) { }
  getMenusForddl(requestData): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.menu.getMenusForddl, requestData);
  }
  addMenu(requestData): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.menu.addMenu, requestData);
  }
  menulist(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.menu.menulist);
  }
  GetMenus(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Common.GetMenus, false);
  }
  GetMenuById(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Common.GetMenuById, false);
  }
  updatemenu(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.menu.getMenu);
  }
  allMenuList(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Common.GetAllMenus, requestData);
  }
  deleteMenuById(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.menu.deleteMenuById, requestData);
  }
  getdata(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.menu.menulist);
  }
  getUserRolesForddl(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Common.GetUserRolesForddl, false);
  }
  getMenuById(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.menu.getMenuById, requestData);
  }
  // filterlist(requestData): Observable<ResponseModel> {
  //   return this._httpDataService.postMethod<ResponseModel>(URLList.menu.filterdata,requestData);
  // }

  setUsersToService(details: DataTransferDTO) {
    this.userDataTransferDTO = details;
  }
  getUsersFromService(): DataTransferDTO {
    return this.userDataTransferDTO;
  }
  resetUsersToService(): void {
    this.userDataTransferDTO = null;
  }
  allMenuPermission(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.menu.allMenuPermission);
  }
  savePermission(requestData): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.menu.savePermission, requestData);
  }
}
