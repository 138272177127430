<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Roles</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Role List</li>
                </ol>
            </div>
        </div>
    </div><!-- /.container-fluid -->
</section>
<section cass="content add-detail">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div [formGroup]="filterUserForm">
                    <div class="card collapsed-card">
                        <div class="card-header b-t-info">
                            <h3 class="card-title" data-card-widget="collapse" data-toggle="tooltip">Search</h3>

                            <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-card-widget="collapse"
                                    data-toggle="tooltip" title="Collapse">
                                    <i class="fas fa-search"></i></button>
                            </div>
                        </div>
                        <div class="card-body" style="display: block;">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label> Role Name</label>
                                        <input type="text" class="form-control" placeholder="UserRole Name"
                                            formControlName="UserRoleName" maxlength="50">

                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Select Status</label>
                                        <select class="form-control" formControlName="IsActive">
                                            <option selected="true" value="null"> -- Select--</option>
                                            <option value="true">Active</option>
                                            <option value="false">InActive</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row float-right">
                                <div class="col-12">
                                    <div class="form-group">
                                        <button type="button" (click)="Reset()"
                                            class="btn btn-secondary float-left mr-lg-2"><i class="fas fa-times"></i>
                                            Reset</button>
                                        <button type="submit" (click)="SearchFilter()" class="btn btn-info"> <i
                                                class="fas fa-search"></i>
                                            Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="card">
                    <div class="card-header b-t-olive">
                        <h3 class="card-title">Roles List</h3>
                        <div class="card-tools">

                            <button type="button" (click)="AddRole()" class="btn btn-block bg-gradient-info"><i
                                    class="fas fa-plus"></i> Add
                                Role</button>
                        </div>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr.No</th>
                                    <th class="text-center">Role Name</th>
                                    <th class="text-center">Description</th>
                                    <th scope="col" class="text-center">Created Date</th>
                                    <th scope="col" class="text-center">Status</th>
                                    <th scope="col" class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let x of inquiryData | paginate:{itemsPerPage: PageSize, currentPage:p};let i = index"
                                    class="
                                    text-center">
                                    <td>{{PageSize*(p-1)+ i+1}}</td>
                                    <td>{{x.UserRoleName}}</td>
                                    <td>{{x.Description}}</td>
                                    <td>{{x.CreatedDate | date :'mediumDate'}}</td>
                                    <td> <span *ngIf="x.IsActive" class="badge bg-success">Active</span>
                                        <span *ngIf="!x.IsActive" class="badge bg-gradient-danger">InActive</span>
                                    </td>
                                    <td class="text-right py-0 align-middle text-center">
                                        <div class="btn-group btn-group-sm">
                                            <a href="javascript:void(0);" (click)="PreviewRecord(x)" title="Preview"
                                                class="btn btn-secondary"><i class="fas fa-eye"></i></a>
                                            <a href="javascript:void(0);" (click)="EditRecord(x)" title="Edit" *ngIf="x.Id>1"
                                                class="btn btn-info"><i class="fas fa-edit"></i></a>
                                            <a href="javascript:void(0);" (click)="DeleteRecord(x.Id)" title="Delete" *ngIf="x.Id>1"
                                                class="btn btn-danger"><i class="fas fa-trash"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <pagination-controls class="table-pagination" (pageChange)="p=$event" maxSize="9"
                            directionLinks="true" autoHide="true" responsive="true" previousLabel="Previous"
                            nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                            screenReaderCurrentLabel="You're on page"></pagination-controls>
                        <div class="dtastyle" *ngIf="inquiryData?.length == 0">
                            <span class="search_no_results  ">
                                No data found
                            </span>
                        </div>

                    </div>
                </div>
                <div class="modal-backdrop fade show" *ngIf="showList" style="display:block"></div>
                <div draggable="false" class="modal" id="addClass" role="dialog" aria-hidden="true"
                    style="display:block" *ngIf="showList">
                    <div class="modal-dialog modal-lg">
                        <div [formGroup]="addRoleForm" class="modal-content"
                            [ngClass]="actionMode == ActionModeEnum.Preview ? 'previewModeColor':'defaultModeColor'">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">
                                    <strong *ngIf="actionMode == ActionModeEnum.Add">Add Role</strong>
                                    <strong *ngIf="actionMode == ActionModeEnum.Edit">Edit Role</strong>
                                    <strong *ngIf="actionMode == ActionModeEnum.Preview">Preview Role</strong>
                                    <button class="close" type="button" (click)="ClosePopup()" aria-label="Close">
                                        <span aria-hidden="true"><i class="fas fa-times" aria-hidden="true"></i></span>
                                    </button>
                                </h5>
                            </div>
                            <div class="modal-body pdf-model">
                                <div class="card-body" style="display: block;">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Name</label>
                                                <input type="text" class="form-control" formControlName="UserRoleName"
                                                    placeholder="Role Name" maxlength="50"
                                                    [ngClass]="{ 'is-invalid': submitted && f.UserRoleName.errors }">
                                                <div *ngIf="submitted && f.UserRoleName.errors"  class="invalid-feedback">
                                                    <div *ngIf="f.UserRoleName.errors.required"> Name is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Description</label>
                                                <textarea type="text" class="form-control" formControlName="Description"
                                                    placeholder="Description" maxlength="1000"
                                                    [ngClass]="{ 'is-invalid': submitted && f.Description.errors }"></textarea>
                                                <div *ngIf="submitted && f.Description.errors" class="invalid-feedback">
                                                    <div *ngIf="f.Description.errors.required">Courier Partner
                                                        Description is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Status</label>
                                                <input type="checkbox" name="my-checkbox"
                                                    class="form-check-input chkMargin" formControlName="IsActive"
                                                    data-bootstrap-switch data-off-color="danger"
                                                    data-on-color="success">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer"
                                *ngIf="actionMode == ActionModeEnum.Add || actionMode == ActionModeEnum.Edit">
                                <button type="button" (click)="Reset()" class="btn btn-secondary float-left"><i
                                        class="fas fa-times"></i>
                                    Reset</button>
                                <button type="button" (click)="Save()" class="btn btn-primary float-right"> <i
                                        class="fas fa-save"></i>
                                    Save</button>

                            </div>
                        </div>


                    </div>

                </div>

            </div>

        </div>

    </div>
</section>