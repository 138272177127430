import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './component/login/login.component';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import {FormsModule} from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { OTPComponent } from './component/otp/otp.component';

@NgModule({
  declarations: [LoginComponent, OTPComponent],
  imports: [
    CommonModule,
    SharedModule,
    AuthenticationRoutingModule,
    FormsModule
  ]
})
export class AuthenticationModule { }
