<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Users</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">User List</li>
                </ol>
            </div>
        </div>
    </div><!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content add-detail">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div [formGroup]="searchForm" class="card collapsed-card">
                    <div class="card-header b-t-info">
                        <h3 class="card-title" data-card-widget="collapse" data-toggle="tooltip">Search</h3>

                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                                title="Collapse">
                                <i class="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <div class="card-body" style="display: block;">
                        <div class="row">
                            <div class="col-4">
                                <div class="form-group">
                                    <label>User Name</label>
                                    <input type="text" class="form-control" placeholder="User Name"
                                        formControlName="UserName" maxlength="50">
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Name</label>
                                    <input type="text" class="form-control" placeholder="Name" formControlName="Name"
                                        maxlength="10">
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Select Status</label>
                                    <select class="form-control" formControlName="IsActive">
                                        <option value=""> -- All --</option>
                                        <option value="true">Active</option>
                                        <option value="false">InActive</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="text" class="form-control" placeholder="Email" formControlName="Email"
                                        maxlength="50">
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Mobile</label>
                                    <input type="text" class="form-control" placeholder="Mobile"
                                        formControlName="Mobile" maxlength="10">
                                </div>
                            </div>

                            <div class="col-4">
                                <div class="form-group">
                                    <label>Select User Role</label>
                                    <select class="form-control" formControlName="UserRoleId">
                                        <option selected="true" value=""> -- All --</option>
                                        <option *ngFor="let x of userRoleTypeList;" [ngValue]="x.Id">
                                            {{x.Name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row float-right">
                            <div class="col-12">
                                <div class="form-group">
                                    <button type="button" (click)="ResetFiter()"
                                        class="btn btn-secondary float-left mr-lg-2">
                                        <i class="fas fa-times"></i>
                                        Reset
                                    </button>
                                    <button type="button" class="btn btn-info" (click)="SearchFilter()">
                                        <i class="fas fa-search"></i>
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                </div>
                
                <div class="card">
                    <div class="card-header b-t-olive">
                        <h3 class="card-title">Users List</h3>

                        <div class="card-tools">
                            <a [routerLink]="'/user/add-user'" class="nav-link">
                                <button type="button" class="btn btn-block bg-gradient-info">
                                    <i class="fas fa-plus"></i>
                                    Add User
                                </button>
                            </a>
                        </div>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col" class="text-center">S No</th>
                                    <th scope="col" class="text-center">User Code</th>
                                    <th scope="col" class="text-center">User Name</th>
                                    <th scope="col" class="text-center">Role</th>
                                    <th scope="col" class="text-center">Name</th>
                                    <th scope="col" class="text-center">Mobile</th>
                                    <!-- <th scope="col" class="text-center">Email</th> -->
                                    <th scope="col" class="text-center">Created Date</th>
                                    <th scope="col" class="text-center">Status</th>
                                    <th scope="col" class="text-center">Log</th>
                                    <th scope="col" class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let x of userList | paginate:{itemsPerPage: PageSize, currentPage:p};let i = index"
                                    class="text-center">
                                    <td>{{PageSize*(p-1)+ i+1}}</td>
                                    <td>{{x.UserCode}}</td>
                                    <td>{{x.UserName}}</td>
                                    <td>{{x.UserRoleName}}</td>
                                    <td>{{x.FirstName}} {{x.LastName}}</td>
                                    <td>{{x.Mobile}}</td>
                                    <!-- <td>{{x.Email}}</td> -->
                                    <td>{{x.CreatedDate | date :'mediumDate'}}</td>
                                    <td>
                                        <span *ngIf="x.IsActive" class="badge bg-success">Active</span>
                                        <span *ngIf="!x.IsActive" class="badge bg-gradient-danger">InActive</span>
                                    </td>
                                    <td>
                                        <a href="javascript:void(0);" (click)="ViewLog(x.Id)" title="View Log"
                                        class="btn btn-secondary">View Log</a>
                                    </td>
                                    <td class="text-right py-0 align-middle">
                                        <div class="btn-group btn-group-sm">
                                            <a href="javascript:void(0);" (click)="PreviewRecord(x)" title="Preview"
                                                class="btn btn-secondary"><i class="fas fa-eye"></i></a>
                                            <a href="javascript:void(0);" (click)="EditRecord(x)" title="Edit"
                                                class="btn btn-info" *ngIf="x.Id>1"><i class="fas fa-edit"></i></a>
                                            <a href="javascript:void(0);" (click)="DeleteRecord(x.Id)" title="Delete"
                                                class="btn btn-danger" *ngIf="x.Id>1"><i class="fas fa-trash"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>


                        </table>
                        <pagination-controls class="table-pagination" (pageChange)="p=$event" maxSize="9"
                            directionLinks="true" autoHide="true" responsive="true" previousLabel="Previous"
                            nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                            screenReaderCurrentLabel="You're on page"></pagination-controls>
                        <div class="dtastyle" *ngIf="userList?.length == 0">
                            <span class="search_no_results  ">
                                No data found
                            </span>
                        </div>
                    </div>
                    
                </div>

            </div>
           
        </div>
    </div>
</section>