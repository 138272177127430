export class rolemodel {

    Id: number;
    UserRoleName: string;
    Description: string;
    IsActive: boolean;
    CreatedBy: number | null;
    UpdatedBy: number | null;
    CreatedDate: Date | string | null;
    UpdatedDate: Date | string | null;
}
