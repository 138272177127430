import { Component, OnInit } from '@angular/core';
import { InquiryService } from 'src/app/modules/inquiry/service/inquiry.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { flatten } from '@angular/compiler';
import { ActionMode } from 'src/app/models/enum';
import { DataTransferDTO } from 'src/app/models/DataTransferDTO.model';
import { KeyValuePair } from 'src/app/models/KeyValuePair.model';
import { ListUser } from 'src/app/models/listModel.model';
import { CommonDropdownModel } from 'src/app/models/CommonDropdownModel.model';
import * as _ from 'lodash';
import swal from 'sweetalert';

@Component({
  selector: 'app-list-inquiry',
  templateUrl: './list-inquiry.component.html',
  styleUrls: ['./list-inquiry.component.css']
})
export class ListInquiryComponent implements OnInit {
  public userList: Array<ListUser>;
  public filterData: ListUser = new ListUser();
  //  filterData
  public inquiryData: any = [];
  p: number = 1;
  PageSize: number = 5;
  public firmList: Array<CommonDropdownModel>;
  id: number;
  inquiryIds: any = [];
  AssignedById: any = [];
  FirmId: any = [];
  users: any = [];
  selectedValue: any;
  noCheck: boolean = false;
  checks: boolean = false;
  public searchForm: FormGroup;

  constructor(private _inquiryService: InquiryService,
    private formBuilder: FormBuilder,
    private _commonService: CommonService,
    private _toasterService: ToasterService,
    private router: Router) { }

  ngOnInit(): void {
    this.BindUser();
    this.InitializeSearchForm();
    this.AssignedById = localStorage.getItem("Id");
  }
  InitializeSearchForm() {
    this.searchForm = this.formBuilder.group({
      Name: ['', Validators.nullValidator],
      Mobile: ['',Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
      Email: ['', Validators.required,Validators.email],
    });
  }
  SearchFilter() {
    var self = this;
    self.filterData = self.setFormDataToModel(self.filterData);
   //console.log('self.filterData', self.filterData);
   self.BindUser();
  }
  setFormDataToModel(filterCustomers) {
    filterCustomers.Name = this.searchForm.get('Name') != null ? this.searchForm.get('Name').value : null;
    filterCustomers.Mobile = this.searchForm.get('Mobile') != null ? this.searchForm.get('Mobile').value : null;
    filterCustomers.Email = this.searchForm.get('Email') != null ? this.searchForm.get('Email').value : null;
    return filterCustomers;
  }
  ResetFiter() {
    this.searchForm.reset();
    this.InitializeSearchForm();
    this.filterData = new ListUser();
    this.BindUser();

  }

  BindUser() {
    let detail = {'Name':this.filterData.Name,'Mobile': this.filterData.Mobile, 'Email': this.filterData.Email};
    var self = this;
    self._inquiryService.filterbind(detail).subscribe(response => {
      if (response.IsSuccess) {
        this.inquiryData = response.Data;
      }
      else {
        this.inquiryData = [];
      }
    });
  }
  bulk(e) {
    var checkboxes = document.getElementsByClassName('custom-control-input');
    var vals = "";
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i].id) {

        this.checks = false;
        var matches = checkboxes[i].id.match(/(\d+)/);
        if (matches && this.inquiryIds.indexOf(parseInt(matches[0])) === -1) {
          this.checks = true;
          this.inquiryIds.push(parseInt(matches[0]));
          // console.log(matches[0]);
        }

      }
    }
    
    if (e.target.checked == true) {
      this.checks = true;
      
    }
    else {
      this.checks = false;
      
    }
  }
  getInquiryId(e, id) {

    if (e.target.checked == true && this.inquiryIds.indexOf(id) === -1) {
      this.noCheck = true;
      this.inquiryIds.push(id);
    }
    else if (e.target.checked == false && this.inquiryIds.indexOf(id) > -1) {
      this.checks = false;
      this.inquiryIds = this.inquiryIds.filter(function (value, index, arr) {
        return value !== id;
      });
    }


  }

  assignlead() {
    let detail = { 'AssignedToUserId': this.selectedValue, 'LeadIdList': this.inquiryIds, 'AssignedBy': this.AssignedById };

    this._inquiryService.assignData(detail).subscribe(res => {
      if (res.IsSuccess) {
        this._toasterService.pop('success', "Success", res.Message);
      }
      else {
        this._toasterService.pop('error', 'Error', res.Message);

      }
    });
  }
  PreviewRecord(currentRow: ListUser) {
    if (currentRow && currentRow.Id > 0) {
      let dataTransferDTO: DataTransferDTO = new DataTransferDTO();
      dataTransferDTO.Id = currentRow.Id;
      dataTransferDTO.ActionMode = ActionMode.Preview;
      this._inquiryService.setUsersToService(dataTransferDTO);
      this.router.navigateByUrl('lead/add-lead');
    }
  }
  EditRecord(currentRow: ListUser) {
   // console.log('currentRow', currentRow);
    if (currentRow && currentRow.Id > 0) {
      let dataTransferDTO: DataTransferDTO = new DataTransferDTO();
      dataTransferDTO.Id = currentRow.Id;
      dataTransferDTO.ActionMode = ActionMode.Edit;
      this._inquiryService.setUsersToService(dataTransferDTO);
      this.router.navigateByUrl('lead/add-lead');
    }

  }
  DeleteRecord(Id: number) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      dangerMode: true
    })
      .then((willDelete) => {
        if (willDelete) {
          let dataToPass: KeyValuePair = { key: "id", value: String(Id) };
          this._inquiryService.deleteUserById(dataToPass).subscribe(response => {
            if (response.IsSuccess) {
              this._toasterService.pop('success', "Success", response.Message);
              this.BindUser();
            }

            else {
              this._toasterService.pop('error', 'Error', response.Message);
            }
          });
       

        } else {
          swal("Your file is safe!");
        }
      });
  }
}
