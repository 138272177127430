import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonDropdownModel } from 'src/app/models/CommonDropdownModel.model';
import { CustomerWallet, FilterCustomerWallet } from 'src/app/models/customerDTO/CustomerWallet.model';
import { CommonService } from 'src/app/services/common.service';
import { CustomersService } from '../../customers/service/customers.service';
import { CompanyService } from '../../company/company.service';

@Component({
  selector: 'app-account-book',
  templateUrl: './account-book.component.html',
  styleUrls: ['./account-book.component.css']
})
export class AccountBookComponent implements OnInit {
  public paymentTransactionList: Array<CustomerWallet>;
  public filterData: FilterCustomerWallet = new FilterCustomerWallet();
  public searchForm: FormGroup;
  public paymentMethodList: Array<CommonDropdownModel>;
  public paymentAccountList: Array<CommonDropdownModel>;
  p: number = 1;
  PageSize: number = 20;

  constructor(
    private _customerService: CustomersService,
    private _commonService: CommonService,
    private _companyService: CompanyService,
    private formBuilder: FormBuilder,) { }

  ngOnInit(): void {
    this.InitializeSearchForm();
    this.BindPaymentMethodDDL();
    this.BindPaymentAccountDDL();
  }
  InitializeSearchForm() {
    this.searchForm = this.formBuilder.group({
      PaymentAccountId: ['', Validators.nullValidator],
      PaymentMethodId: ['', Validators.nullValidator],
      PaymentDate: ['', Validators.nullValidator],
    });
  }
  BindCustomersList() {
    var self = this;
    self._customerService.getCustomerWallet(this.filterData).subscribe((response) => {
      if (response.IsSuccess) {
        self.paymentTransactionList = response.Data;
      }
      else {
        self.paymentTransactionList = [];
      }
    });
  }

  SearchFilter() {
    var self = this;
    self.filterData = self.setFormDataToModel(self.filterData);
    self.BindCustomersList();
  }
  setFormDataToModel(filterPaymentTransactions: FilterCustomerWallet) {
    filterPaymentTransactions.Id = this.searchForm.get('Id') != null ? this.searchForm.get('Id').value : null;
    filterPaymentTransactions.Mobile = this.searchForm.get('Mobile') != null ? this.searchForm.get('Mobile').value : null;
    filterPaymentTransactions.PaymentMethodId = this.searchForm.get('PaymentMethodId') != null ? this.searchForm.get('PaymentMethodId').value : null;
    return filterPaymentTransactions;
  }
  ResetFiter() {
    this.searchForm.reset();
    this.InitializeSearchForm();
  }
  BindPaymentMethodDDL() {
    var self = this;
    self._commonService.getPaymentMethodForddl().subscribe((response) => {
      self.paymentMethodList = (response.IsSuccess && response.Data != null && response.Data.length > 0) ? response.Data : [];
    });
  }
  BindPaymentAccountDDL() {
    var self = this;
    self._companyService.getCompanyPaymentAccountForddl().subscribe((response) => {
      self.paymentAccountList = (response.IsSuccess && response.Data != null && response.Data.length > 0) ? response.Data : [];
    });
  }
}
