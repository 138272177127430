import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { ManageAccessComponent } from './component/manage-access/manage-access.component';
import { AdminGuardGuard } from '../../guard/admin-guard.guard';
import {  LocationStrategy, HashLocationStrategy, DatePipe, CurrencyPipe } from '@angular/common';
const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,canActivate:[AdminGuardGuard]
  },
  {
    path: 'manage-access',
    component: ManageAccessComponent,canActivate:[AdminGuardGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class DashboardRoutingModule { }
