<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Bank Accounts</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Bank Accounts</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>
<section class="content add-detail">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card collapsed-card">
                    <div class="card-header b-t-info">
                        <h3 class="card-title" data-card-widget="collapse" data-toggle="tooltip">Search</h3>

                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                                title="Collapse">
                                <i class="fas fa-search"></i></button>
                        </div>
                    </div>
                    <div class="card-body" style="display: block;">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <input class="form-control" type="text" name="AccountName" [(ngModel)]="AccountName"
                                        (ngModelChange)="Search()" placeholder="Enter Account Name to Search">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header b-t-olive">
                        <h3 class="card-title">List</h3>
                        <div class="card-tools">
                            <button type="button" (click)="AddNew()" class="btn btn-block bg-gradient-info">
                                <i class="fas fa-plus"></i> Add</button>
                        </div>
                    </div>
                    <div class="card-body">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col" class="text-center">S No</th>
                                    <th scope="col" class="text-center">Account Name</th>
                                    <th scope="col" class="text-center">Account Type</th>
                                    <th scope="col" class="text-center">Account Number</th>
                                    <th scope="col" class="text-center">Account Balance</th>
                                    <th scope="col" class="text-center">Note</th>
                                    <th scope="col" class="text-center">Account Details</th>
                                    <th scope="col" class="text-center">Created By</th>
                                    <th scope="col" class="text-center">Created Date</th>
                                    <th scope="col" class="text-center">Status</th>
                                    <th scope="col" class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let x of paymentAccountList| paginate:{itemsPerPage: PageSize, currentPage:p} ;let i = index"
                                    class="text-center">
                                    <td>{{PageSize*(p-1)+ i+1}}</td>
                                    <td>{{x.PaymentAccountName}}</td>
                                    <td>{{x.AccountTypeName}}</td>
                                    <td>{{x.AccountNumber}}</td>
                                    <td>{{x.AccountBalance}}</td>
                                    <td>{{x.Note}}</td>
                                    <td>{{x.Label1}} - {{x.Value1}}<br> {{x.Label2}} - {{x.Value2}}<br> {{x.Label3}} -
                                        {{x.Value3}}<br> {{x.Label4}} - {{x.Value4}}<br> {{x.Label5}} - {{x.Value5}}
                                    </td>
                                    <td>{{x.CreatedByName}}</td>
                                    <td>{{x.CreatedDate | date :'mediumDate'}}</td>

                                    <td> <span *ngIf="x.IsActive" class="badge bg-success">Active</span>
                                        <span *ngIf="!x.IsActive" class="badge bg-gradient-danger">InActive</span>
                                    </td>
                                    <td class="text-right py-0 align-middle">
                                        <a href="javascript:void(0);" title="Edit" (click)="FundTransfer(x.Id)"
                                            class="btn btn-sm btn-warning"><i class="fas fa-money-bill-alt"></i> Fund
                                            Transfer</a>
                                        <a href="javascript:void(0);" title="Edit" (click)="Deposit(x.Id)"
                                            class="btn btn-sm btn-success"><i class="fas fa-money-bill-alt"></i>
                                            Deposit</a>
                                        <div class="btn-group btn-group-sm">
                                            <a href="javascript:void(0);" title="Edit" (click)="EditRecord(x)"
                                                class="btn btn-info"><i class="fas fa-edit"></i></a>
                                            <a href="javascript:void(0);" title="Delete" (click)="DeleteRecord(x.Id)"
                                                class="btn btn-danger"><i class="fas fa-trash"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="paymentAccountList && paymentAccountList.length==0">
                                <tr>
                                    <td colspan="8" class="text-center">
                                        No Records Found.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <pagination-controls class="table-pagination" (pageChange)="p=$event" maxSize="9"
                            directionLinks="true" autoHide="true" responsive="true" previousLabel="Previous"
                            nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                            screenReaderCurrentLabel="You're on page" *ngIf="paymentAccountList?.length > 0">
                        </pagination-controls>
                    </div>
                    <!-- /.card-body -->

                </div>
                <div class="modal-backdrop fade show" *ngIf="showList" style="display:block"></div>
                <div draggable="false" class="modal" id="addClass" role="dialog" aria-hidden="true"
                    style="display:block" *ngIf="showList">
                    <div class="modal-dialog modal-lg">
                        <div [formGroup]="registerForm" class="modal-content"
                            [ngClass]="actionMode == ActionModeEnum.Preview ? 'previewModeColor':'defaultModeColor'">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">
                                    <strong *ngIf="actionMode == ActionModeEnum.Add">Add Bank Account</strong>
                                    <strong *ngIf="actionMode == ActionModeEnum.Edit">Edit Bank Account</strong>
                                    <strong *ngIf="actionMode == ActionModeEnum.Preview">Preview Bank
                                        Account</strong>
                                    <button class="close" type="button" (click)="ClosePopup()" aria-label="Close">
                                        <span aria-hidden="true"><i class="fas fa-times" aria-hidden="true"></i></span>
                                    </button>
                                </h5>
                            </div>
                            <div class="modal-body pdf-model">
                                <div class="card-body" style="display: block;">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>Bank Account Name</label>
                                                <input type="text" class="form-control"
                                                    formControlName="PaymentAccountName"
                                                    placeholder="Bank Account Name" maxlength="50"
                                                    [ngClass]="{ 'is-invalid': submitted && f.PaymentAccountName.errors }">
                                                <div *ngIf="submitted && f.PaymentAccountName.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.PaymentAccountName.errors.required">Bank Account
                                                        Name is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>Account Type</label><span class="redStar">*</span>
                                                <select class="form-control" formControlName="AccountTypeId"
                                                    [ngClass]="{ 'is-invalid': submitted && f.AccountTypeId.errors }">
                                                    <option value="">-- Select --</option>
                                                    <option *ngFor="let x of AccountTypeDDL;" [ngValue]="x.Id">
                                                        {{x.Name}}
                                                    </option>
                                                </select>
                                                <div *ngIf="submitted && f.AccountTypeId.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.AccountTypeId.errors.required">
                                                        Account Type is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>Account Number</label>
                                                <input type="text" class="form-control" formControlName="AccountNumber"
                                                    placeholder="Account Number" maxlength="50"
                                                    [ngClass]="{ 'is-invalid': submitted && f.AccountNumber.errors }">
                                                <div *ngIf="submitted && f.AccountNumber.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.AccountNumber.errors.required">Account Number is
                                                        required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>Opening Balance</label>
                                                <input type="number" class="form-control"
                                                    formControlName="OpeningBalance" placeholder="Opening Balance"
                                                    maxlength="10"
                                                    [ngClass]="{ 'is-invalid': submitted && f.OpeningBalance.errors }">
                                                <div *ngIf="submitted && f.OpeningBalance.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.OpeningBalance.errors.required">Opening Balance is
                                                        required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <label>Label</label>
                                        </div>
                                        <div class="col-6">
                                            <label>Value</label>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" formControlName="Label1"
                                                    maxlength="50">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" formControlName="Value1"
                                                    maxlength="50">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" formControlName="Label2"
                                                    maxlength="50">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" formControlName="Value2"
                                                    maxlength="50">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" formControlName="Label3"
                                                    maxlength="50">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" formControlName="Value3"
                                                    maxlength="50">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" formControlName="Label4"
                                                    maxlength="50">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" formControlName="Value4"
                                                    maxlength="50">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" formControlName="Label5"
                                                    maxlength="50">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" formControlName="Value5"
                                                    maxlength="50">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>Note</label>
                                                <input type="text" class="form-control" formControlName="Note"
                                                    placeholder="Note" maxlength="50">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>Status</label>
                                                <input type="checkbox" name="my-checkbox"
                                                    class="form-check-input chkMargin" formControlName="IsActive"
                                                    data-bootstrap-switch data-off-color="danger"
                                                    data-on-color="success">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer"
                                *ngIf="actionMode == ActionModeEnum.Add || actionMode == ActionModeEnum.Edit">
                                <button type="button" (click)="Reset()" class="btn btn-secondary float-left">
                                    <i class="fas fa-times"></i> Reset</button>
                                <button type="button" (click)="Save()" class="btn btn-primary float-right">
                                    <i class="fas fa-save"></i> Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-backdrop fade show" *ngIf="showFundTransfer" style="display:block"></div>
                <div draggable="false" class="modal" role="dialog" aria-hidden="true" style="display:block"
                    *ngIf="showFundTransfer">
                    <div class="modal-dialog">
                        <div [formGroup]="paymentForm" class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">
                                    <strong>{{actionPayment}}</strong>
                                    <button class="close" type="button" (click)="ClosePopup2()" aria-label="Close">
                                        <span aria-hidden="true"><i class="fas fa-times" aria-hidden="true"></i></span>
                                    </button>
                                    <input type="hidden" formControlName="hdnId">
                                </h5>
                            </div>
                            <div class="modal-body pdf-model">
                                <div class="card-body" style="display: block;">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label *ngIf="actionPayment=='Fund Transfer'">Transfer To</label>
                                                <label *ngIf="actionPayment=='Deposit'">Deposit From</label>
                                                <span class="redStar">*</span>
                                                <select class="form-control" formControlName="PaymentAccountId"
                                                    [ngClass]="{ 'is-invalid': submitted && g.PaymentAccountId.errors }">
                                                    <option value="">-- Select --</option>
                                                    <option *ngFor="let x of PaymentAccountDDL;" [ngValue]="x.Id">
                                                        {{x.Name}}
                                                    </option>
                                                </select>
                                                <div *ngIf="submitted && g.PaymentAccountId.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="g.PaymentAccountId.errors.required">
                                                        Payment Account is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Amount</label>
                                                <input type="text" class="form-control" formControlName="Amount"
                                                    placeholder="Amount" maxlength="50"
                                                    [ngClass]="{ 'is-invalid': submitted && g.Amount.errors }">
                                                <div *ngIf="submitted && g.Amount.errors" class="invalid-feedback">
                                                    <div *ngIf="g.Amount.errors.required">Amount is
                                                        required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Date</label>
                                                <input type="text" class="form-control" formControlName="Date"
                                                    placeholder="Date" maxlength="50"
                                                    [ngClass]="{ 'is-invalid': submitted && g.Date.errors }"
                                                    ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()">
                                                <div *ngIf="submitted && g.Date.errors" class="invalid-feedback">
                                                    <div *ngIf="g.Date.errors.required">Date is
                                                        required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Note</label>
                                                <input type="text" class="form-control" formControlName="Note"
                                                    placeholder="Note" maxlength="50">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <button type="button" (click)="Submit()" class="btn btn-primary float-right">
                                    <i class="fas fa-save"></i> Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>