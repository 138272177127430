import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import{AddInquiryComponent} from 'src/app/modules/inquiry/add-inquiry/add-inquiry.component';
import{ListInquiryComponent} from 'src/app/modules/inquiry/list-inquiry/list-inquiry.component';
import{RoleAccessComponent} from './role-access/role-access.component';
import { AssignLeadComponent } from './assign-lead/assign-lead.component';
import { AdminGuardGuard } from 'src/app/guard/admin-guard.guard';
import { TestContactFormComponent } from './test-contact-form/test-contact-form.component';

const routes: Routes = [
  {
    path: 'lead/add-lead',
    component: AddInquiryComponent
  },
  {
    path: 'lead/list-lead',
    component: ListInquiryComponent
  },
 
  {
    path: 'lead/assign-lead',
    component: AssignLeadComponent
  },
  
  {
    path: 'lead/test-contact-form',
    component: TestContactFormComponent
  }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InquiryRoutingModule { }
