import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {  CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule, routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './common/header/header.component';
import { SideBarComponent } from './common/side-bar/side-bar.component';
import { FooterComponent } from './common/footer/footer.component';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { CustomersModule } from './modules/customers/customers.module';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { ToasterModule } from 'angular2-toaster';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, LocationStrategy, HashLocationStrategy, DatePipe, CurrencyPipe } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CommonService } from './services/common.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { VendorsModule } from './modules/vendors/vendors.module';
import {InquiryModule} from './modules/inquiry/inquiry.module';
import{UserModule} from './modules/user/user.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import{RoleModule} from './modules/role/role.module';
import{MenuModule} from'./modules/menu/menu.module';
import{CRMModule} from 'src/app/modules/crm/crm.module';
import {NgxPaginationModule} from 'ngx-pagination'; 
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import{CompanyModule} from 'src/app/modules/company/company.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ColorPickerModule } from 'ngx-color-picker';
import{ParcelSlipModule} from 'src/app/parcel-slip/parcel-slip.module';
import {TestRoleModule} from 'src/app/modules/test-role/test-role.module'

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SideBarComponent,
    FooterComponent,
    
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    ToasterModule.forRoot(),
    AppRoutingModule,
    AuthenticationModule,
    RouterModule.forRoot(routes),
    DashboardModule,
    NgSelectModule,
    NgxPaginationModule,
    InquiryModule,
    UserModule,
    RoleModule,
    MenuModule,
    CRMModule,
    Ng2SearchPipeModule,
    CompanyModule,
    NgbModule,
    ParcelSlipModule,
    ColorPickerModule,
    TestRoleModule
  ],

   providers: [
    {
       provide: LocationStrategy,
       useClass: HashLocationStrategy,
   },
     DatePipe, CurrencyPipe, CommonService
   ],
  entryComponents: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
