import { Injectable } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { HttpDataService } from './http-data.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from '../modules/authentication/service/login.service';
import { ResponseModel } from '../models/ResponseModel';
import { Observable } from 'rxjs';
import { URLList } from '../common/Utility/URL';
import { KeyValuePair } from '../models/KeyValuePair.model';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private _httpDataService: HttpDataService,
    private router: Router,
    private _toasterService: ToasterService,
    private _spinner: NgxSpinnerService,
    private _loginService: LoginService) {

  }
  getDropDownText(Id, object) {
    let item = object.find(x => x.Id == Id);
    return item;
  }
  showSuccessMessage(message: string) {
    this.showAlert('success', message);
  }
  showErrorMessage(message: string) {
    this.showAlert('error', message);
  }
  showWarningMessage(message: string) {
    this.showAlert('warning', message);
  }
  showAlert(title: string, message: string) {
    this._toasterService.pop(title, title, message);
  }
  parentSpinnerShow() {
    this._spinner.show();
  }
  parentSpinnerHide() {
    this._spinner.hide();
  }
  validateAPIResponse(response: ResponseModel) {
    if (!response) {
      return false;
    }
    else if (response.IsSuccess == false) {
      if (!response.Message) {
        response.Message = "Something went worng . Try again."
      }
      this.showErrorMessage(response.Message)
      return false;
    }
    else if (response.IsSuccess == true) {
      return true;
    }
    return false;
  }
  getUnitForddl(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Common.GetUnitForddl, false);
  }
  getVariationForddl(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Common.GetVariationForddl, false);
  }
  getTaxForddl(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Common.GetTaxForddl, false);
  }
  getVendorForddl(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Common.GetVendorForddl, false);
  }
  getBrandForddl(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Common.GetBrandForddl, false);
  }
  getPaymentMethodForddl(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Common.GetPaymentMethodForddl, false);
  }
  getProductsCategoryForddl(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Common.GetProductsCategoryForddl, requestData);
  }
  GetProductsCategoryForddlForOrder(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Common.GetProductsCategoryForddlForOrder);
  }
  getProductsForddl(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Common.GetProductsForddl, false);
  }

  getFirmsForddl(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Common.GetFirmsForddl, false);
  }
  getCustomerTypesForddl(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Common.GetCustomerTypesForddl, false);
  }
  getParentCustomersForddl(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Common.GetParentCustomersForddl, false);
  }

  getCustomersForddl(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Common.GetCustomersForddl, false);
  }
  getCourierCompanyForddl(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Common.GetCourierCompanyForddl, false);
  }
  getWareHouseForddl(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Common.GetWareHouseForddl, false);
  }
  getOrderStatusForddl(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Common.GetOrderStatusForddl, requestData);
  }
  getPaymentStatusForddl(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Common.GetPaymentStatusForddl, false);
  }
  getUserRolesForddl(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Common.GetUserRolesForddl, false);
  }
  getCountryForddl(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Common.GetCountryForddl, false);
  }
  getStateForddl(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Common.GetStateForddl, requestData);
  }
}
