<!--The content below is only a placeholder and can be replaced.-->
<div class="clearfix"></div>
<ngx-spinner size="medium" type="ball-beat">
</ngx-spinner>
<toaster-container [toasterconfig]="toasterConfig"></toaster-container>

<div class="wrapper" *ngIf="!readyToLoad">
  <app-header></app-header>
  <app-side-bar></app-side-bar>
  
</div>
<div class="content-wrapper" [ngClass]="{ 'full-width': readyToLoad }">
  <router-outlet></router-outlet>
</div>

<!-- <footer class="main-footer" *ngIf="!readyToLoad">
  <app-footer></app-footer>
</footer> -->