<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Assign Leads</h1>
      </div>
    </div>
  </div>
</section>

<section class="content add-detail">
  <div class="container-fluid">

    <div class="col-12">
      <div class="card collapsed-card">
        <div class="card-body" style="display: block;">
          <div class="row">
            <div class="col-md-9">
              <div class="form-group">
                <label>Assign to User (sales person)</label>
                <select name="my-select" class="form-control" [(ngModel)]="selectedValue">
                  <option value="0">--Select--</option>
                  <option *ngFor="let user of users" [ngValue]="user.Id">
                    {{user.Name}}
                  </option>
                </select>
                
                <div *ngIf="showError">
                  <p class="text-red">Please select the user or leads</p>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <button type="submit" (click)="(selectedValue > 0 && this.inquiryIds.length >0) ? assignlead() : showError= true"
                  class="btn btn-block btn-info btnstyle"> <i class="fas fa-user"></i>
                  Assign Lead</button>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header b-t-olive">
          <h3 class="card-title">Leads List</h3>
        </div>
        <div class="card-body">

          <table class="table table-bordered" *ngIf="inquiryData?.length>0">
            <thead>
              <tr class="text-center">
                <th>
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" [checked]="noCheck" (change)="bulk($event)" class="custom-control-input"
                      id="customAllCheck">
                    <label class="custom-control-label" for="customAllCheck"></label>
                  </div>
                </th>
                <th>S.No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Address</th>
                <th>City</th>
                <th>Source</th>
                <th>Remark</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of inquiryData; let i = index"
                class="text-center">
                <td>
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" [checked]="checks" (change)="getInquiryId($event,data.Id)"
                      attr.id="customCheck{{data.Id}}" class="custom-control-input">
                    <label class="custom-control-label" for="customCheck{{data.Id}}"></label>
                  </div>
                </td>
                <td>{{i+1}}</td>
                <td>{{data.FirstName}} {{data.LastName}}</td>
                <td>{{data.Email}}</td>
                <td>{{data.Mobile}}</td>
                <td>{{(data.Address.length>10)? (data.Address | slice:0:10)+'..':(data.Address)}}</td>
                <td>{{data.City}}</td>
                <td>{{data.Source}}</td>
                <td>{{(data.Remark.length>10)? (data.Remark | slice:0:10)+'..':(data.Remark)}}</td>

              </tr>

              
            </tbody>
          </table>
          
          <div class="dtastyle" *ngIf="inquiryData?.length == 0">
            <span class="search_no_results  ">
              No data found
            </span>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>