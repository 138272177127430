export enum UserRole {
  SuperAdmin = 0,
  SendPushNotification,
  UserManagement,
  CourseCreationandPreview,
  CoursePublishing,
}
export enum Status {
  Active = 1,
  Inactive = 0,
}
export enum MediaType {
  FrontImage = 0,
  BackImage = 1,
  BookPdf = 2
}
export enum MasterList {
  UserType = 1,
  Gender = 2,
  Section = 3,
  Category = 4,
  Subject = 5,
  Author = 6,
  DeviceCategory = 7

}
export enum ResponseStatus //Status of response eg: failure, success etc.
{
  ERROR = 10000,
  SUCCESS = 10001,
  RECORDNOTFOUND = 10002,
  INSERT_FAIL = 10003,
  UPDATE_FAIL = 10004,
  DELETE_FAIL = 10005,
  NO_RECORD_FOUND = 10006,
  CUSTOM_ERROR = 10007,
  PASS_ALREADY_EXISTED = 10008,
}
export enum ActionMode {
  Add = 0,
  Edit = 1,
  Preview = 2,
  Delete = 3,
  Copy = 4
}