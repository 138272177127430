import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/modules/authentication/service/login.service';
import { HttpDataService } from 'src/app/services/http-data.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OTPComponent implements OnInit {
  otpField: number;
  public userId;
  maskid: any;
  email: any;

  constructor(private router: Router,
    private service: LoginService,
    private _httpService: HttpDataService,
  ) { }


  ngOnInit(): void {
    let str = localStorage.getItem("Email").split('');

    let finalArr = [];
    let len = str.indexOf('@');
    str.forEach((item, pos) => {

      (pos >= 1 && pos <= len - 2) ? finalArr.push('*') : finalArr.push(str[pos]);
    })
    this.email = finalArr.join('')
  }
  onSubmit() {
    let otpDetail = {
      "Id": localStorage.getItem("Id"), "OTP": this.otpField
    }
    this.service.OTP(otpDetail).subscribe(res => {

      if (res.IsSuccess == true) {
        localStorage.setItem('FullName', res.Data.FullName);
        localStorage.setItem('Token', res.Data.Token);
        localStorage.setItem('Authorization', res.Data.Token);
        this._httpService.SetHeader();
        // this.router.navigateByUrl('/dashboard');
        this.router.navigate(['/dashboard'])
          .then(() => {
            window.location.reload();
          });
      }

    });
  }
}

