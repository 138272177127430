<div class="login-box">
  <div class="login-logo">
    <img src="../../../../../assets/images/Sample1.png" alt="Inventory Manage" class="logo-default"
      style="margin-top:0px; max-height:150px; ">
 
  </div>

  <div class="card">
    <div class="card-body login-card-body">
      <p class="login-box-msg">Sign in to start your session</p>
      
      <div class="row align-items-center login-row">
        <div class="col-sm-12 form-horizontal">

          <div class="form-group">
            <input [(ngModel)]="username" type="text" placeholder="Enter Username" class="form-control">
          </div>

          <div class="form-group">
            <input [(ngModel)]="password" type="password" placeholder="Enter Password" class="form-control">
          </div>

          <div class="form-group">
            <button (click)="doLogin(username,password)" [disabled]="!canLogIn()" class="btn btn-success btn-block">Login </button>
          </div>

        </div>
    </div>
  
  </div>
</div>