import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule ,Validator} from '@angular/forms';
import { FormsModule } from '@angular/forms'; 
import { AbstractControl } from '@angular/forms';
import { UserRoutingModule } from './user-routing.module';
import { AddUserComponent } from './add-user/add-user.component';
import { UserListComponent } from './user-list/user-list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import {  LocationStrategy, HashLocationStrategy, DatePipe, CurrencyPipe } from '@angular/common';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ProfileComponent } from './profile/profile.component';



@NgModule({
  declarations: [AddUserComponent, UserListComponent, ChangePasswordComponent, ProfileComponent,],
  imports: [
    UserRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    CommonModule,
    
  ]
})
export class UserModule { }
