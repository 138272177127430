<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Change Password</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Change Password</li>
                </ol>
            </div>
        </div>
    </div><!-- /.container-fluid -->
</section>
<section class="content add-detail">
    <div class="container-fluid">
        <div [formGroup]="userForm" class="card row">
            <div class="col-12">
                <div class="form-group">
                    <label>Current Password</label><span class="redStar">*</span>
                    <input type="password" class="form-control" formControlName="CurrentPassword"
                        placeholder="Current Password" maxlength="100"
                        [ngClass]="{ 'is-invalid': submitted && f.CurrentPassword.errors }">
                    <div *ngIf="submitted && f.CurrentPassword.errors" class="invalid-feedback">
                        <div *ngIf="f.CurrentPassword.errors.required"> Current Password is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label>New Password</label><span class="redStar">*</span>
                    <input type="password" class="form-control" formControlName="NewPassword" placeholder="New Password"
                        maxlength="100" [ngClass]="{ 'is-invalid': submitted && f.NewPassword.errors }">
                    <div *ngIf="submitted && f.NewPassword.errors" class="invalid-feedback">
                        <div *ngIf="f.NewPassword.errors.required"> New Password is required
                        </div>
                    </div>
                </div>
            </div> 
            <div class="col-12">
                <div class="form-group">
                    <label>Re-Enter Password</label><span class="redStar">*</span>
                    <input type="password" class="form-control" formControlName="ReEnterPassword"
                        placeholder="Re-Enter Password" maxlength="100"
                        [ngClass]="{ 'is-invalid': submitted && f.ReEnterPassword.errors }">

                        <div *ngIf=" userForm.get('NewPassword').value != userForm.get('ReEnterPassword').value && userForm.get('ReEnterPassword').touched">
                            <p  >
                                Confirm Password must be same
                            </p>
                        </div>

                    <div *ngIf="submitted && f.ReEnterPassword.errors" class="invalid-feedback">
                        <div *ngIf="f.ReEnterPassword.errors.required"> Re-Enter Password is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <button type="button" (click)="Save()" class="btn btn-primary float-right"> <i
                            class="fas fa-save"></i>
                        Change Password</button>
                </div>
            </div>
        </div>
    </div>
</section>