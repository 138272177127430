export class menuList {
      MenuId: number;
      Id: number;
      MenuName: string;
      MenuDescription: string;
      ParentMenuId: number;
      ParentParentMenuId: number;
      MenuLevel: number;
      Url: string;
      IsActive: boolean;
      Icon: string;
      CreateUpdateBy: number;
}
export class menufilter {
      Id: number;
      MenuLevel: number;
      MenuName: string;
}
