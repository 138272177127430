import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActionMode } from 'src/app/models/enum';
import { DataTransferDTO } from 'src/app/models/DataTransferDTO.model';
import { MenuService } from '../menu.service';
import { menuList } from 'src/app/models/commonDTO/menu-list.model';
import { Router } from '@angular/router';
@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.css']
})
export class MenuListComponent implements OnInit {
  menulist: any;
  public allNumbers: number[] = [];
  public searchForm: FormGroup;

  constructor(private _menuService: MenuService,
    private formBuilder: FormBuilder,
    private router: Router) {
      for (let insertNumbers = 0; insertNumbers <= 100; insertNumbers++) {
        this.allNumbers.push(insertNumbers);
      }
     }

  ngOnInit(): void {
    // this.InitializeSearchForm();
    this.GetMenusLists();
  }

  GetMenusLists() {
    this._menuService.GetMenuById().subscribe(res => {
      if (res.IsSuccess) {
        this.menulist =this.list_to_tree(res.Data); ;
      }
    });
  }
  list_to_tree(list) {
    var map = {}, node, roots = [], i;

    for (i = 0; i < list.length; i += 1) {
      map[list[i].Id] = i; // initialize the map
      list[i].ChildMenu = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.ParentMenuId !== 0) {
        list[map[node.ParentMenuId]].ChildMenu.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  }

  // InitializeSearchForm() {
  //   this.searchForm = this.formBuilder.group({
  //     MenuLevel: ['', Validators.required],
  //     MenuName: ['', Validators.required],
  //   });
  // }
 
  EditRecord(currentRow: menuList) {
    if (currentRow && currentRow.Id > 0) {
      let dataTransferDTO: DataTransferDTO = new DataTransferDTO();
      dataTransferDTO.Id = currentRow.Id;
      dataTransferDTO.ActionMode = ActionMode.Edit;
      this._menuService.setUsersToService(dataTransferDTO);
      this.router.navigateByUrl('menu');
    }

  }
  PreviewRecord(currentRow: menuList) {
    if (currentRow && currentRow.Id > 0) {
      let dataTransferDTO: DataTransferDTO = new DataTransferDTO();
      dataTransferDTO.Id = currentRow.Id;
      dataTransferDTO.ActionMode = ActionMode.Preview;
      this._menuService.setUsersToService(dataTransferDTO);
      this.router.navigateByUrl('menu');
    }
  }

}