   import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { ToasterService, Toast } from 'angular2-toaster';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable, of, Subject } from 'rxjs';
//import { JWTPayloadData, UserData, CustomerRoleSummary, AccountStatus } from 'src/app/Model/JWTPayloadData';
import { environment } from '../../environments/environment';
import { RequestErrors } from '../common/Utility/RequestErrors';
import { UrlData } from '../common/Utility/URL';
import { ResponseStatus } from '../models/enum';
import { KeyValuePair } from '../models/KeyValuePair.model';
@Injectable({
  providedIn: 'root'
})
export class HttpDataService {
  private _loginFrom: number;
  private _baseUrl: string = environment.ApiUrl;
  private _header: HttpHeaders;
  private _fileheader: HttpHeaders;
  private _async: boolean = false;
  private _sections: Array<string>;
  public _sectionsSubject: Subject<Array<string>> = new Subject<Array<string>>();
  public _companySubject: Subject<string> = new Subject<string>();


  constructor(private _httpClient: HttpClient
    , private _toasterService: ToasterService
    , private _spinner: NgxSpinnerService
    , private _router: Router
  ) 
  {
    if (localStorage.getItem('Token'))
      this.SetHeader();
    else
      this._header = new HttpHeaders({ 'Content-Type': 'application/json' });
  }

  SetHeader() {
    // console.log('set header', localStorage.getItem('Token'));
    this._header = new HttpHeaders({ 'Content-Type': 'application/json' }).set('Authorization', 'Bearer ' + localStorage.getItem('Token'));
    this._fileheader = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('Token'));
   
  }
  parentSpinnerShow() {
    this._spinner.show();
  }
  parentSpinnerHide() {
    this._spinner.hide();
  }

  ResetHeader() {
    this._header = this._header["headers"].set("authorization", null);
    this._fileheader = new HttpHeaders().set('Authorization', null);
  }

  setSections(sections) {
    this._sections = sections;
    this._sectionsSubject.next(sections);
  }

  getSections() {
    return this._sections;
  }

  setLoginFrom(loginFrom) {
    this._loginFrom = loginFrom;
    //this._sectionsSubject.next(sections);
  }

  getLoginFrom() {
    return this._loginFrom;
  }
  getCompany = function (): string { return this._company; }
  IsCustomerPaymentModeTopUp = function (): boolean { return this._isCustomerPaymentModeTopUp; }


  showHideSpinner(async: boolean, urlData: UrlData) {
    if (!async) {
      this.parentSpinnerShow();
    }
  }

  PopToast(type: string, title: string, body: string): void {
    var toast: Toast = {
      type: type,
      title: title,
      body: body
    };
    this._toasterService.pop(toast);
  }

  Spinner(status: boolean) {
    if (!this._async && status) {
      this._spinner.show();
    }
    else if (!this._async && !status) {
      this._spinner.hide();
    }
  }

  public GetList<T>(urlData: UrlData, async: boolean = false): Observable<T[]> {
    this._async = async;
    this.showHideSpinner(async, urlData);

    let url: string = this.GetUrl(urlData);

    return this._httpClient.get<T[]>(url, { headers: this._header, observe: 'response' })
      .pipe(
        map((res: HttpResponse<T[]>) => {
          return this.setGenericResponse(res.body, async, urlData);
        })
        , catchError(this.handleError.bind(this))
      );
  }

  private GetUrl(urlData: UrlData): string {
    return (environment.ApiUrl) + urlData.URL;
  }

  public Get<T>(urlData: UrlData, async: boolean = false): Observable<T> {
    this._async = async;
    this.showHideSpinner(async, urlData);

    let url: string = this.GetUrl(urlData);
    return this._httpClient.get<T>(url, { headers: this._header, observe: 'response' })
      .pipe(
        map((res: HttpResponse<T>) => {
          return this.setGenericResponse(res.body, async, urlData);
        })
        , catchError(this.handleError.bind(this))
      );
  }

  public GetById<T>(urlData: UrlData, data, async: boolean = false, spinner: boolean = true): Observable<T> {
    this._async = async;
    this.showHideSpinner(async, urlData);
    let url: string = this.GetUrl(urlData);
    return this._httpClient.get<T>(url, { params: data, headers: this._header, observe: 'response' })
      .pipe(
        map((res: HttpResponse<T>) => {
          return this.setGenericResponse(res.body, async, urlData);
        })
        , catchError(this.handleError.bind(this))
      );
  }

  public GetToken(url: string, data) {
    url = this._baseUrl + url;
    return this._httpClient.post(url, data, { responseType: 'text' })
      .pipe(map((data: string) => {
        //this.Spinner(false);
        return data;
      }),
        catchError(this.handleError.bind(this))
      );
  }

  postMethod<T>(urlData: UrlData, data, async: boolean = false): Observable<T> {
    this.showHideSpinner(async, urlData);
    let url: string = this.GetUrl(urlData);
    return this._httpClient.post<T>(url, data, { headers: this._header, observe: 'response' })
      .pipe(
        map((res: HttpResponse<T>) => {
          return this.setGenericResponse(res.body, async, urlData)
        }
        )
        , catchError(this.handleError.bind(this))
      );
  }

  postMethodWithId<T>(urlData: UrlData, data, param, async: boolean = false): Observable<T> {
    this._async = async;
    this.showHideSpinner(async, urlData);

    let url: string = this.GetUrl(urlData);
    return this._httpClient.post<T>(url, data, { params: param, headers: this._header, observe: 'response' })
      .pipe(
        map((res: HttpResponse<T>) => {
          return this.setGenericResponse(res.body, async, urlData);
        })
        , catchError(this.handleError.bind(this))
      );
  }

  postMethodParams<T>(urlData: UrlData, data, param, async: boolean = false): Observable<T> {
    this._async = async;
    this.showHideSpinner(async, urlData);

    let url: string = this.GetUrl(urlData);
    return this._httpClient.post<T>(url, data, { params: param, headers: this._header, observe: 'response' })
      .pipe(
        map((res: HttpResponse<T>) => {
          return this.setGenericResponse(res.body, async, urlData);
        })
        , catchError(this.handleError.bind(this))
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status == 401) {
      this._toasterService.pop('error', 'UnAuthorized', RequestErrors.NotAuthorized);
      //this.PopToast('error', 'Information', RequestErrors.SessionExpired);
      localStorage.removeItem("Authorization");
      this._router.navigateByUrl('/Login');
      //return;
    }
    else if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      //console.error('An error occurred:', error.error.message);
      this._toasterService.pop('error', 'Server Error', RequestErrors.Generic);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      this._toasterService.pop('error', 'Server Error', RequestErrors.Generic);
    }
    this._async = false;
    // this.Spinner(false);
    this.parentSpinnerHide();
    // return an observable with a user-facing error message
    return throwError(error.status == 401 ? "" :
      RequestErrors.Generic);
  };

  postFile<T>(urlData: UrlData, data, async: boolean = false): Observable<T> {
    this._async = async;
    this.showHideSpinner(async, urlData);

    let url: string = this.GetUrl(urlData);
    //var headers = new HttpHeaders().set('Authorization', localStorage.getItem('Token'));
    return this._httpClient.post<T>(url, data, { headers: this._fileheader, observe: 'response' })
      .pipe(
        map((res: HttpResponse<T>) => {
          if (!async)
            this.parentSpinnerHide();
            return this.setGenericResponse(res.body, async);
        })
        , catchError(this.handleError.bind(this))
      );
  }

  RequestWithUrl(url: string, async: boolean = false): Observable<any> {
    this._async = async;
    if (!async)
      this.parentSpinnerShow();

    return this._httpClient.get(url)
      .pipe(
        map(res => {
          if (!async)
            this.parentSpinnerHide();
          return res;
        }), catchError(this.handleError.bind(this))
      );
  }
  postMethodWithFile<T>(urlData: UrlData, data, async: boolean = false): Observable<T> {
    this._async = async;
    this.showHideSpinner(async, urlData);

    let url: string = this.GetUrl(urlData);
    return this._httpClient.post<T>(url, data, { headers: this._fileheader, observe: 'response' })
      .pipe(
        map((res: HttpResponse<T>) => {
          if (!async)
            this.parentSpinnerHide();
            return this.setGenericResponse(res.body, async);
        })
        , catchError(this.handleError.bind(this))
      );
  }
  postMethodParam<T>(url: string, data, param, async: boolean = false): Observable<T> {
    this._async = async;
    if (!async)
      this._spinner.show();

    url = this._baseUrl + url;
    return this._httpClient.post<T>(url, data, { params: param, headers: this._header, observe: 'response' })
      .pipe(
        map((res: HttpResponse<T>) => {
          return this.setGenericResponse(res.body, async);
        })
        , catchError(this.handleError.bind(this))
      );
  }
  setGenericResponse(data, async: boolean, urlData: UrlData = null) {
    if (!async) {
      this.parentSpinnerHide();
    }
    if (urlData) {
      if (data.IsSuccess || data.Status == ResponseStatus.SUCCESS) {
        return data;
      }
      else {
        this._toasterService.pop('error', " ", data.Message);
        return data;
      }
    }
    else {
      if (data.IsSuccess || data.Status == ResponseStatus.SUCCESS) {
        return data;
      }
      else {
        // this._toasterService.pop('error', " ", data.Message);
        return data;
      }
    }
  }

  //New Created for Product Page
  public GetByURL<T>(urlData: UrlData, params: KeyValuePair, async: boolean = false): Observable<T> {
    //Pass Data in this format. 
    //let dataToPass: KeyValuePair = { key: "id", value: String(currentRow.Id) };
    this._async = async;
    this.showHideSpinner(async, urlData);
    let url: string = this.GetUrl(urlData);
    //let paramstring = '';
    // for (var key in params) {
    //   paramstring += '&' + params["key"] + '=' + params["value"];
    // }
    // let apiUrl = url + '?' + paramstring.substr(1);

    let apiUrl = url + '?' + params.key + '=' + params.value;;

    return this._httpClient.get<T>(apiUrl, { headers: this._header, observe: 'response' })
      .pipe(
        map((res: HttpResponse<T>) => {
          if (!async) {
            this.parentSpinnerHide();
          }
          return this.setGenericResponse(res.body, async);
        })
        , catchError(this.handleError.bind(this))
      );
  }
}
