import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuRoutingModule } from './menu-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MenuPermissionComponent } from './menu-permission/menu-permission.component';
import { importType } from '@angular/compiler/src/output/output_ast';
import { MenuComponent } from './menu/menu.component';
import { MenuListComponent } from './menu-list/menu-list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import {  LocationStrategy, HashLocationStrategy, DatePipe, CurrencyPipe } from '@angular/common';


@NgModule({
  declarations: [MenuPermissionComponent,
    MenuComponent,
    MenuListComponent,
    
  ],
  imports: [
    CommonModule,
    MenuRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPaginationModule
  ]
})
export class MenuModule { }
