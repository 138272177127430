import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { UserService } from 'src/app/modules/user/user.service';
import { ActionMode } from 'src/app/models/enum';
import { FilterUsers, Users } from 'src/app/models/commonDTO/users.model';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  public users: Users = new Users();
  public registerForm: FormGroup;
  public submitted = false;
  public actionMode: ActionMode = ActionMode.Add;

  constructor(private _userService: UserService,
    private formBuilder: FormBuilder,
    private _toasterService: ToasterService) { }

  ngOnInit(): void {
    this.InitializeForm();
    this.FillData();
  }
  InitializeForm() {
    this.registerForm = this.formBuilder.group({
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      Mobile: [{value: '', disabled: true}, Validators.required],
      Email: ['', [Validators.nullValidator, Validators.email]],
      Address: ['', Validators.required],
    });

    // name: [{value: '', disabled: true}, Validators.required],
    // name: [{value: '', disabled: this.isDisabled}, Validators.required],

    this.actionMode = ActionMode.Add;
  }
  FillData() {

    let filterData: FilterUsers = new FilterUsers();
    filterData.Id = Number(localStorage.getItem("Id"));
    this._userService.getUsers(filterData).subscribe((response) => {
      if (response.IsSuccess) {
        this.users = response.Data[0];
        this.registerForm.patchValue({
          FirstName: this.users.FirstName,
          LastName: this.users.LastName,
          Mobile: this.users.Mobile,
          Email: this.users.Email,
          Address: this.users.Address,
        });
      }
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }
  Save() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      this.registerForm.markAsTouched();
      return;
    }
    else {
      var self = this;
      self.users = this.setFormDataToModel(this.users);
      self._userService.saveUsers(self.users).subscribe((response) => {
        if (response.IsSuccess) {
          localStorage.setItem('FullName', self.users.FirstName + ' ' + self.users.LastName);
          this._toasterService.pop('success', "Success", response.Message);
        }
      });
    }
  }
  setFormDataToModel(user: Users) {
    user.UserName = this.registerForm.get('Mobile').value;
    user.FirstName = this.registerForm.get('FirstName').value;
    user.LastName = this.registerForm.get('LastName').value;
    user.Mobile = this.registerForm.get('Mobile').value;
    user.Email = this.registerForm.get('Email').value;
    user.Address = this.registerForm.get('Address').value;
    return user;
  }
}
