import { Component, OnInit } from '@angular/core';
import{DashboardService} from 'src/app/modules/dashboard/service/dashboard.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
 
  public dashBoardData: any;

  constructor(private dashboardService: DashboardService,  private route: ActivatedRoute, private router: Router) { }
  ngOnInit() {
     this.dashboardService.getDeatails().subscribe(response => {
      if (response.IsSuccess) {
     this.dashBoardData = response.Data;
      //console.log(' this.dashBoardData', this.dashBoardData);
      //console.log('StageName',response.Data.StageName)
     
       }
       else {
         this.dashBoardData = [];
       }
     });
  }
  dashboard(LeadStageId){
    localStorage.setItem("LeadStageId", LeadStageId);
    this.router.navigateByUrl('/reports/report');
 }
}
