import { Injectable } from '@angular/core';
import { HttpDataService } from 'src/app/services/http-data.service';
import { URLList } from 'src/app/common/Utility/URL';
import { Observable } from 'rxjs';
import { ResponseModel } from 'src/app/models/ResponseModel';
import { KeyValuePair } from 'src/app/models/KeyValuePair.model';
import { followlead, filterFollowup } from 'src/app/models/followlead.model';
@Injectable({
  providedIn: 'root'
})
export class CrmService {

  constructor(private _httpDataService: HttpDataService) { }
  getlead(requestData:followlead): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.crm.getlead,requestData);
  }
  getLeadById(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.crm.getLeadById,requestData);
  }
  getLeadsForFollowUp(requestData: filterFollowup): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.crm.getLeadsForFollowUp,requestData);
  }
  saveLead(requestData): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.crm.saveLead, requestData);
  }
  stageDDl(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.crm.DDl);
  }
  
}
