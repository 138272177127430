<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Company Expenses</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Company-Expenses</li>
                </ol>
            </div>
        </div>
    </div><!-- /.container-fluid -->
</section>

<section class="content add-detail">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header b-t-olive">
                        <h2 class="card-title">Company Expenses Lists</h2>

                        <div class="card-tools">
                            <button type="button" (click)="AddCompanyExpenses()"
                                class="btn btn-block bg-gradient-info"><i class="fas fa-plus"></i> Add
                            </button>
                        </div>
                    </div>



                    <div class="col-12 bt-4s ">
                        <div class="form-group">
                            <input class="form-control" type="text" name="ExpensesDetails" [(ngModel)]="ExpensesDetails"
                                (ngModelChange)="Search()" placeholder="Enter Expenses Details to Search">

                        </div>

                    </div>




                    <div class="card-body">
                        <table class="table table-bordered">
                            <thead>
                                <tr>

                                    <th scope="col" class="text-center">Expense Type Id</th>
                                    <th scope="col" class="text-center">Amount</th>
                                    <th scope="col" class="text-center">Payment Method</th>
                                    <th scope="col" class="text-center">Expenses Details</th>
                                    <th scope="col" class="text-center">Expenses File</th>
                                    <th scope="col" class="text-center">Expenses By</th>
                                    <th scope="col" class="text-center">Expenses Date</th>
                                    <th scope="col" class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let x of companyExpensesList | paginate:{itemsPerPage: PageSize, currentPage:p} ;let i = index"
                                    class="text-center">

                                    <td>{{x.ExpenseTypeId}}</td>
                                    <td>{{x.Amount}}</td>
                                    <td>{{x.PaymentMethod}}</td>
                                    <td>{{x.ExpensesDetails}}</td>
                                    <td>{{x.ExpensesFile}}</td>
                                    <td>{{x.ExpensesBy}}</td>
                                    <td>{{x.ExpensesDate | date :'mediumDate'}}</td>
                                    <td class="text-right py-0 align-middle">
                                        <div class="btn-group btn-group-sm">
                                            <a href="javascript:void(0);" (click)="EditRecord(x)" title="Edit"
                                                class="btn btn-info"><i class="fas fa-edit"></i></a>
                                            <a href="javascript:void(0);" (click)="DeleteRecord(x.Id)" title="Delete"
                                                class="btn btn-danger"><i class="fas fa-trash"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="companyExpensesList && companyExpensesList.length==0">
                                <tr>
                                    <td colspan="8" class="text-center">
                                        No Records Found.
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                        <pagination-controls class="table-pagination" (pageChange)="p=$event" maxSize="9"
                            directionLinks="true" autoHide="true" responsive="true" previousLabel="Previous"
                            nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                            screenReaderCurrentLabel="You're on page" *ngIf="companyExpensesList?.length > 0">
                        </pagination-controls>

                    </div>


                </div>
                <div class="modal-backdrop fade show" *ngIf="showList" style="display:block"></div>
                <div draggable="false" class="modal" id="addClass" role="dialog" aria-hidden="true"
                    style="display:block" *ngIf="showList">
                    <div class="modal-dialog modal-lg">
                        <div [formGroup]="registerForm" class="modal-content"
                            [ngClass]="actionMode == ActionModeEnum.Preview ? 'previewModeColor':'defaultModeColor'">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">
                                    <strong *ngIf="actionMode == ActionModeEnum.Add">Add companyExpense</strong>
                                    <strong *ngIf="actionMode == ActionModeEnum.Edit">Edit companyExpense</strong>

                                    <button class="close" type="button" (click)="ClosePopup()" aria-label="Close">
                                        <span aria-hidden="true"><i class="fas fa-times" aria-hidden="true"></i></span>
                                    </button>
                                </h5>
                            </div>
                            <div class="modal-body pdf-model">
                                <div class="card-body" style="display: block;">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>ExpenseTypeId</label><span class="redStar">*</span>
                                                <input type="number" class="form-control" formControlName="ExpenseTypeId"
                                                    placeholder="Expense Type Id" maxlength="100"
                                                    [ngClass]="{ 'is-invalid': submitted && f.ExpenseTypeId.errors }">
                                                <div *ngIf="submitted && f.ExpenseTypeId.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.ExpenseTypeId.errors.required">Expense Type Id is
                                                        required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>Amount</label><span class="redStar">*</span>
                                                <input type="number" class="form-control" formControlName="Amount"
                                                    placeholder="Amount" maxlength="100"
                                                    [ngClass]="{ 'is-invalid': submitted && f.Amount.errors }">
                                                <div *ngIf="submitted && f.Amount.errors" class="invalid-feedback">
                                                    <div *ngIf="f.Amount.errors.required">Amount is
                                                        required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>PaymentMethod</label><span class="redStar">*</span>
                                                <input type="text" class="form-control" formControlName="PaymentMethod"
                                                    placeholder="Payment Method" maxlength="100"
                                                    [ngClass]="{ 'is-invalid': submitted && f.PaymentMethod.errors }">
                                                <div *ngIf="submitted && f.PaymentMethod.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.Amount.errors.required">Payment Method is
                                                        required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>Expenses Details</label><span class="redStar">*</span>
                                                <input type="text" class="form-control"
                                                    formControlName="ExpensesDetails" placeholder="Expenses Details"
                                                    maxlength="100"
                                                    [ngClass]="{ 'is-invalid': submitted && f.ExpensesDetails.errors }">
                                                <div *ngIf="submitted && f.ExpensesDetails.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.ExpensesDetails.errors.required"> Expenses Details is
                                                        required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>ExpensesFile</label><span class="redStar">*</span>
                                            <input type="text" class="form-control" formControlName="ExpensesFile"
                                                placeholder="Expenses File" maxlength="100"
                                                [ngClass]="{ 'is-invalid': submitted && f.ExpensesFile.errors }">
                                            <div *ngIf="submitted && f.ExpensesFile.errors" class="invalid-feedback">
                                                <div *ngIf="f.ExpensesFile.errors.required"> Expenses File is
                                                    required
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="card-footer"
                                *ngIf="actionMode == ActionModeEnum.Add || actionMode == ActionModeEnum.Edit">
                                <button type="button" (click)="Reset()" class="btn btn-secondary float-left"><i
                                        class="fas fa-times"></i>
                                    Reset</button>
                                <button type="button" (click)="Save()" class="btn btn-primary float-right"> <i
                                        class="fas fa-save"></i>
                                    Save</button>
                            </div>
                        </div>


                    </div>

                </div>

            </div>

        </div>

    </div>

</section>