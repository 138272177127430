import { Injectable } from '@angular/core';
import { HttpDataService } from 'src/app/services/http-data.service';
import { ResponseModel } from 'src/app/models/ResponseModel';
import { Observable } from 'rxjs';
import { URLList } from 'src/app/common/Utility/URL';
import { UserModel } from 'src/app/models/UserModel';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public userId:any;
  public Email:any;
  public UserName:any;
  public Password:any;
  
  constructor(private _httpData: HttpDataService) { }
  Login(userModel: UserModel): Observable<ResponseModel> {
    return this._httpData.postMethod<ResponseModel>(URLList.Accounts.login, userModel, false);
  }
  Logout(userModel: UserModel): Observable<ResponseModel> {
    return this._httpData.postMethod<ResponseModel>(URLList.Accounts.userlogout, userModel, true);
  }
  ForgotPassword(userModel: UserModel): Observable<ResponseModel> {
    return this._httpData.postMethod<ResponseModel>(URLList.Accounts.forgotPassword, userModel, true);
  }
  OTP(otpDetail): Observable<ResponseModel> {
    return this._httpData.postMethod<ResponseModel>(URLList.Accounts.OTP,otpDetail,  false);
  }
}