import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';
import{FollowupLeadComponent} from 'src/app/modules/crm/followup-lead/followup-lead.component';

const routes: Routes = [
  {
    path: 'crm/followup-lead',
    component: FollowupLeadComponent//,canActivate:[AdminGuardGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CRMRoutingModule { }
