import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { ActionMode } from 'src/app/models/enum';
import { KeyValuePair } from 'src/app/models/KeyValuePair.model';
import swal from 'sweetalert';
import { CompanyExpenses } from 'src/app/models/Companyexpense.model';
import { CommonDropdownModel } from 'src/app/models/CommonDropdownModel.model';
import{CompanyService} from 'src/app/modules/company/company.service';


@Component({
  selector: 'app-company-expenses',
  templateUrl: './company-expenses.component.html',
  styleUrls: ['./company-expenses.component.css']
})
export class CompanyExpensesComponent implements OnInit {
  public companyExpensesList: Array<CompanyExpenses>;
  public companyExpenses: CompanyExpenses = new CompanyExpenses();
  public showList: boolean = false;
  public actionMode: ActionMode = ActionMode.Add;

  public registerForm: FormGroup;
  public submitted = false;

  p: number = 1;
  PageSize: number = 5;
  ExpenseTypeId: string;
  ExpensesDetails: any;

  constructor(private _companyService: CompanyService,
    private formBuilder: FormBuilder,
    private _toasterService: ToasterService) { }

  ngOnInit(): void {
    this.InitializeForm();
    this.bindCompanyExpenses();
  }
  InitializeForm() {
    this.registerForm = this.formBuilder.group({
      ExpenseTypeId: ['', Validators.required],
      Amount: ['', Validators.required],
      PaymentMethod: ['', Validators.required],
      ExpensesDetails: ['', Validators.required],
      ExpensesFile: ['', Validators.required],
    });
    this.actionMode = ActionMode.Add;
  }
  get f() {
    return this.registerForm.controls;
  }
  public get ActionModeEnum(): typeof ActionMode {
    return ActionMode;
  }
  

  ConvertJsonToModel(jsonStr) {
    return new Map(JSON.parse(jsonStr));
  }

  bindCompanyExpenses() {
    var self = this;
    self._companyService.getCompanyExpenses().subscribe((response) => {
      if (response.IsSuccess) {
        self.companyExpensesList = response.Data;
        //console.log('self.companyExpensesList',self.companyExpensesList);
      }
      else {
        self.companyExpensesList = [];
      }
    });
  }

  Reset() {
    this.submitted = false;
    this.registerForm.reset();
    this.InitializeForm();
    
    
  }
  AddCompanyExpenses() {
    this.showList = true;
    this.InitializeForm();
  }
  ClosePopup() {
    this.showList = false;
  }
  Search() {
    if (this.ExpensesDetails == "") {
      this.ngOnInit();
    }
    else {
      this.companyExpensesList = this.companyExpensesList.filter(res => {
        return res.ExpensesDetails.toLocaleLowerCase().match(this.ExpensesDetails.toLocaleLowerCase());
      })

    }

  }
 

  Save() {
    this.submitted = true;
      if (this.registerForm.invalid) {
        this.registerForm.markAsTouched();
        return;
     }
      else {
    var self = this;
    self.companyExpenses = this.setFormDataToModel(this.companyExpenses);
    self._companyService.saveCompanyExpenses(self.companyExpenses).subscribe((response) => {
      //console.log('saveCourierPartners : ', response);
      if (response.IsSuccess) {
        this.bindCompanyExpenses();
        this._toasterService.pop('success', "Success", response.Message);
        this.Reset();
        this.ClosePopup();
      }
      else {
        this._toasterService.pop('error', 'Error', response.Message);
      }
    });
  }
}

setFormDataToModel(companyExpenses: CompanyExpenses) {
  companyExpenses.ExpenseTypeId = this.registerForm.get('ExpenseTypeId').value;
  companyExpenses.Amount = this.registerForm.get('Amount').value;
  companyExpenses.PaymentMethod = this.registerForm.get('PaymentMethod').value;
  companyExpenses.ExpensesDetails = this.registerForm.get('ExpensesDetails').value;
  companyExpenses.ExpensesFile = this.registerForm.get('ExpensesFile').value;
  return companyExpenses;
}

  
  EditRecord(currentRow: CompanyExpenses) {
    var self = this;
    let dataToPass: KeyValuePair = { key: "id", value: String(currentRow.Id) };
    self._companyService.getCompanyExpensesById(dataToPass).subscribe((response) => {
     // console.log('getBrandsById : ', response);
      if (response.IsSuccess) {
        self.companyExpenses = response.Data[0];
        self.showList = true;
        self.registerForm.patchValue({
          ExpenseTypeId: self.companyExpenses.ExpenseTypeId,
          Amount: self.companyExpenses.Amount,
          PaymentMethod: self.companyExpenses.PaymentMethod,
          ExpensesDetails: self.companyExpenses.ExpensesDetails,
          ExpensesFile: self.companyExpenses.ExpensesFile,
        });
        self.actionMode = ActionMode.Edit;
      }
    });
  }
  DeleteRecord(Id: number) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      dangerMode: true
    })
      .then((willDelete) => {
        if (willDelete) {
          let dataToPass: KeyValuePair = { key: "id", value: Id.toString() };
          this._companyService.deleteCompanyExpenses(dataToPass).subscribe(response => {
            if (response.IsSuccess) {
              this._toasterService.pop('success', "Success", response.Message);
              this.bindCompanyExpenses();
            }

            else {
              this._toasterService.pop('error', 'Error', response.Message);
            }
          });
        } else {
          swal("Your file is safe!");
        }
      });
  }
}
