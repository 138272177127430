<!-- Main content -->
<section class="content add-detail">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card collapsed-card">
                    <div class="card-header b-t-info">
                        <h3 class="card-title" data-card-widget="collapse" data-toggle="tooltip">Search</h3>

                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                                title="Collapse">
                                <i class="fas fa-search"></i></button>
                        </div>
                    </div>
                    <div class="card-body" style="display: block;">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Select User Type</label>
                                    <select class="form-control" formControlName="UserRoleId" id="UserRoleId" (change)="modo($event)">
                                        <option value="">-- Select --</option>
                                        <option *ngFor="let order of userRoles; let i = index"
                                            [ngValue]="userRoles[i].Id">
                                            {{userRoles[i].Name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row float-right">
                            <div class="col-12">
                                <div class="form-group">
                                    
                                    <button type="button" class="btn btn-info" (click)="GetAllMenus()"> <i
                                            class="fas fa-search"></i>
                                        Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ============= Grid/List vendors Section [Start] =================== -->
                <div class="card" *ngIf="menu">
                    <div class="card-header b-t-olive">
                        <h3 class="card-title">Access List</h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                            data-accordion="false">
                            <li class="nav-item has-treeview border" *ngFor="let menuItem of menu">
                                <a class="nav-link" *ngIf="menuItem.ChildMenu.length > 0">
                                    <p>
                                        <i class="left fas fa-angle-down"></i>

                                        {{menuItem?.MenuName}}
                                    </p>
                                </a>
                                <a class="nav-link" *ngIf="menuItem.ChildMenu.length == 0" [routerLink]="menuItem?.Url">
                                    <p>
                                        {{menuItem?.MenuName}}
                                    </p>
                                </a>
                                <ul class="nav nav-treeview" *ngFor="let childItem of menuItem.ChildMenu">
                                    <li class="nav-item has-treeview border">
                                        <a class="nav-link" *ngIf="childItem.ChildMenu.length > 0">
                                            <p>
                                                <i class="left fas fa-angle-down"></i>

                                                {{childItem.MenuName}}
                                            </p>
                                        </a>
                                        <a class="nav-link" *ngIf="childItem.ChildMenu.length == 0"
                                            [routerLink]="childItem.Url">
                                            <i class="nav-icon fas fa-circle"></i>
                                            <p>
                                                {{childItem.MenuName}}
                                            </p>
                                            <button class="btn btn-success pull-right m-l-2">Give Access</button>
                                            <button class="btn btn-danger pull-right">Cancel Access</button>

                                        </a>

                                        <ul class="nav nav-treeview" *ngFor="let lastChildItem of childItem.ChildMenu">
                                            <li class="nav-item">
                                                <a [routerLink]="lastChildItem.Url" class="nav-link">
                                                    <i class="far fa-dot-circle nav-icon"></i>
                                                    <p> {{lastChildItem?.MenuName}}</p>
                                                    <button class="btn btn-success">Give Access</button>
                                                    <button class="btn btn-danger">Cancel Access</button>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>






                                </ul>
                            </li>


                        </ul>
                    </div>
                </div>

            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>
<!-- /.content -->