import { CompanyExpensesComponent } from 'src/app/modules/company/company-expenses/company-expenses.component';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ResponseModel } from 'src/app/models/ResponseModel';
import { HttpDataService } from 'src/app/services/http-data.service';
import { URLList } from 'src/app/common/Utility/URL';
import { KeyValuePair } from 'src/app/models/KeyValuePair.model';
import { CompanyExpenses } from 'src/app/models/Companyexpense.model';
import { CompanyPaymentAccount } from 'src/app/models/companyDTO/CompanyPaymentAccount.model';
import { CompanyAccountType } from 'src/app/models/companyDTO/CompanyAccountType.model';
import { CompanyPaymentTransactions } from 'src/app/models/companyDTO/CompanyPaymentTransactions.model';
@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private _httpDataService: HttpDataService) { }
  getCompanyExpenses(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Company.getCompanyExpenses);
  }
  getCompanyExpensesById(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Company.getCompanyExpenses, requestData);
  }
  saveCompanyExpenses(requestData: CompanyExpenses): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Company.saveCompanyExpenses, requestData);
  }
  deleteCompanyExpenses(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Company.deleteCompanyExpenses, requestData);
  }

  getCompanyPaymentAccount(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Company.GetCompanyPaymentAccount);
  }
  getCompanyPaymentAccountById(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Company.GetCompanyPaymentAccount, requestData);
  }
  getCompanyPaymentAccountForddl(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Company.GetCompanyPaymentAccountForddl);
  }
  saveCompanyPaymentAccount(requestData: CompanyPaymentAccount): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Company.SaveCompanyPaymentAccount, requestData);
  }
  deleteCompanyPaymentAccount(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Company.DeleteCompanyPaymentAccount, requestData);
  }

  GetAccountType(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Company.GetAccountType);
  }
  GetAccountTypeById(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Company.GetAccountType, requestData);
  }
  getAccountTypeForddl(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Company.GetAccountTypeForddl);
  }
  SaveAccountType(requestData: CompanyAccountType): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Company.SaveAccountType, requestData);
  }
  DeleteAccountType(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Company.DeleteAccountType, requestData);
  }

  savePaymentTransactions(requestData: CompanyPaymentTransactions): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Company.SavePaymentTransactions, requestData);
  }
}
