import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { ToasterService } from 'angular2-toaster';
import { KeyValuePair } from 'src/app/models/KeyValuePair.model';
import { ActionMode } from 'src/app/models/enum';
import { menuList } from 'src/app/models/commonDTO/menu-list.model';
import { MenuService } from '../menu.service';
import { DataTransferDTO } from 'src/app/models/DataTransferDTO.model';
import { CommonDropdownModel } from 'src/app/models/CommonDropdownModel.model';
import { Router } from '@angular/router';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  public firstMenuList: Array<CommonDropdownModel>;
  public childMenuList: Array<CommonDropdownModel>;
  public actionMode: ActionMode = ActionMode.Add;
  public dataTransferDTO: DataTransferDTO;
  public menuForm: FormGroup;

  public submitted = false;
  public ShowSecondLevelId: boolean = false;
  public ShowThirdLevelId: boolean = false;
  childId: any;
  level: any;
  ParentMenuId: any;
  public menuList: menuList = new menuList();
  constructor(public formbuilder: FormBuilder,
    private _toasterService: ToasterService,
    public menuService: MenuService,
    private router: Router) { }

  ngOnInit(): void {
    this.InitializeForm();
    this.ReceiveTransmittedData();
  }
  InitializeForm() {
    this.menuForm = new FormGroup({
      FirstLevelId: new FormControl('', Validators.required),
      SecondLevelId: new FormControl(''),
      ThirdLevelId: new FormControl(''),
      MenuName: new FormControl('', Validators.required),
      MenuDescription: new FormControl('', Validators.required),
      Icon: new FormControl('', Validators.required),
      Url: new FormControl('', Validators.nullValidator),
      status: new FormControl(true, Validators.required),
      MenuLevel: new FormControl('', Validators.nullValidator),
    });
  }

  get f() { return this.menuForm.controls; }

  public get ActionModeEnum(): typeof ActionMode {
    return ActionMode;
  }
  Reset() {
    this.submitted = false;
    this.menuForm.reset();
    this.InitializeForm();
  }
  Save() {
    this.submitted = true;
    if (this.menuForm.invalid) {
      this.menuForm.markAsTouched();
      return;
    }
    else {
      this.menuList = this.setFormDataToModel(this.menuList);
      this.menuService.addMenu(this.menuList).subscribe(response => {
        if (response.IsSuccess) {
          this._toasterService.pop('success', "Success", response.Message);
          // this.menuForm.reset();
          // this.submitted = false;
          this.router.navigateByUrl('menu/menu-list');
        }
        else {
          this._toasterService.pop('error', 'Error', response.Message);
        }
      });
    }
  }
  setFormDataToModel(menuList: menuList) {
    menuList.MenuName = this.menuForm.value.MenuName;
    menuList.MenuDescription = this.menuForm.value.MenuDescription;
    menuList.ParentMenuId = this.ParentMenuId;
    menuList.MenuLevel = this.level;
    menuList.Url = this.menuForm.value.Url;
    menuList.Icon = this.menuForm.value.Icon;
    menuList.IsActive = this.menuForm.value.status;
    return menuList;
  }


  addlevel2(val) {
    var self = this;
    self.level = val;
    self.ParentMenuId = '';
    if (val == 3 || val == 2) {
      self.ShowSecondLevelId = true;
      let requestData: KeyValuePair = { key: "ParentMenuId", value: "0" };
      self.menuService.getMenusForddl(requestData).subscribe((response) => {
        self.firstMenuList = (response.IsSuccess && response.Data != null && response.Data.length > 0) ? response.Data : [];
      });
    }
    else if (val == 1) {
      self.ShowSecondLevelId = false;
    }
  }
  childLevel(val) {
    var self = this;
    if (self.level == 3) {
      self.ShowThirdLevelId = true;
      let requestData: KeyValuePair = { key: "ParentMenuId", value: val };
      self.menuService.getMenusForddl(requestData).subscribe((response) => {
        self.childMenuList = (response.IsSuccess && response.Data != null && response.Data.length > 0) ? response.Data : [];
      });
    }
    self.ParentMenuId = val;
  }
  lastchild(val) {
    this.ParentMenuId = val;
  }
  EditRecord(Id) {
    var self = this;
    let dataToPass: KeyValuePair = { key: "MenuId", value: Id };
    self.menuService.getMenuById(dataToPass).subscribe((response) => {
      if (response.IsSuccess) {
        self.menuList = response.Data[0];
        self.menuForm.patchValue({
          FirstLevelId: self.menuList.MenuLevel,
          SecondLevelId: self.menuList.ParentParentMenuId != 0 ? self.menuList.ParentParentMenuId : self.menuList.ParentMenuId,
          ThirdLevelId: self.menuList.ParentMenuId,
          MenuName: self.menuList.MenuName,
          MenuDescription: self.menuList.MenuDescription,
          Url: self.menuList.Url,
          Icon: self.menuList.Icon,
          status: self.menuList.IsActive,
        });

        self.addlevel2(self.menuForm.value.FirstLevelId);
        self.childLevel(self.menuForm.value.SecondLevelId);
        self.lastchild(self.menuForm.value.ThirdLevelId);
        self.actionMode = ActionMode.Edit;
      }
    });
  }
  PreviewRecord(Id) {
    var self = this;
    let dataToPass: KeyValuePair = { key: "MenuId", value: Id };
    self.menuService.getMenuById(dataToPass).subscribe((response) => {
      if (response.IsSuccess) {
        self.menuList = response.Data[0];
        self.menuForm.patchValue({
          FirstLevelId: self.menuList.MenuLevel,
          SecondLevelId: self.menuList.ParentParentMenuId != 0 ? self.menuList.ParentParentMenuId : self.menuList.ParentMenuId,
          ThirdLevelId: self.menuList.ParentMenuId,
          MenuName: self.menuList.MenuName,
          MenuDescription: self.menuList.MenuDescription,
          Url: self.menuList.Url,
          Icon: self.menuList.Icon,
          status: self.menuList.IsActive,
        });
        self.addlevel2(self.menuForm.value.FirstLevelId);
        self.childLevel(self.menuForm.value.SecondLevelId);
        self.lastchild(self.menuForm.value.ThirdLevelId);
        this.menuForm.disable();
        self.actionMode = ActionMode.Preview;
      }
    });
  }

  
  ReceiveTransmittedData() {
    this.dataTransferDTO = this.menuService.getUsersFromService();
    if (this.dataTransferDTO) {
      this.menuService.resetUsersToService();
      switch (this.dataTransferDTO.ActionMode) {
        case ActionMode.Edit:
          this.EditRecord(this.dataTransferDTO.Id);
          break;
        case ActionMode.Preview:
          this.PreviewRecord(this.dataTransferDTO.Id);
          break;

        default:

          break;
      }
    }
  }
}

