import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountBookComponent } from './account-book/account-book.component';
import { AccountTypeComponent } from './account-type/account-type.component';
import { CompanyExpensesComponent } from './company-expenses/company-expenses.component';
import { CompanyPaymentAccountComponent } from './company-payment-account/company-payment-account.component';


const routes: Routes = [
  { path: 'expenses', component: CompanyExpensesComponent },//,canActivate:[AdminGuardGuard]
  { path: 'payment-account', component: CompanyPaymentAccountComponent },//,canActivate:[AdminGuardGuard]
  { path: 'account-type', component: AccountTypeComponent },//,canActivate:[AdminGuardGuard]
  { path: 'account-book', component: AccountBookComponent },//,canActivate:[AdminGuardGuard]
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompanyRoutingModule { }
