import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { UserService } from '../user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  public userForm: FormGroup;
  public submitted = false;
  constructor(private _usersService: UserService, private formBuilder: FormBuilder,
    private _toasterService: ToasterService) { }

  ngOnInit(): void {
    this.InitializeForm();
  }
  InitializeForm() {
    this.userForm = this.formBuilder.group({
      CurrentPassword: ['', Validators.required],
      NewPassword: ['', Validators.required],
      ReEnterPassword: ['', Validators.required],
    });
  }
  get f() {
    return this.userForm.controls;
  }

  Save() {
    this.submitted = true;
    if (this.userForm.invalid) {
      this.userForm.markAsTouched();
      return;
    }
    else {
      var self = this;
      self._usersService.changePassword(self.userForm.value).subscribe((response) => {
        if (response.IsSuccess) {
          this._toasterService.pop('success', "Success", response.Message);
          this.Reset();
        }
      });
    }
  }
  Reset() {
    this.submitted = false;
    this.userForm.reset();
    this.InitializeForm();
  }
}
