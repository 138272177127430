export class CustomerWallet {
    Id: number;
    OrderId:number;
    CustomerName: string;
    CustomerId: number;
    Amount: number;
    PaymentMethodName: string;
    PaymentMethodId: number; 
    PaymentAccountId: number;
    PaymentType:string;
    PaymentFile: string;
    PaymentDate: string;
    sPaymentDate: Date | string | null;
    PaymentDetails: string;
    PaymentSenderDetails: string;
    PaymentReceiverDetails: string;
    UpdatedBy: number | null;
    CreatedDate: Date | string | null;
}
export class FilterCustomerWallet {
    Id: number | null;
    Mobile: string | null;
    PaymentMethodId: number | null;
    PaymentDate: Date | string | null;
    PaymentDetails: string;
    PaymentSenderDetails: string;
    PaymentReceiverDetails: string;
}