import { importType } from '@angular/compiler/src/output/output_ast';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddUserComponent } from 'src/app/modules/user/add-user/add-user.component';
import { UserListComponent } from 'src/app/modules/user/user-list/user-list.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AdminGuardGuard } from 'src/app/guard/admin-guard.guard';
import{ProfileComponent} from "src/app/modules/user/profile/profile.component";
const routes: Routes = [
  { path: 'user/add-user', component: AddUserComponent, canActivate: [AdminGuardGuard] },
  { path: 'user/user-list', component: UserListComponent, canActivate: [AdminGuardGuard] },
  { path: 'user/change-password', component: ChangePasswordComponent },
  { path: 'user/profile', component: ProfileComponent},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
