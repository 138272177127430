export class MenuPermission
{
    Id: number;
    MenuName: string;
    ParentMenuId: number | null;
    IsAlreadyAccess: boolean;
    ChildMenu: MenuPermission[];
}
export class MenuPermissionSave
{
    UserRoleId: number;
    MenuPermissionList: MenuPermission[];
}
