export class ListUser {
    Id: number;
    FirstName: string;
    LastName: string;
    Name: string;
    Mobile: string;
    Email: string;
    Address: string;
    City: string;
    Remark: string;
    CreatedBy: number | null;
    UpdatedBy: number | null;
    CurrentStageId: Number;
    PageNumber: Number;
    PageSize: Number
}
export class filterListUSer {
    Id: number;
    Name: string;
    Mobile: string;
    Email: string;
}