<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Test contact form</h1>
                <h5 class="modal-title">
                </h5>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">

                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active" *ngIf="actionMode == ActionModeEnum.Add">Add Lead</li>
                    <li class="breadcrumb-item active" *ngIf="actionMode == ActionModeEnum.Edit">Edit Lead</li>
                    <li class="breadcrumb-item active" *ngIf="actionMode == ActionModeEnum.Preview">Preview Lead</li>
                    <div class="box-tools pull-right  ml-lg-2">
                        <a [routerLink]="'/lead/list-lead'" class="btn bg-navy btn-sm" title="Show List">
                            <i class="fa fa-reply"></i> Show List
                        </a>
                    </div>
                </ol>
            </div>
        </div>
    </div><!-- /.container-fluid -->
</section>
<!-- Main content -->

<section class="content add-detail">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div [formGroup]="inquiryForm1" class="card collapsed-card">
                    <div class="card-body" style="display: block;"
                        [ngClass]="actionMode == ActionModeEnum.Preview ? 'previewModeColor':'defaultModeColor'">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label>First Name</label><span class="redStar">*</span>
                                    <input type="text" class="form-control" placeholder="First Name"
                                        formControlName="FirstName" maxlength="50"
                                        [ngClass]="{ 'is-invalid': submitted && f.FirstName.errors }">
                                    <div *ngIf="submitted && f.FirstName.errors" class="invalid-feedback">
                                        <div *ngIf="f.FirstName.errors.required">
                                            First Name is required
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label>Last Name</label><span class="redStar">*</span>
                                    <input type="text" class="form-control" placeholder="Last Name"
                                        formControlName="LastName" maxlength="50"
                                        [ngClass]="{ 'is-invalid': submitted && f.LastName.errors }">
                                    <div *ngIf="submitted && f.LastName.errors" class="invalid-feedback">
                                        <div *ngIf="f.LastName.errors.required">
                                            Last Name is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Email</label><span class="redStar">*</span>
                                    <input type="email" class="form-control" placeholder="Email" formControlName="Email"
                                         [ngClass]="{ 'is-invalid': submitted && f.Email.errors }">
                                    <div *ngIf="submitted && f.Email.errors" class="invalid-feedback">
                                        <div *ngIf="f.Email.errors.required">
                                            Email is required
                                        </div>
                                        <div *ngIf="f.Email.errors.Email">Email must be a valid email address</div>
                                    </div>
                                </div>
                            </div>
                           
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Mobile</label><span class="redStar">*</span>
                                    <input type="text" id="Mobile" class="form-control" placeholder="Mobile"
                                        formControlName="Mobile" maxlength="10"
                                        [ngClass]="{ 'is-invalid': submitted && f.Mobile.errors }">
                                    <div *ngIf="submitted && f.Mobile.errors" class="invalid-feedback">
                                        <div *ngIf="f.Mobile.errors.required">
                                            Mobile is required
                                        </div>
                                        <div *ngIf="f.Mobile.errors.pattern">Please, Enter 10 digit Mobile Number.</div> 
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Message</label>
                                        <textarea type="text" class="form-control" formControlName="Message"
                                            placeholder="Message" maxlength="1000"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="card-footer" style="display: block;"
                            *ngIf="actionMode == ActionModeEnum.Add || actionMode == ActionModeEnum.Edit">
                            <div class="row">
                                <div class="col-10">
                                    <button type="button" (click)="Reset()" class="btn btn-secondary float-left">
                                        <i class="fas fa-times"></i>
                                        Reset
                                    </button>
                                </div>
                                <div class="col-2">
                                    <button type="button" (click)="onSubmit()" class="btn btn-primary float-right">
                                        <i class="fas fa-save"></i>
                                        Save
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>