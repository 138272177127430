export class CompanyExpenses {

    Id: number;
    ExpenseTypeId: number;
    Amount: number;
    PaymentMethod: number;
    ExpensesDetails: string;
    ExpensesFile: string;
    ExpensesBy: number | null;
    ExpensesDate: Date | string | null;
}
