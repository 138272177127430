import { Injectable } from '@angular/core';
import { ResponseModel } from 'src/app/models/ResponseModel';
import { Observable } from 'rxjs';
import { URLList } from 'src/app/common/Utility/URL';
import { HttpDataService } from 'src/app/services/http-data.service';
import { CourierCompany } from 'src/app/models/commonDTO/CourierCompany.model';
import { KeyValuePair } from 'src/app/models/KeyValuePair.model';
import { OrderStatus } from 'src/app/models/commonDTO/OrderStatus.model';
import { PaymentMethod } from 'src/app/models/commonDTO/PaymentMethod.model';
import { PaymentStatus } from 'src/app/models/commonDTO/PaymentStatus.model';
import { PaymentType } from 'src/app/models/commonDTO/PaymentType.model';
import { ProductCategory } from 'src/app/models/commonDTO/ProductCategory.model';
import { Products, ProductSave } from 'src/app/models/commonDTO/Products.model';
import { StockAction } from 'src/app/models/commonDTO/StockAction.model';
import { WareHouse } from 'src/app/models/commonDTO/WareHouse.model';
import { OtpMode } from 'src/app/models/commonDTO/otpMode.model';
import { LeadSource } from 'src/app/models/commonDTO/LeadSource.model';
import { LeadStages } from 'src/app/models/commonDTO/LeadStages.model';
import { AttributeMaster } from 'src/app/models/attributesmaster.model';
import { Attribute } from 'src/app/models/Attribute.model';
import { warrantie } from 'src/app/models/Warranties.model';
import { Brand } from 'src/app/models/Brand.model';
import { Variation } from 'src/app/models/Variation.model';
import { Unitmaster } from 'src/app/models/Unitmaster.model';
import { ExpenseType } from 'src/app/models/ExpenseType.model';
import { Tax } from 'src/app/models/Tax.model';
import { DataTransferDTO } from 'src/app/models/DataTransferDTO.model';

@Injectable({
  providedIn: 'root'
})
export class MastersService {
  dataTransferDTO: DataTransferDTO;

  constructor(private _httpDataService: HttpDataService) { }

  setDataToService(details: DataTransferDTO) {
    this.dataTransferDTO = details;
  }
  getDataFromService(): DataTransferDTO {
    return this.dataTransferDTO;
  }
  resetDataToService(): void {
    this.dataTransferDTO = null;
  }
  //#region [Courier partner]
  AttributeMasterById(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.AttributeMasterById, requestData);
  }

  deleteAttributeMaster(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.deleteAttributeMaster,requestData);     
  }

  deleteUserById(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.deleteUserById,requestData);
     
  }
  getCourierPartners(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Masters.GetCourierPartners);
  }
  getCourierPartnerById(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.GetCourierPartners, requestData);
  }
  saveCourierPartners(requestData: CourierCompany): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Masters.SaveCourierPartners, requestData);
  }
  deleteCourierPartner(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.deleteCourierPartner, requestData);
  }
  
  saveWarrantie(requestData: warrantie): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Masters.saveWarrantie, requestData);
  }
  //#endregion

  //#region [Order Status]

  getOrderStatus(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Masters.GetOrderStatus);
  }
  getOrderStatusById(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.GetOrderStatus, requestData);
  }
  saveOrderStatus(requestData: OrderStatus): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Masters.SaveOrderStatus, requestData);
  }
  deleteOrderStatus(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.deleteOrderStatus, requestData);
  }

  //#endregion
  //#region [Payment Methods]
  getPaymentMethods(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Masters.GetPaymentMethods);
  }
  getPaymentMethodsById(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.GetPaymentMethods, requestData);
  }
  savePaymentMethods(requestData: PaymentMethod): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Masters.SavePaymentMethods, requestData);
  }
  deletePaymentMethods(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.DeletePaymentMethods,requestData);     
  }
  getPaymentMethodsForddl(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Masters.GetPaymentMethodForddl, false);
  }

  //#endregion


  //#region [Payment Status]
  getPaymentStatus(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Masters.GetPaymentStatus);
  }
  getPaymentStatusById(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.GetPaymentStatus, requestData);
  }
  savePaymentStatus(requestData: PaymentStatus): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Masters.SavePaymentStatus, requestData);
  }
  //#endregion


  //#region [Payment Type]
  getPaymentType(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Masters.GetPaymentType);
  }
  getPaymentTypeById(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.GetPaymentType, requestData);
  }
  savePaymentType(requestData: PaymentType): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Masters.SavePaymentType, requestData);
  }
  //#endregion

  //#region [ProductCategory]
  getProductCategory(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Masters.GetProductCategory);
  }
  getProductCategoryById(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.GetProductCategory, requestData);
  }
  saveProductCategory(requestData: ProductCategory): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Masters.SaveProductCategory, requestData);
  }
  deleteProductCategory(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.DeleteProductCategory, requestData);
  }
  //#endregion

  //#region [Products]
  getProducts(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Masters.GetProducts);
  }
  getProductsById(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.GetProducts, requestData);
  }
  getProductsBySKU(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.GetProductsBySKU, requestData);
  }
  saveProducts(requestData: ProductSave): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Masters.SaveProducts, requestData);
  }
  deleteProducts(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.DeleteProducts, requestData);
  }
  getProductImages(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.GetProductImages, requestData);
  }
  getProductVariations(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.GetProductVariations, requestData);
  }
  //#endregion

  //#region [StockHistory]
  getStockHistory(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Masters.GetStockHistory);
  }
  //#endregion

  //#region [StockActions]
  getStockActions(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Masters.GetStockActions);
  }
  getStockActionsById(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.GetStockActions, requestData);
  }
  saveStockActions(requestData: StockAction): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Masters.SaveStockActions, requestData);
  }
  //#endregion

  //#region [WareHouse]
  getWareHouse(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Masters.GetWareHouse);
  }
  getWareHouseById(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.GetWareHouse, requestData);
  }
  saveWareHouse(requestData: WareHouse): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Masters.SaveWareHouse, requestData);
  }
  //#endregion

   //#region [OTP Methods]
   getOTPMethods(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Masters.GetOtpModes);
  }
  getOTPMethodsById(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.GetOtpModes, requestData);
  }
  saveOTPMethods(requestData:OtpMode): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Masters.SaveOtpMode, requestData);
  }
  deleteOtpMode(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.deleteOtpMode, requestData);
  }
  //#endregion


    //#region [LeadSource]

    getLeadSource(): Observable<ResponseModel> {
      return this._httpDataService.Get<ResponseModel>(URLList.Masters.GetLeadSource);
    }
    getLeadSourceById(requestData: KeyValuePair): Observable<ResponseModel> {
      return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.GetLeadSource, requestData);
    }
    saveLeadSource(requestData: LeadSource): Observable<ResponseModel> {
      return this._httpDataService.postMethod<ResponseModel>(URLList.Masters.SaveLeadSource, requestData);
    }
    getLeadSourace(): Observable<ResponseModel> {
      return this._httpDataService.Get<ResponseModel>(URLList.Inquiry.getLeadSourace);
  
    }
    //#endregion

    //#region [LeadStages]

    getLeadStages(): Observable<ResponseModel> {
      return this._httpDataService.Get<ResponseModel>(URLList.Masters.GetLeadStages);
    }
    getLeadStagesById(requestData: KeyValuePair): Observable<ResponseModel> {
      return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.GetLeadStages, requestData);
    }
    saveLeadStages(requestData: LeadStages): Observable<ResponseModel> {
      return this._httpDataService.postMethod<ResponseModel>(URLList.Masters.SaveLeadStages, requestData);
    }
    
    AttributeList(): Observable<ResponseModel> {
      return this._httpDataService.Get<ResponseModel>(URLList.Masters.AttributeList);
    }
    saveAttributeMasterName(requestData: AttributeMaster): Observable<ResponseModel> {
      return this._httpDataService.postMethod<ResponseModel>(URLList.Masters.saveAttributeMasterName, requestData);
    }
    getAttributeMasterForddl(): Observable<ResponseModel> {
      return this._httpDataService.Get<ResponseModel>(URLList.Masters.getAttributeMasterForddl, false);
    }


    BindAttributeList(): Observable<ResponseModel> {
      return this._httpDataService.Get<ResponseModel>(URLList.Masters.bindAttributeList);
    }
    saveAttribute(requestData: Attribute): Observable<ResponseModel> {
      return this._httpDataService.postMethod<ResponseModel>(URLList.Masters.saveAttribute, requestData);
    }
    getattributeById(requestData: KeyValuePair): Observable<ResponseModel> {
      return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.getattributeById, requestData);
    }
    deleteAttributeById(requestData: KeyValuePair): Observable<ResponseModel> {
      return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.deleteAttribute, requestData);
    }


    getWarrantieList(): Observable<ResponseModel> {
      return this._httpDataService.Get<ResponseModel>(URLList.Masters.getWarrantieLists);
    }
    getWarrantieById(requestData: KeyValuePair): Observable<ResponseModel> {
      return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.getWarrantieLists, requestData);
    }
    deleteWarrantie(requestData: KeyValuePair): Observable<ResponseModel> {
      return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.deleteWarrantie, requestData);
    }



    getBrandsLists(): Observable<ResponseModel> {
      return this._httpDataService.Get<ResponseModel>(URLList.Masters.getBrandsLists);
    }
    getBrandsById(requestData: KeyValuePair): Observable<ResponseModel> {
      return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.getBrandsLists, requestData);
    }
    deleteBrands(requestData: KeyValuePair): Observable<ResponseModel> {
      return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.deleteBrands, requestData);
    }
    saveBrand(requestData: Brand): Observable<ResponseModel> {
      return this._httpDataService.postMethod<ResponseModel>(URLList.Masters.saveBrands, requestData);
    }


    getTaxLists(): Observable<ResponseModel> {
      return this._httpDataService.Get<ResponseModel>(URLList.Masters.getTaxLists);
    }
    getTaxById(requestData: KeyValuePair): Observable<ResponseModel> {
      return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.getTaxLists, requestData);
    }
    deleteTax(requestData: KeyValuePair): Observable<ResponseModel> {
      return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.deleteTax, requestData);
    }
    saveTax(requestData: Tax): Observable<ResponseModel> {
      return this._httpDataService.postMethod<ResponseModel>(URLList.Masters.saveTax, requestData);
    }

    getVariationsLists(): Observable<ResponseModel> {
      return this._httpDataService.Get<ResponseModel>(URLList.Masters.getVariationsLists);
    }
    getVariationsById(requestData: KeyValuePair): Observable<ResponseModel> {
      return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.getVariationsLists, requestData);
    }
    deleteVariations(requestData: KeyValuePair): Observable<ResponseModel> {
      return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.deleteVariations, requestData);
    }
    saveVariations(requestData: Variation): Observable<ResponseModel> {
      return this._httpDataService.postMethod<ResponseModel>(URLList.Masters.saveVariations, requestData);
    }

    getUnitMasterLists(): Observable<ResponseModel> {
      return this._httpDataService.Get<ResponseModel>(URLList.Masters.getUnitMasterLists);
    }
    getUnitMasterById(requestData: KeyValuePair): Observable<ResponseModel> {
      return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.getUnitMasterLists, requestData);
    }
    deleteUnitMaster(requestData: KeyValuePair): Observable<ResponseModel> {
      return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.deleteUnitMaster, requestData);
    }
    saveUnitMaster(requestData: Unitmaster): Observable<ResponseModel> {
      return this._httpDataService.postMethod<ResponseModel>(URLList.Masters.saveUnitMaster, requestData);
    }

    getUnitIdLists(): Observable<ResponseModel> {
      return this._httpDataService.Get<ResponseModel>(URLList.Masters.getUnitIdLists);
    }


    getExpenseTypeLists(): Observable<ResponseModel> {
      return this._httpDataService.Get<ResponseModel>(URLList.Company.getExpenseTypeLists);
    }
    getExpenseTypeById(requestData: KeyValuePair): Observable<ResponseModel> {
      return this._httpDataService.GetByURL<ResponseModel>(URLList.Company.getExpenseTypeLists, requestData);
    }
    deleteExpenseType(requestData: KeyValuePair): Observable<ResponseModel> {
      return this._httpDataService.GetByURL<ResponseModel>(URLList.Company.deleteExpenseType, requestData);
    }
    saveExpenseType(requestData: ExpenseType): Observable<ResponseModel> {
      return this._httpDataService.postMethod<ResponseModel>(URLList.Company.saveExpenseType, requestData);
    }

    getAttributeMasterLists(): Observable<ResponseModel> {
      return this._httpDataService.Get<ResponseModel>(URLList.Masters.getAttributeMasterLists);
    }
    deletePaymenttypeById(requestData: KeyValuePair): Observable<ResponseModel> {
      return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.deletePaymenttype, requestData);
    }
    deleteStockactionById(requestData: KeyValuePair): Observable<ResponseModel> {
      return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.deleteStockaction, requestData);
    }
    deleteWarehouseById(requestData: KeyValuePair): Observable<ResponseModel> {
      return this._httpDataService.GetByURL<ResponseModel>(URLList.Masters.deleteWarehouse, requestData);
    }
}
