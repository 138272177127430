export class Menu
{
    Id: number;
    MenuName: string;
    MenuDescription: string;
    ParentMenuId: number | null;
    MenuLevel: number;
    Url: string;
    IsActive: boolean;
    IsAlreadyAccess: boolean;
    ChildMenu: Menu[];
}