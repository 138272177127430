export class RequestErrors {
    readonly UserRequired = 'Username is required.';
    readonly PasswordRequired = 'Password is required.';

    public static SessionExpired = 'Your session has expired. Please login again.';
    public static NotAuthorized = 'You are not authorized to access this page.';
    public static Generic = 'There is some error in processing. Please try again later.';
    public static SuccessMessageSave = 'Information has been saved against the invoice(s)';
    public static SuccessMessageDeleted = 'Information has been deleted against the invoice(s)';
    public static DeleteReminder = 'Information has been deleted against this ticket(s)';

    public static DateExists = 'Date already  selected';
    public static OldDate = "Date couldn't be less than the current date.";

}