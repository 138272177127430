import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Action } from 'rxjs/internal/scheduler/Action';
import { UserModel } from 'src/app/models/UserModel';
import { LoginService } from 'src/app/modules/authentication/service/login.service';
import * as $ from "jquery";//add
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router,
    private service: LoginService) { }

  ngOnInit() {
  }
  get user(): any {
    return localStorage.getItem("FullName");
  }
  async logout() {
    let userModelSelected = new UserModel();
    await $.getJSON('https://api.ipify.org?format=json', function (data) {
      userModelSelected.IpAddress = data.ip;
    });
    userModelSelected.UserAgent = window.navigator.userAgent;

    this.service.Logout(userModelSelected).subscribe(res => {

      if (res.IsSuccess) {
        //localStorage.clear();
        localStorage.removeItem('Token');
        localStorage.removeItem('Authorization');
        this.router.navigateByUrl('/Login');
      }
    });
  }
  menuToggle() {
    const toggleMenu = document.querySelector('.menu');
    toggleMenu.classList.toggle('active')
  }
}
