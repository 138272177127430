<div class="login-box">

  <!-- <div class="card"> -->
  <div class="card-body login-card-body">
    <div class="login-box-msg"> <i class="fas fa-lock"></i>
    </div>

    <h5 class="login-box-msg">Device verification </h5>

    <div class="row align-items-center login-row">
      <div class="col-sm-12 form-horizontal">
        <div class="card">
          <form class="form" #otpForm="ngForm" (ngSubmit)="otpForm.form.valid && onSubmit()">

            <div class=" form-group">
              <label>Device verification code </label>

              <input type="text" class="form-control" placeholder="5-digit code" name="otp" [(ngModel)]="otpField"
                #otp="ngModel" required><br>
              <span class="text-danger" *ngIf="(otp.touched || otpForm.submitted) && otp.errors?.required">
                otp required
              </span>

            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-success btn-block">Verify </button>
            </div>
          </form>
        </div>
        <div class="para">
          <p ><i class="fa fa-envelope  para3" aria-hidden="true"></i> We just sent your authentication code via email to
            {{this.email}}. Please check your Email Id. </p>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</div>