import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/modules/user/user.service';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { CommonService } from 'src/app/services/common.service';
import { ActionMode } from 'src/app/models/enum';
import { from } from 'rxjs';
import { FilterUsers, Users } from 'src/app/models/commonDTO/users.model';
import { ChangePassword } from 'src/app/models/commonDTO/ChangePassword.model';
import { DataTransferDTO } from 'src/app/models/DataTransferDTO.model';
import { CommonDropdownModel } from 'src/app/models/CommonDropdownModel.model';
import { Router } from '@angular/router';
@Component({
    selector: 'app-add-user',
    templateUrl: './add-user.component.html',
    styleUrls: ['./add-user.component.css']
})

export class AddUserComponent implements OnInit {
    public users: Users = new Users();
    public changePassword: ChangePassword = new ChangePassword();
    public actionMode: ActionMode = ActionMode.Add;
    public dataTransferDTO: DataTransferDTO;
    public userRoleTypeList: Array<CommonDropdownModel>;
    public userForm: FormGroup;
    public passwordForm: FormGroup;
    public submitted = false;
    public showList: boolean = false;

    constructor(
        private _usersService: UserService,
        private formBuilder: FormBuilder,
        private _commonService: CommonService,
        private _toasterService: ToasterService,
        private router: Router) {

    }

    ngOnInit(): void {
        this.InitializeForm();
        this.BindUserRoleTypeDDL();
        this.ReceiveTransmittedData();
    }
    BindUserRoleTypeDDL() {
        var self = this;
        self._commonService.getUserRolesForddl().subscribe((response) => {
            self.userRoleTypeList = (response.IsSuccess && response.Data != null && response.Data.length > 0) ? response.Data : [];
        });
    }

    InitializeForm() {
        this.userForm = this.formBuilder.group({
            // UserName: ['', Validators.required],
            UserCode: ['', Validators.required],
            Password: ['', Validators.required],
            ConfirmPassword: ['', Validators.required],
            UserRoleId: ['', Validators.required],
            FirstName: ['', Validators.required],
            LastName: ['', Validators.required],
            Mobile: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
            Email: ['', Validators.nullValidator],
            Address: ['', Validators.required],
            IsActive: [true, Validators.nullValidator]
        }, {
            validator: this.MustMatch('Password', 'ConfirmPassword')
        });
        this.actionMode = ActionMode.Add;
    }
    MustMatch(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                // return if another validator has already found an error on the matchingControl
                return;
            }

            // set error on matchingControl if validation fails
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true });
            } else {
                matchingControl.setErrors(null);
            }
        }
    }
    get f() {
        return this.userForm.controls;
    }
    get pf() {
        return this.passwordForm.controls;
    }
    public get ActionModeEnum(): typeof ActionMode {
        return ActionMode;
    }
    Save() {
        debugger;
        this.submitted = true;
        if (this.userForm.invalid) {
            this.userForm.markAsTouched();
            return;
        }
        else {
            var self = this;
            self.users = self.setFormDataToModel(self.users);
            self._usersService.saveUsers(self.users).subscribe((response) => {
                if (response.IsSuccess) {
                    this._toasterService.pop('success', "Success", response.Message);
                    this.Reset();
                    this.router.navigateByUrl('user/user-list');
                }
            });
        }
    }
    OpenPopup() {
        this.passwordForm = this.formBuilder.group({
            NewPassword: ['', Validators.required],
            NewConfirmPassword: ['', Validators.required],
        }, {
            validator: this.MustMatch('NewPassword', 'NewConfirmPassword')
        });
        this.showList = true;
    }
    ClosePopup() {
        this.showList = false;
    }
    SavePassword() {
        this.submitted = true;
        if (this.passwordForm.invalid) {
            this.passwordForm.markAsTouched();
            return;
        }
        else {
            var self = this;
            self.changePassword = self.setFormDataToModelPassword(self.changePassword);
            self._usersService.changePassword(self.changePassword).subscribe((response) => {
                if (response.IsSuccess) {
                    this._toasterService.pop('success', "Success", response.Message);
                    this.ClosePopup();

                }
            });
        }
    }
    setFormDataToModel(user: Users) {
        user.UserCode = this.userForm.get('UserCode').value;
        user.UserName = this.userForm.get('Mobile').value;
        user.Password = this.userForm.get('Password').value;
        user.UserRoleId = this.userForm.get('UserRoleId').value;
        user.FirstName = this.userForm.get('FirstName').value;
        user.LastName = this.userForm.get('LastName').value;
        user.Mobile = this.userForm.get('Mobile').value;
        user.Email = this.userForm.get('Email').value;
        user.Address = this.userForm.get('Address').value;
        user.IsActive = this.userForm.get('IsActive').value;
        return user;
    }

    setFormDataToModelPassword(changePassword: ChangePassword) {
        changePassword.UserId = this.dataTransferDTO.Id;
        changePassword.CurrentPassword = "";
        changePassword.NewPassword = this.passwordForm.get('NewPassword').value;
        return changePassword;
    }

    Reset() {
        this.submitted = false;
        this.userForm.reset();
        this.InitializeForm();
    }
    EditRecord(id: number) {
        var self = this;
        let filterData: FilterUsers = new FilterUsers();
        filterData.Id = id;
        self._usersService.getUsers(filterData).subscribe((response) => {
            if (response.IsSuccess) {
                self.users = response.Data[0];

                self.userForm.patchValue({
                    // UserName: self.users.UserName,
                    UserCode: self.users.UserCode,
                    Password: self.users.Password,ConfirmPassword: self.users.Password,
                    UserRoleId: self.users.UserRoleId,
                    FirstName: self.users.FirstName,
                    LastName: self.users.LastName,
                    Mobile: self.users.Mobile,
                    Email: self.users.Email,
                    Address: self.users.Address,
                    IsActive: self.users.IsActive
                });
                self.actionMode = ActionMode.Edit;
            }
        });
    }
    SetValueAndUpdateValidation() {
        this.userForm.setControl("FirstName", new FormControl({ value: this.users.FirstName, disabled: true }, { validators: [Validators.nullValidator] }));
        this.userForm.setControl("LastName", new FormControl({ value: this.users.LastName, disabled: true }, { validators: [Validators.required] }));
    }
    PreviewRecord(id: number) {
        var self = this;
        let filterData: FilterUsers = new FilterUsers();
        filterData.Id = id;
        self._usersService.getUsers(filterData).subscribe((response) => {
            if (response.IsSuccess) {
                self.users = response.Data[0];

                self.userForm.patchValue({
                    // UserName: self.users.UserName,
                    UserCode: self.users.UserCode,
                    Password: self.users.Password,
                    UserRoleId: self.users.UserRoleId,
                    FirstName: self.users.FirstName,
                    LastName: self.users.LastName,
                    Mobile: self.users.Mobile,
                    Email: self.users.Email,
                    Address: self.users.Address,
                    IsActive: self.users.IsActive
                });
                //this.SetValueAndUpdateValidation();
                this.userForm.disable();
                self.actionMode = ActionMode.Preview;
            }
        });
    }
    ReceiveTransmittedData() {
        this.dataTransferDTO = this._usersService.getUsersFromService();
        if (this.dataTransferDTO) {
            this._usersService.resetUsersToService();
            switch (this.dataTransferDTO.ActionMode) {
                case ActionMode.Edit:
                    this.EditRecord(this.dataTransferDTO.Id);
                    break;
                case ActionMode.Preview:
                    this.PreviewRecord(this.dataTransferDTO.Id);
                    break;

                default:

                    break;
            }
        }
    }
}

