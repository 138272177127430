import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { CompanyAccountType } from 'src/app/models/companyDTO/CompanyAccountType.model';
import { ActionMode } from 'src/app/models/enum';
import { KeyValuePair } from 'src/app/models/KeyValuePair.model';
import swal from 'sweetalert';
import { CompanyService } from '../company.service';

@Component({
  selector: 'app-account-type',
  templateUrl: './account-type.component.html',
  styleUrls: ['./account-type.component.css']
})
export class AccountTypeComponent implements OnInit {
  public AccountTypeList: Array<CompanyAccountType>;
  public AccountType: CompanyAccountType = new CompanyAccountType();
  public registerForm: FormGroup;
  public showList: boolean = false;
  public actionMode: ActionMode = ActionMode.Add;
  public submitted = false;
  p: number = 1;
  PageSize: number = 20;
  AccountTypeName: string;

  constructor(private _companyService: CompanyService,
    private formBuilder: FormBuilder,
    private _toasterService: ToasterService) {

  }

  ngOnInit(): void {
    this.InitializeForm();
    this.BindAccountTypeList();
  }
  InitializeForm() {
    this.registerForm = this.formBuilder.group({
      Name: ['', Validators.required],
      Description: ['', Validators.required],
      IsActive: [true, Validators.nullValidator],
    });
    this.actionMode = ActionMode.Add;
  }
  get f() {
    return this.registerForm.controls;
  }
  public get ActionModeEnum(): typeof ActionMode {
    return ActionMode;
  }
  Save() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      this.registerForm.markAsTouched();
      return;
    }
    else {
      var self = this;
      self.AccountType = this.setFormDataToModel(this.AccountType);
      self._companyService.SaveAccountType(self.AccountType).subscribe((response) => {
        if (response.IsSuccess) {
          this.BindAccountTypeList();
          this._toasterService.pop('success', "Success", response.Message);
          this.Reset();
          this.ClosePopup();
        }
        else {
          this._toasterService.pop('error', 'Error', response.Message);
        }
      });
    }

  }
  setFormDataToModel(accountType: CompanyAccountType) {
    accountType.Name = this.registerForm.get('Name').value;
    accountType.Description = this.registerForm.get('Description').value;
    accountType.IsActive = this.registerForm.get('IsActive').value;
    return accountType;
  }

  Reset() {
    this.submitted = false;
    this.registerForm.reset();
    this.InitializeForm();
    this.BindAccountTypeList();
    this.AccountType = new CompanyAccountType();
  }

  AddNew() {
    this.showList = true;
    this.InitializeForm();
  }

  ClosePopup() {
    this.showList = false;
  }

  ConvertJsonToModel(jsonStr) {
    return new Map(JSON.parse(jsonStr));
  }

  BindAccountTypeList() {
    var self = this;
    self._companyService.GetAccountType().subscribe((response) => {
      if (response.IsSuccess) {
        self.AccountTypeList = response.Data;
      }
      else {
        self.AccountTypeList = [];
      }
    });
  }
  Search() {
    if (this.AccountTypeName == "") {
      this.ngOnInit();
    }
    else {
      this.AccountTypeList = this.AccountTypeList.filter(res => {
        return res.Name.toLocaleLowerCase().match(this.AccountTypeName.toLocaleLowerCase());
      })

    }
  }

  EditRecord(currentRow: CompanyAccountType) {
    var self = this;
    let dataToPass: KeyValuePair = { key: "id", value: String(currentRow.Id) };
    self._companyService.GetAccountTypeById(dataToPass).subscribe((response) => {
      if (response.IsSuccess) {
        self.AccountType = response.Data[0];
        self.showList = true;
        self.registerForm.patchValue({
          Name: self.AccountType.Name,
          Description: self.AccountType.Description,
          IsActive: self.AccountType.IsActive,
        });
        self.actionMode = ActionMode.Edit;
      }
    });
  }
  DeleteRecord(id: number) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      dangerMode: true
    })
      .then((willDelete) => {
        if (willDelete) {
          let dataToPass: KeyValuePair = { key: "id", value: String(id) };
          this._companyService.DeleteAccountType(dataToPass).subscribe(response => {
            if (response.IsSuccess) {
              this._toasterService.pop('success', "Success", response.Message);
              this.BindAccountTypeList();
            }

            else {
              this._toasterService.pop('error', 'Error', response.Message);
            }
          });

        } else {
          swal("Your file is safe!");
        }
      });
  }
}
