import { Component, OnInit } from '@angular/core';
import { ActionMode } from 'src/app/models/enum';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MastersService } from '../../masters/services/masters.service';
import { ToasterService } from 'angular2-toaster';
import { KeyValuePair } from 'src/app/models/KeyValuePair.model';
import swal from 'sweetalert';
import { CompanyPaymentAccount } from 'src/app/models/companyDTO/CompanyPaymentAccount.model';
import { CommonDropdownModel } from 'src/app/models/CommonDropdownModel.model';

import { CompanyService } from 'src/app/modules/company/company.service';
import { CompanyPaymentTransactions } from 'src/app/models/companyDTO/CompanyPaymentTransactions.model';

@Component({
  selector: 'app-company-payment-account',
  templateUrl: './company-payment-account.component.html',
  styleUrls: ['./company-payment-account.component.css']
})
export class CompanyPaymentAccountComponent implements OnInit {
  public paymentAccountList: Array<CompanyPaymentAccount>;
  public paymentAccount: CompanyPaymentAccount = new CompanyPaymentAccount();
  public showList: boolean = false;
  public actionMode: ActionMode = ActionMode.Add;
  p: number = 1;
  PageSize: number = 20;
  public registerForm: FormGroup;
  public paymentForm: FormGroup;
  public submitted = false;
  AccountName: string;
  public AccountTypeDDL: Array<CommonDropdownModel>;
  public PaymentAccountDDL: Array<CommonDropdownModel>;
  public actionPayment: string = "Fund Transfer";
  showFundTransfer: boolean = false;
  constructor(private _masterService: MastersService,
    private _companyService: CompanyService,
    private formBuilder: FormBuilder,
    private _toasterService: ToasterService) { }

  ngOnInit(): void {

    this.InitializeForm();
    this.BindPaymentAccountList();
    this.BindAccountTypeDDL();
  }
  BindAccountTypeDDL() {
    var self = this;
    self._companyService.getAccountTypeForddl().subscribe((response) => {
      self.AccountTypeDDL = (response.IsSuccess && response.Data != null && response.Data.length > 0) ? response.Data : [];
    });
  }
  BindPaymentAccountDDL(id: number) {
    var self = this;
    self._companyService.getCompanyPaymentAccountForddl().subscribe((response) => {
      if (response.IsSuccess && response.Data != null && response.Data.length > 0) {
        //self.PaymentAccountDDL = response.Data.splice(response.Data.findIndex(v => v.Id == id), 1);
        self.PaymentAccountDDL = $.grep(response.Data, function (e) {
          return e.Id != id;
        });
        // self.PaymentAccountDDL = response.Data;
        console.log(self.PaymentAccountDDL);
      }
      else
        self.PaymentAccountDDL = [];
    });
  }
  InitializeForm() {
    this.registerForm = this.formBuilder.group({
      PaymentAccountName: ['', Validators.required],
      AccountTypeId: ['', Validators.required],
      AccountNumber: ['', Validators.required],
      OpeningBalance: ['', Validators.required],
      Note: [''],
      Label1: [''],
      Value1: [''],
      Label2: [''],
      Value2: [''],
      Label3: [''],
      Value3: [''],
      Label4: [''],
      Value4: [''],
      Label5: [''],
      Value5: [''],
      IsActive: [true, Validators.nullValidator],
    });
    this.actionMode = ActionMode.Add;
  }
  get f() {
    return this.registerForm.controls;
  }
  get g() {
    return this.paymentForm.controls;
  }
  public get ActionModeEnum(): typeof ActionMode {
    return ActionMode;
  }


  BindPaymentAccountList() {
    var self = this;
    self._companyService.getCompanyPaymentAccount().subscribe((response) => {
      if (response.IsSuccess) {
        self.paymentAccountList = response.Data;
      }
      else {
        self.paymentAccountList = [];
      }
    });
  }
  Search() {
    if (this.AccountName == "") {
      this.ngOnInit();
    }
    else {
      this.paymentAccountList = this.paymentAccountList.filter(res => {
        return res.PaymentAccountName.toLocaleLowerCase().match(this.AccountName.toLocaleLowerCase());
      })

    }

  }

  Save() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      this.registerForm.markAsTouched();
      return;
    }
    else {
      var self = this;
      self.paymentAccount = this.setFormDataToModel(this.paymentAccount);
      self._companyService.saveCompanyPaymentAccount(self.paymentAccount).subscribe((response) => {
        if (response.IsSuccess) {
          this.BindPaymentAccountList();
          this._toasterService.pop('success', "Success", response.Message);
          this.Reset();
          this.ClosePopup();
        }
      });
    }
  }
  setFormDataToModel(paymentAccount: CompanyPaymentAccount) {
    paymentAccount.PaymentAccountName = this.registerForm.get('PaymentAccountName').value;
    paymentAccount.AccountTypeId = this.registerForm.get('AccountTypeId').value;
    paymentAccount.AccountNumber = this.registerForm.get('AccountNumber').value;
    paymentAccount.OpeningBalance = this.registerForm.get('OpeningBalance').value;
    paymentAccount.Note = this.registerForm.get('Note').value;
    paymentAccount.Label1 = this.registerForm.get('Label1').value;
    paymentAccount.Label2 = this.registerForm.get('Label2').value;
    paymentAccount.Label3 = this.registerForm.get('Label3').value;
    paymentAccount.Label4 = this.registerForm.get('Label4').value;
    paymentAccount.Label5 = this.registerForm.get('Label5').value;
    paymentAccount.Value1 = this.registerForm.get('Value1').value;
    paymentAccount.Value2 = this.registerForm.get('Value2').value;
    paymentAccount.Value3 = this.registerForm.get('Value3').value;
    paymentAccount.Value4 = this.registerForm.get('Value4').value;
    paymentAccount.Value5 = this.registerForm.get('Value5').value;
    paymentAccount.IsActive = this.registerForm.get('IsActive').value;
    return paymentAccount;
  }
  ConvertJsonToModel(jsonStr) {
    return new Map(JSON.parse(jsonStr));
  }
  Reset() {
    this.submitted = false;
    this.registerForm.reset();
    this.InitializeForm();
  }
  ClosePopup() {
    this.showList = false;
  }
  ClosePopup2() {
    this.showFundTransfer = false;
  }
  AddNew() {
    this.showList = true;
    this.InitializeForm();
  }

  EditRecord(currentRow: CompanyPaymentAccount) {
    var self = this;
    let dataToPass: KeyValuePair = { key: "id", value: String(currentRow.Id) };
    self._companyService.getCompanyPaymentAccountById(dataToPass).subscribe((response) => {
      if (response.IsSuccess) {
        self.paymentAccount = response.Data[0];
        self.showList = true;
        self.registerForm.patchValue({
          PaymentAccountName: self.paymentAccount.PaymentAccountName,
          AccountTypeId: self.paymentAccount.AccountTypeId,
          AccountNumber: self.paymentAccount.AccountNumber,
          Note: self.paymentAccount.Note,
          Label1: self.paymentAccount.Label1,
          Label2: self.paymentAccount.Label2,
          Label3: self.paymentAccount.Label3,
          Label4: self.paymentAccount.Label4,
          Label5: self.paymentAccount.Label5,
          Value1: self.paymentAccount.Value1,
          Value2: self.paymentAccount.Value2,
          Value3: self.paymentAccount.Value3,
          Value4: self.paymentAccount.Value4,
          Value5: self.paymentAccount.Value5,
          IsActive: self.paymentAccount.IsActive,
        });
        self.actionMode = ActionMode.Edit;
      }
    });
  }
  DeleteRecord(id: number) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      dangerMode: true
    })
      .then((willDelete) => {
        if (willDelete) {
          let dataToPass: KeyValuePair = { key: "id", value: String(id) };
          this._companyService.deleteCompanyPaymentAccount(dataToPass).subscribe(response => {
            if (response.IsSuccess) {
              this._toasterService.pop('success', "Success", response.Message);
              this.BindPaymentAccountList();
            }
            else {
              this._toasterService.pop('error', 'Error', response.Message);
            }
          });

        } else {
          swal("Your file is safe!");
        }
      });
  }
  FundTransfer(id: number) {
    this.actionPayment = "Fund Transfer";
    this.showFundTransfer = true;
    this.BindPaymentAccountDDL(id);
    this.paymentForm = this.formBuilder.group({
      PaymentAccountId: ['', Validators.required],
      Amount: ['', Validators.required],
      Date: ['', Validators.required],
      Note: [''],
      hdnId: [id],
    });
  }
  Deposit(id: number) {
    this.actionPayment = "Deposit";
    this.showFundTransfer = true;
    this.BindPaymentAccountDDL(id);
    this.paymentForm = this.formBuilder.group({
      PaymentAccountId: ['', Validators.required],
      Amount: ['', Validators.required],
      Date: ['', Validators.required],
      Note: [''],
      hdnId: [id],
    });
  }
  Submit() {
    this.submitted = true;
    if (this.paymentForm.invalid) {
      this.paymentForm.markAsTouched();
      return;
    }
    else {
      var self = this;
      let paymentTransactions: CompanyPaymentTransactions = new CompanyPaymentTransactions();
      if (this.actionPayment == "Fund Transfer") {
        paymentTransactions.CreditAccountId = this.paymentForm.get('PaymentAccountId').value;
        paymentTransactions.DebitAccountId = this.paymentForm.get('hdnId').value;
      }
      else {
        paymentTransactions.CreditAccountId = this.paymentForm.get('hdnId').value;
        paymentTransactions.DebitAccountId = this.paymentForm.get('PaymentAccountId').value;
      }
      paymentTransactions.PaymentDate = this.paymentForm.get('Date').value;
      paymentTransactions.Note = this.paymentForm.get('Note').value;
      paymentTransactions.Amount = this.paymentForm.get('Amount').value;
      self._companyService.savePaymentTransactions(paymentTransactions).subscribe((response) => {
        if (response.IsSuccess) {
          this.BindPaymentAccountList();
          this._toasterService.pop('success', "Success", response.Message);
          this.Reset();
          this.ClosePopup2();
        }
      });
    }
  }
}
