<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>FollowUp Lead</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">FollowUp-Lead</li>
                </ol>
            </div>
        </div>
    </div><!-- /.container-fluid -->
</section>
<section class="content add-detail">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div [formGroup]="leadForm" class="card collapsed-card">
                    <div class="card-header b-t-info">
                        <h3 class="card-title" data-card-widget="collapse" data-toggle="tooltip">Search</h3>

                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                                title="Collapse">
                                <i class="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <div class="card-body" style="display: block;">
                        <div class="row">

                            <div class="col-4">
                                <div class="form-group">
                                    <label>Name</label>
                                    <input type="text" class="form-control" placeholder="Name" formControlName="Name">
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="text" class="form-control" placeholder="Email" formControlName="Email">
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Mobile</label>
                                    <input type="text" class="form-control" placeholder="Mobile"
                                        formControlName="Mobile" maxlength="10">
                                </div>
                            </div>
                        </div>

                        <div class="row">

                            <div class="col-4">
                                <div class="form-group">
                                    <label>City</label>
                                    <input type="text" class="form-control" placeholder="City" formControlName="City">
                                </div>
                            </div>

                            <div class="col-4">
                                <div class="form-group">
                                    <label>Source</label>
                                    <select class="form-control" id="Source" formControlName="Source">
                                        <option value="">--Select--</option>
                                        <option *ngFor="let x of userLeadSourace;" [ngValue]="x.Id">
                                            {{x.Name}}</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                        <div class="row float-right">
                            <div class="col-12">
                                <div class="form-group">
                                    <button type="button" (click)="Reset()"
                                        class="btn btn-secondary float-left mr-lg-2">
                                        <i class="fas fa-times"></i>
                                        Reset
                                    </button>
                                    <button type="button" (click)="SearchFilter()" class="btn btn-info">
                                        <i class="fas fa-search"></i>
                                        Search
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header b-t-olive">
                        <h2 class="card-title">List</h2>

                    </div>

                    <!-- /.card-header -->
                    <div class="card-body">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr.No</th>
                                    <th scope="col" class="text-center"> Name</th>
                                    <th scope="col" class="text-center">Mobile</th>
                                    <th scope="col" class="text-center">Email</th>
                                    <th scope="col" class="text-center">City</th>
                                    <th scope="col" class="text-center">Source</th>
                                    <th scope="col" class="text-center">LastStageName</th>
                                    <th scope="col" class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr *ngFor="let x of followup  | paginate:{itemsPerPage: PageSize, currentPage:p};let i = index"
                                    class="text-center">
                                    <td>{{PageSize*(p-1)+ i+1}}</td>
                                    <td>{{x.FirstName}}</td>
                                    <td>{{x.Mobile}}</td>
                                    <td>{{x.Email}}</td>
                                    <td>{{x.City}}</td>
                                    <td>{{x.SourceName}}</td>
                                    <td>{{x.LastStageName}}</td>
                                    <td class="text-right py-0 align-middle text-center">
                                        <div class="btn-group btn-group-sm">

                                            <button class="button button4" type="submit"
                                                (click)="PreviewRecord(x.Id)">Show Data</button>
                                            <button class="button button4" type="submit"
                                                (click)="EditRecord(x.Id,x.LastStageName)">Update Data</button>

                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                        <pagination-controls class="table-pagination" (pageChange)="p=$event" maxSize="9"
                            directionLinks="true" autoHide="true" responsive="true" previousLabel="Previous"
                            nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                            screenReaderCurrentLabel="You're on page"></pagination-controls>
                        <div class="dtastyle" *ngIf="followup?.length == 0">
                            <span class="search_no_results  ">
                                No data found
                            </span>
                        </div>
                    </div>

                </div>
                <!-- Main content -->
                <div class="modal-backdrop fade show" *ngIf="showList" style="display:block"></div>
                <div draggable="false" class="modal" id="addClass" role="dialog" aria-hidden="true"
                    style="display:block" *ngIf="showList">
                    <div class="modal-dialog modal-lg">
                        <div [formGroup]="followUpForm" class="modal-content   cls-width"
                            [ngClass]="actionMode == ActionModeEnum.Preview ? 'previewModeColor':'defaultModeColor'">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">
                                    <strong *ngIf="actionMode == ActionModeEnum.Edit">Update followup</strong>
                                    <strong *ngIf="actionMode == ActionModeEnum.Preview">followup-lead
                                    </strong>
                                    <button class="close" type="button" (click)="ClosePopup()" aria-label="Close">
                                        <span aria-hidden="true"><i class="fas fa-times" aria-hidden="true"></i></span>
                                    </button>
                                </h5>
                            </div>
                            <div class="modal-body pdf-model">
                                <div class="card-body" style="display: block;">
                                    <div class="row" *ngIf="actionMode == ActionModeEnum.Edit">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>Select Stage</label>
                                                <select class="form-control" formControlName="LeadStageId"
                                                    id="LeadStageId"
                                                    [ngClass]="{ 'is-invalid': submitted && lead.LeadStageId.errors }">
                                                    <option value="">-- Select --</option>
                                                    <option *ngFor="let x of stageddl;let i = index" [ngValue]="x.Id">
                                                        {{x.Name}}</option>

                                                </select>
                                                <div *ngIf="submitted && lead.LeadStageId.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="lead.LeadStageId.errors.required">
                                                        LeadStageId is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group ">
                                                <label>Remark/Requirement</label>
                                                <textarea type="text" class="form-control"
                                                    placeholder="Remark/Requirement Details" formControlName="Remarks"
                                                    maxlength="1000"
                                                    [ngClass]="{ 'is-invalid': submitted && lead.Remarks.errors }"></textarea>
                                                <div *ngIf="submitted && lead.Remarks.errors" class="invalid-feedback">
                                                    <div *ngIf="lead.Remarks.errors.required">
                                                        Remark/Requirement is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <table class="table table-bordered">

                                            <thead>
                                                <tr>
                                                    <th scope="col" class="text-center"> S.No</th>
                                                    <th scope="col" class="text-center">StageName</th>
                                                    <th scope="col" class="text-center">Remark</th>
                                                    <th scope="col" class="text-center">FollowUpdate</th>
                                                    <th scope="col" class="text-center">UpdateBy</th>
                                                    <th scope="col" class="text-center">UpdateDate</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr *ngFor="let x of followUpShow;let i = index" class="text-center">

                                                    <td>{{i+1}}</td>
                                                    <td>{{x.StageName}}</td>
                                                    <td>{{x.Remarks}}</td>
                                                    <td>{{(x.FollowUpdate !== '0001-01-01T00:00:00') ?
                                                        (x.FollowUpdate | date:'medium') : '' }}</td>
                                                    <td>{{x.UserRoleName}} - {{x.UpdatedByName}}</td>
                                                    <td>{{x.UpdatedDate | date:'medium'}}</td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="followUpShow && followUpShow.length==0">
                                                <tr>
                                                    <td colspan="8" class="text-center">
                                                        No data Found.
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>
                                        <div class="dtastyle" *ngIf="followUpShow?.length == 0">
                                            <span class="search_no_results">
                                                No data found
                                            </span>
                                        </div>

                                    </div>

                                </div>


                                <div class="card-footer col-12" *ngIf="actionMode == ActionModeEnum.Edit">
                                    <button type="button" (click)="ClosePopup()" class="btn btn-secondary float-left"><i
                                            class="fas fa-times"></i>
                                        Close</button>
                                    <button type="button" (click)="Save()" class="btn btn-primary float-right">
                                        <i class="fas fa-save"></i>
                                        Save</button>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>


</section>