import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseModel } from 'src/app/models/ResponseModel';
import { HttpDataService } from 'src/app/services/http-data.service';
import { URLList } from 'src/app/common/Utility/URL';
import { KeyValuePair } from 'src/app/models/KeyValuePair.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private _httpData: HttpDataService) { }

  stageDDl(): Observable<ResponseModel> {
    return this._httpData.Get<ResponseModel>(URLList.Reports.DDl);
  }
  GetMenus(): Observable<ResponseModel> {
    return this._httpData.Get<ResponseModel>(URLList.Common.GetMenus, false);
  }
  getAllMenus(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpData.GetByURL<ResponseModel>(URLList.Common.GetAllMenus, requestData);
  }
  getDeatails(): Observable<ResponseModel> {
    return this._httpData.Get<ResponseModel>(URLList.Common.LeadCountDashboard);
  }
}
