<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-4">
                <h1>Account Type</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Account Type</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>
<section class="content add-detail">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card collapsed-card">
                    <div class="card-header b-t-info">
                        <h3 class="card-title" data-card-widget="collapse" data-toggle="tooltip">Search</h3>

                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                                <i class="fas fa-search"></i></button>
                        </div>
                    </div>
                    <div class="card-body" style="display: block;">

                        <div>
                            <div class="col-12">
                                <div class="form-group">
                                    <input class="form-control" type="text" name="AccountTypeName" [(ngModel)]="AccountTypeName" (ngModelChange)="Search()" placeholder="Enter Account Type Name Search">

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div class="card">
                    <div class="card-header b-t-olive">
                        <h3 class="card-title">List</h3>

                        <div class="card-tools">
                            <button type="button" (click)="AddNew()" class="btn btn-block bg-gradient-info">
                                <i class="fas fa-plus"></i> Add</button>
                        </div>
                    </div>
                    <div class="card-body">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col" class="text-center">S No</th>
                                    <th scope="col" class="text-center">Name</th>
                                    <th scope="col" class="text-center">Description</th>
                                    <th scope="col" class="text-center">Created By</th>
                                    <th scope="col" class="text-center">Created Date</th>
                                    <th scope="col" class="text-center">Status</th>
                                    <th scope="col" class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let x of AccountTypeList | paginate:{itemsPerPage: PageSize, currentPage:p} ;let i = index" class="text-center">
                                    <td>{{PageSize*(p-1)+ i+1}}</td>
                                    <td>{{x.Name}}</td>
                                    <td>{{x.Description}}</td>
                                    <td>{{x.CreatedByName}}</td>
                                    <td>{{x.CreatedDate | date :'mediumDate'}}</td>

                                    <td> <span *ngIf="x.IsActive" class="badge bg-success">Active</span>
                                        <span *ngIf="!x.IsActive" class="badge bg-gradient-danger">InActive</span>
                                    </td>
                                    <td class="text-center py-0 align-middle">
                                        <div class="btn-group btn-group-sm">
                                            <a href="javascript:void(0);" title="Edit" (click)="EditRecord(x)" class="btn btn-info"><i class="fas fa-edit"></i></a>
                                            <a href="javascript:void(0);" title="Delete" (click)="DeleteRecord(x.Id)" class="btn btn-danger"><i class="fas fa-trash"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                        <pagination-controls class="table-pagination" (pageChange)="p=$event" maxSize="9" directionLinks="true" autoHide="true" responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page"
                            *ngIf="AccountTypeList?.length > 0">
                        </pagination-controls>
                        <div class="dtastyle" *ngIf="AccountTypeList && AccountTypeList.length==0">
                            <span colspan="8" class="search_no_results text-center ">
                                No data found
                            </span>
                        </div>
                    </div>

                </div>
                <div class="modal-backdrop fade show" *ngIf="showList" style="display:block"></div>
                <div draggable="false" class="modal" id="addClass" role="dialog" aria-hidden="true" style="display:block" *ngIf="showList">
                    <div class="modal-dialog modal-lg">
                        <div [formGroup]="registerForm" class="modal-content" [ngClass]="actionMode == ActionModeEnum.Preview ? 'previewModeColor':'defaultModeColor'">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">
                                    <strong *ngIf="actionMode == ActionModeEnum.Add">Add Account Type</strong>
                                    <strong *ngIf="actionMode == ActionModeEnum.Edit">Edit Account Type</strong>
                                    <button class="close" type="button" (click)="ClosePopup()" aria-label="Close">
                                        <span aria-hidden="true"><i class="fas fa-times" aria-hidden="true"></i></span>
                                    </button>
                                </h5>
                            </div>
                            <div class="modal-body pdf-model">
                                <div class="card-body" style="display: block;">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Name</label><span class="redStar">*</span>
                                                <input type="text" class="form-control" formControlName="Name" placeholder="Name" maxlength="100" [ngClass]="{ 'is-invalid': submitted && f.Name.errors }">
                                                <div *ngIf="submitted && f.Name.errors" class="invalid-feedback">
                                                    <div *ngIf="f.Name.errors.required"> Name is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Description</label>
                                                <textarea type="text" class="form-control" formControlName="Description" placeholder="Description" maxlength="1000" [ngClass]="{ 'is-invalid': submitted && f.Description.errors }"></textarea>
                                                <div *ngIf="submitted && f.Description.errors" class="invalid-feedback">
                                                    <div *ngIf="f.Description.errors.required">
                                                        Description is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Status</label>
                                                <input type="checkbox" name="my-checkbox" class="form-check-input chkMargin" formControlName="IsActive" data-bootstrap-switch data-off-color="danger" data-on-color="success">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card-footer" *ngIf="actionMode == ActionModeEnum.Add || actionMode == ActionModeEnum.Edit">
                                <button type="button" (click)="Reset()" class="btn btn-secondary float-left"><i
                                        class="fas fa-times"></i>
                                    Reset</button>
                                <button type="button" (click)="Save()" class="btn btn-primary float-right"> <i
                                        class="fas fa-save"></i>
                                    Save</button>
                            </div>
                        </div>



                    </div>
                </div>

            </div>
        </div>
    </div>
</section>