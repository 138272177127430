export class Users {
    Id: number;
    leadid : number; 
    UserCode: string;
    UserName: string;
    Password: string;
    UserRoleId: number;
    FirstName: string;
    LastName: string;
    Mobile: string;
    Email: string;
    Address: string;
    IsActive: boolean;
    CreatedBy: number | null;
    UpdatedBy: number | null;
    CreatedDate: Date | string | null;
    UpdatedDate: Date | string | null;
}
export class FilterUsers {
    leadid: number;
    Id: number| null;
    UserName: string;
    UserRole:string;
    Name: string;
    Email: string;
    Mobile: string;
    UserRoleId: number;
    IsActive : boolean;
}