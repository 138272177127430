import { Injectable } from '@angular/core';
import { HttpDataService } from 'src/app/services/http-data.service';
import { ResponseModel } from 'src/app/models/ResponseModel';
import { Observable } from 'rxjs';
import { URLList } from 'src/app/common/Utility/URL';
import { UserModel } from 'src/app/models/UserModel';
import { DataTransferDTO } from 'src/app/models/DataTransferDTO.model';
import { Customers, FilterCustomers } from 'src/app/models/customerDTO/Customers.model';
import { CustomerOrders, FilterCustomerOrders } from 'src/app/models/customerDTO/CustomerOrders.model';
import { CustomerWallet, FilterCustomerWallet } from "src/app/models/customerDTO/CustomerWallet.model";
import { KeyValuePair } from 'src/app/models/KeyValuePair.model';
import { CustomerType } from 'src/app/models/customerDTO/customerType.model';
import { ProcessOrder } from 'src/app/models/customerDTO/ProcessOrder.model';
import { DispatchOrder } from 'src/app/models/customerDTO/DispatchOrder.model';


@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  dataTransferDTO: DataTransferDTO;
  orderDataTransferDTO: DataTransferDTO;
  customerWalletDataTransferDTO: DataTransferDTO;

  constructor(private _httpDataService: HttpDataService) { }

  Login(userModel: UserModel): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Accounts.login, userModel, false);
  }
  //========================== Customer [Start] =================================
  getCustomers(requestData: FilterCustomers): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Customers.GetCustomers, requestData);
  }
  getCustomersById(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Customers.GetCustomers, requestData);
  }
  getCustomerByMobile(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Customers.GetCustomerByMobile, requestData);
  }
  GetCustomersUserRoleWiseForddl(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Customers.GetCustomersUserRoleWiseForddl);
  }
  saveCustomers(requestData: Customers): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Customers.SaveCustomers, requestData);
  }
  deleteCustomers(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Customers.DeleteCustomers, requestData);
  }

  setCustomersDataToService(details: DataTransferDTO) {
    this.dataTransferDTO = details;
  }
  getCustomersDataFromService(): DataTransferDTO {
    return this.dataTransferDTO;
  }
  resetCustomersDataToService(): void {
    this.dataTransferDTO = null;
  }
  //========================== Customer [End] ==================================

  getCustomerTypes(): Observable<ResponseModel> {
    return this._httpDataService.Get<ResponseModel>(URLList.Customers.GetCustomerTypes);
  }
  getCustomerTypesById(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Customers.GetCustomerTypes, requestData);
  }
  deleteCustomerTypes(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Customers.DeleteCoutomerType, requestData);
  }
  saveCustomerTypes(requestData: CustomerType): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Customers.SaveCustomerTypes, requestData);
  }
  //========================== Orders [Start] =================================
  getOrdersSummaryInfo(requestData: FilterCustomerOrders): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Customers.GetOrdersSummaryInfo, requestData);
  }  
  getOrdersByStage(requestData: FilterCustomerOrders): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Customers.GetOrdersByStage, requestData);
  }
  getOrders(requestData: FilterCustomerOrders): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Customers.GetOrders, requestData);
  }
  getOrdersDetails(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Customers.GetOrdersDetails, requestData);
  }
  getDispatchOrderDetails(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Customers.GetDispatchOrderDetails, requestData);
  }
  saveOrders(requestData: CustomerOrders): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Customers.SaveOrders, requestData);
  }
  processOrder(requestData: ProcessOrder): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Customers.ProcessOrder, requestData);
  }
  dispatchOrder(requestData: DispatchOrder): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Customers.DispatchOrder, requestData);
  }
  deleteOrder(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Customers.DeleteOrder, requestData);
  }
  getOrderProductStages(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Customers.GetOrderProductStages, requestData);
  }
  getPrintOrderForddl(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Customers.GetPrintOrderForddl, requestData);
  }
  getPrintOrderDetails(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Customers.GetPrintOrderDetails, requestData);
  }
  getPrintProcessOrderDetails(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Customers.GetPrintProcessOrderDetails, requestData);
  }
  setOrdersDataToService(details: DataTransferDTO) {
    this.orderDataTransferDTO = details;
  }
  getOrdersDataFromService(): DataTransferDTO {
    return this.orderDataTransferDTO;
  }
  resetOrdersDataToService(): void {
    this.orderDataTransferDTO = null;
  }

  //========================== Orders [End] ===================================

  getCustomerWallet(requestData: FilterCustomerWallet): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Customers.GetCustomerWallet, requestData);
  }
  saveCustomerWallet(requestData: CustomerWallet): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Customers.SaveCustomerWallet, requestData);
  }
  updateOrderPayment(requestData: CustomerWallet): Observable<ResponseModel> {
    return this._httpDataService.postMethod<ResponseModel>(URLList.Customers.UpdateOrderPayment, requestData);
  }
  setCustomerWalletDataToService(details: DataTransferDTO) {
    this.customerWalletDataTransferDTO = details;
  }
  getCustomerWalletDataFromService(): DataTransferDTO {
    return this.customerWalletDataTransferDTO;
  }
  resetCustomerWalletDataToService(): void {
    this.customerWalletDataTransferDTO = null;
  }

  decryptBarCodeByText(requestData: KeyValuePair): Observable<ResponseModel> {
    return this._httpDataService.GetByURL<ResponseModel>(URLList.Common.DecryptBarCode, requestData);
  }

}
