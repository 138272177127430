<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Users</h1>
                <h5 class="modal-title">
                </h5>
            </div>
            <div class="col-sm-6">

                <ol class="breadcrumb float-sm-right">

                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active" *ngIf="actionMode == ActionModeEnum.Add">Add User</li>
                    <li class="breadcrumb-item active" *ngIf="actionMode == ActionModeEnum.Edit">Edit User</li>
                    <li class="breadcrumb-item active" *ngIf="actionMode == ActionModeEnum.Preview">Preview User</li>
                    <div class="box-tools pull-right  ml-lg-2">
                        <a [routerLink]="'/user/user-list'" class="btn bg-navy btn-sm" title="Show List"><i
                                class="fa fa-reply"></i> Show List</a>
                    </div>
                </ol>
            </div>
        </div>
    </div><!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content add-detail">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div [formGroup]="userForm" class="card collapsed-card">
                    <div class="card-body" style="display: block;"
                        [ngClass]="actionMode == ActionModeEnum.Preview ? 'previewModeColor':'defaultModeColor'">
                        <div class="row">
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Select User Role</label><span class="redStar">*</span>
                                    <select class="form-control" formControlName="UserRoleId" id="UserRoleId"
                                        [ngClass]="{ 'is-invalid': submitted && f.UserRoleId.errors }">
                                        <option value="">-- Select --</option>
                                        <option *ngFor="let x of userRoleTypeList;" [ngValue]="x.Id">
                                            {{x.Name}}
                                        </option>
                                    </select>

                                    <div *ngIf="submitted && f.UserRoleId.errors" class="invalid-feedback">
                                        <div *ngIf="f.UserRoleId.errors.required">
                                            User Role is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>User Code</label><span class="redStar">*</span>
                                    <input type="text" class="form-control" placeholder="User Code"
                                        formControlName="UserCode" maxlength="50"
                                        [ngClass]="{ 'is-invalid': submitted && f.UserCode.errors }">
                                    <div *ngIf="submitted && f.UserCode.errors" class="invalid-feedback">
                                        <div *ngIf="f.UserCode.errors.required">
                                            User Code is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2"></div>
                            <div class="col-2 float-right" *ngIf="actionMode == ActionModeEnum.Edit">
                                <div class="card-tools">
                                    <button type="button" (click)="OpenPopup()"
                                        class="btn btn-block bg-gradient-info">Update Password
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4">
                                <div class="form-group">
                                    <label>First Name</label><span class="redStar">*</span>
                                    <input type="text" class="form-control" placeholder="First Name"
                                        formControlName="FirstName" maxlength="50"
                                        [ngClass]="{ 'is-invalid': submitted && f.FirstName.errors }">
                                    <div *ngIf="submitted && f.FirstName.errors" class="invalid-feedback">
                                        <div *ngIf="f.FirstName.errors.required">
                                            First Name is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Last Name</label><span class="redStar">*</span>
                                    <input type="text" class="form-control" placeholder="Last Name"
                                        formControlName="LastName" maxlength="50"
                                        [ngClass]="{ 'is-invalid': submitted && f.LastName.errors }">
                                    <div *ngIf="submitted && f.LastName.errors" class="invalid-feedback">
                                        <div *ngIf="f.LastName.errors.required">
                                            Last Name is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label for="Mobile">Mobile Number (Use as UserName)</label><span
                                        class="redStar">*</span>
                                    <input formControlName="Mobile" placeholder="Mobile Number" id="Mobile"
                                        type="text" [ngClass]="{ 'is-invalid': submitted && f.Mobile.errors }"
                                        class="form-control" maxlength="10">
                                    <div *ngIf="submitted && f.Mobile.errors" class="invalid-feedback">
                                        <div *ngIf="f.Mobile.errors.required">Mobile Number is required.</div>
                                        <div *ngIf="f.Mobile.errors.pattern">Please, Enter 10 digit Mobile Number.</div>
                                    </div>
                                </div>
                                <!-- <div class="form-group">
                                    <label>Mobile</label><span class="redStar">*</span>
                                    <input type="number" class="form-control" placeholder="Mobile"
                                           formControlName="Mobile"  ngModel maxlength="10"
                                           [ngClass]="{ 'is-invalid': submitted && f.Mobile.errors }">
                                    <div *ngIf="submitted && f.Mobile.errors" class="invalid-feedback">
                                        <div *ngIf="f.Mobile.errors.required"> Mobile is required</div> 
                                        <div *ngIf="f.Mobile.errors.pattern">Please, Enter 10 digit Mobile Number.</div>
                                    </div>
                                </div> -->
                            </div>

                        </div>
                        <div class="row">
                            <!-- <div class="col-4">
                                <div class="form-group">
                                    <label>User Name</label><span class="redStar">*</span>
                                    <input type="text" class="form-control" placeholder="User Name"
                                        formControlName="UserName" maxlength="50"
                                        [readonly]="actionMode == ActionModeEnum.Edit"
                                        [ngClass]="{ 'is-invalid': submitted && f.UserName.errors }">
                                    <div *ngIf="submitted && f.UserName.errors" class="invalid-feedback">
                                        <div *ngIf="f.UserName.errors.required">
                                            UserName is required
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="text" formControlName="Email" placeholder="Enter Email"
                                        class="form-control" />
                                </div>
                            </div>
                            <div class="col-4" *ngIf="actionMode == ActionModeEnum.Add">
                                <div class="form-group">
                                    <label>Password</label><span class="redStar">*</span>
                                    <input type="password" class="form-control" placeholder="Password"
                                        formControlName="Password" maxlength="50"
                                        [ngClass]="{ 'is-invalid': submitted && f.Password.errors }">
                                    <div *ngIf="submitted && f.Password.errors" class="invalid-feedback">
                                        <div *ngIf="f.Password.errors.required">
                                            Password is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4" *ngIf="actionMode == ActionModeEnum.Add">
                                <div class="form-group">
                                    <label>Confirm Password</label><span class="redStar">*</span>
                                    <input type="password" class="form-control" placeholder="Confirm Password"
                                        formControlName="ConfirmPassword" maxlength="50"
                                        [ngClass]="{ 'is-invalid': submitted && f.ConfirmPassword.errors }">
                                        <div *ngIf=" userForm.get('Password').value != userForm.get('ConfirmPassword').value && userForm.get('ConfirmPassword').touched"  >
                                            <p  >
                                                Confirm Password must be same
                                            </p>
                                        </div>
                                    <div *ngIf="submitted && f.ConfirmPassword.errors" class="invalid-feedback">  
                                        <div *ngIf="f.ConfirmPassword.errors.required">
                                           Confirm Password is required
                                        </div>  
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Address</label><span class="redStar">*</span>
                                    <textarea type="text" class="form-control" formControlName="Address"
                                        placeholder="Address" maxlength="1000"
                                        [ngClass]="{ 'is-invalid': submitted && f.Address.errors }"></textarea>
                                    <div *ngIf="submitted && f.Address.errors" class="invalid-feedback">
                                        <div *ngIf="f.Address.errors.required">
                                            Address is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label>Status</label>
                                <input type="checkbox" name="my-checkbox"
                                    class="form-check-input chkMargin" id="statusSwitch" formControlName="IsActive"
                                    data-bootstrap-switch data-off-color="danger"  
                                    >
                            </div>
                        </div>
                    </div>
                    <div class="card-footer" style="display: block;"
                        *ngIf="actionMode == ActionModeEnum.Add || actionMode == ActionModeEnum.Edit">

                        <div class="row">
                            <div class="col-9">
                                <button type="button" (click)="Reset()" class="btn btn-secondary float-left">
                                    <i class="fas fa-times"></i>
                                    Reset
                                </button>
                            </div>
                            <!-- <div class="col-1">
                               
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" formControlName="IsActive"
                                        id="statusSwitch">
                                    <label class="custom-control-label" for="statusSwitch">Status</label>
                                </div>
                              
                            </div> -->
                            <div class="col-3">
                                <button type="button" (click)="Save()" class="btn btn-primary float-right">
                                    <i class="fas fa-save"></i>
                                    Save
                                </button>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
            <!-- /.col -->

            <div class="modal-backdrop fade show" *ngIf="showList" style="display:block"></div>
            <div draggable="false" class="modal" id="addClass" role="dialog" aria-hidden="true" style="display:block"
                *ngIf="showList">
                <div class="modal-dialog modal-sm">
                    <div [formGroup]="passwordForm" class="modal-content defaultModeColor">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                                <strong>Update Password</strong>

                                <button class="close" type="button" (click)="ClosePopup()" aria-label="Close">
                                    <span aria-hidden="true"><i class="fas fa-times" aria-hidden="true"></i></span>
                                </button>
                            </h5>
                        </div>
                        <div class="modal-body pdf-model">
                            <div class="card-body" style="display: block;">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>New Password</label><span class="redStar">*</span>
                                            <input type="text" class="form-control" formControlName="NewPassword"
                                                placeholder="New Password" maxlength="50"
                                                [ngClass]="{ 'is-invalid': submitted && pf.NewPassword.errors }">
                                            <div *ngIf="submitted && pf.NewPassword.errors" class="invalid-feedback">
                                                <div *ngIf="pf.NewPassword.errors.required">New Password is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Confirm Password</label><span class="redStar">*</span>
                                            <input type="text" class="form-control" placeholder="Confirm Password"
                                                formControlName="NewConfirmPassword" maxlength="50"
                                                [ngClass]="{ 'is-invalid': submitted && pf.NewConfirmPassword.errors }">
                                            <div *ngIf="submitted && pf.NewConfirmPassword.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="pf.NewConfirmPassword.errors.required">
                                                    Confirm Password is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-footer">
                            <button type="button" (click)="SavePassword()" class="btn btn-primary float-right"> <i
                                    class="fas fa-save"></i>
                                Save</button>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>
<!-- /.content -->