<!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-dark-primary elevation-4">
  <!-- Brand Logo -->
  <a [routerLink]="'dashboard'" class="brand-link">
    <img src="../../../assets/images/logo.png" class="brand-image">
  </a>
  <div class="sidebar">


    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column"  data-widget="treeview" role="menu" data-accordion="false" >
        <li class="nav-item has-treeview" *ngFor="let menuItem of menu">
          <a class="nav-link" *ngIf="menuItem.ChildMenu.length > 0">
            <i class="fas nav-icon" [class]="menuItem?.Icon"></i>
            <p>
              {{menuItem?.MenuName}}
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <a class="nav-link" *ngIf="menuItem.ChildMenu.length == 0" [routerLink]="menuItem?.Url">
            <i class="fas nav-icon" [class]="menuItem?.Icon"></i>
            <p>
              {{menuItem?.MenuName}}
            </p>
          </a>
          <ul class="nav nav-treeview" *ngFor="let childItem of menuItem.ChildMenu">
            <li class="nav-item has-treeview first-child-item">
              <a class="nav-link" *ngIf="childItem.ChildMenu.length > 0">
                <i class="fas nav-icon" [class]="childItem?.Icon"></i>
                <p>
                  {{childItem.MenuName}}
                  <i class="right fas fa-angle-left"></i>
                </p>
              </a>
              <a class="nav-link" *ngIf="childItem.ChildMenu.length == 0" [routerLink]="childItem.Url">
                <i class="fas nav-icon" [class]="childItem?.Icon"></i>
                <p>
                  {{childItem.MenuName}}
                </p>
              </a>

              <ul class="nav nav-treeview">
                <li  *ngFor="let lastChildItem of childItem.ChildMenu" class="nav-item has-treeview lastChildItem">
                  <a [routerLink]="lastChildItem.Url" class="nav-link">
                    <i class="fas nav-icon" [class]="lastChildItem?.Icon"></i>
                    <p> {{lastChildItem?.MenuName}}</p>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </nav>

  </div>
  
</aside>