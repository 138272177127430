import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { ActionMode } from 'src/app/models/enum';
import { KeyValuePair } from 'src/app/models/KeyValuePair.model';
import swal from 'sweetalert';
import { CrmService } from 'src/app/modules/crm/crm.service';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
// import{filterFollowup,followup} from 'src/app/models/crm.model';
import { filterFollowup, followlead } from 'src/app/models/followlead.model';
import { CommonDropdownModel } from 'src/app/models/CommonDropdownModel.model';
import { InquiryService } from 'src/app/modules/inquiry/service/inquiry.service';

@Component({
  selector: 'app-followup-lead',
  templateUrl: './followup-lead.component.html',
  styleUrls: ['./followup-lead.component.css']
})
export class FollowupLeadComponent implements OnInit {
  public courierCompanyList: Array<followlead>;
  public courierCompany: followlead = new followlead();
  public leadForm: FormGroup;
  followup: any;
  public showList: boolean = false;
  p: number = 1;
  PageSize: number = 5;
  public filterData: followlead = new followlead();
  public FollowUp: filterFollowup = new filterFollowup();
  public submitted = false;
  public actionMode: ActionMode = ActionMode.Add;
  public followUpForm: FormGroup;
  followleadedit: any;
  followUpShow: any;
  public userLeadSourace: Array<CommonDropdownModel>;
  dashBoardData: any;
  getLeads: any;
  leadStageName: any;
  editId: any;
  previewId: any;
  stageddl: any;

  constructor(private _crmService: CrmService,
    private formBuilder: FormBuilder,
    private _toasterService: ToasterService,
    private _commonService: CommonService,
    private router: Router,
    public inquiryService: InquiryService) { }

  ngOnInit(): void {

    this.getLead();
    this.BindUser();
    this.InitializeleadForm();
    this.formInitiallead();
    this.BindUserLeadSource();
    this.stageDDl();

  }
  stageDDl() {
    var self = this;
    self._crmService.stageDDl().subscribe(response => {
      if (response.IsSuccess) {
        this.stageddl = response.Data;
       // console.log('this.stageddl', this.stageddl)
      }
      else {
        this.stageddl = [];
      }
    });
  }
  InitializeleadForm() {
    this.leadForm = this.formBuilder.group({
      Name: ['', Validators.nullValidator],
      FirstName: ['', Validators.nullValidator],
      LastName: ['', Validators.nullValidator],
      Mobile: ['', Validators.required,Validators.minLength(10)],
      Email: ['', Validators.required,Validators.email],
      City: ['', Validators.nullValidator],
      Source: ['', Validators.nullValidator],
      Remark: ['', Validators.nullValidator],
      Address: ['', Validators.nullValidator],
    });


  }
  getLead() {
    this.inquiryService.getDeatails().subscribe(response => {
      if (response.IsSuccess) {
        this.getLeads = response.Data;
       // console.log(' this.getLeads', this.getLeads)

      }
      else {
        this.getLeads = [];
      }
    });
  }
  formInitiallead() {
    this.followUpForm = this.formBuilder.group({
      LeadStageId: ['', Validators.required],
      Remarks: ['', Validators.required],

    });
  }
  get lead() {
    return this.followUpForm.controls;
  }
  BindUserLeadSource() {
    var self = this;
    self.inquiryService.getLeadSourace().subscribe((response) => {
      self.userLeadSourace = (response.IsSuccess && response.Data != null && response.Data.length > 0) ? response.Data : [];
      //console.log('self.userLeadSourace', self.userLeadSourace);
    });
  }
  get f() {
    return this.leadForm.controls;
  }
  public get ActionModeEnum(): typeof ActionMode {
    return ActionMode;
  }
  SearchFilter() {
    var self = this;
    self.FollowUp = self.setfollowup(self.FollowUp);
    self.BindUser();
  }
  setfollowup(FilterFollowup) {
    FilterFollowup.Id = this.leadForm.get('Id') != null ? this.leadForm.get('Id').value : null;
    FilterFollowup.Name = this.leadForm.get('Name') != null ? this.leadForm.get('Name').value : null;
    FilterFollowup.Email = this.leadForm.get('Email') != null ? this.leadForm.get('Email').value : null;
    FilterFollowup.Mobile = this.leadForm.get('Mobile') != null ? this.leadForm.get('Mobile').value : null;
    FilterFollowup.City = this.leadForm.get('City') != null ? this.leadForm.get('City').value : null;
    FilterFollowup.Source = this.leadForm.get('Source') != null ? this.leadForm.get('Source').value : null;
    return FilterFollowup;
  }

  Reset() {
    this.submitted = false;
    this.leadForm.reset();
    this.InitializeleadForm();
    this.followUpForm.reset();
    this.BindUser();
    
    
  }

  ClosePopup() {
    this.showList = false;
  }
  BindUser() {
    var self = this;
    self._crmService.getLeadsForFollowUp(this.FollowUp).subscribe(response => {
      if (response.IsSuccess) {
        this.followup = response.Data;
        //console.log('this.followup', this.followup)
      }
      else {
        this.followup = []; 
      }
    });
  }
  Save() {
    this.submitted = true;
    this.editId = this.followUpShow;
    let Details = {
      "LeadId": this.previewId,
      "LeadStageId": this.followUpForm.value.LeadStageId,
      "Remarks": this.followUpForm.value.Remarks,
      "FollowUpdate": new Date(),
    }
    //console.log('Details', Details);
    if (this.followUpForm.invalid) {
      this.followUpForm.markAsTouched();
      return;
    }
    else {
      this._crmService.saveLead(Details).subscribe((response) => {
       // console.log('saveLead : ', response);

        if (response.IsSuccess) {
          this._toasterService.pop('success', "Success", response.Message);
          this.Reset();
          this.ClosePopup();
        }
        else {
          this._toasterService.pop('error', 'Error', response.Message);
        }
      });
    }
  }
  PreviewRecord(id) {
    //console.log('this.previewId', this.previewId);
    let dataToPass: KeyValuePair = { key: "LeadId", value: id };
    this._crmService.getLeadById(dataToPass).subscribe(response => {
      if (response.IsSuccess) {
        this.followUpShow = response.Data;
        this.showList = true;
        this.actionMode = ActionMode.Preview;
        //console.log('this.followUpShow', this.followUpShow.length);

      }
      else {
        this.followUpShow = [];
      }
    });
  }
  EditRecord(Id, LastStageName) {
    //this.leadStageName = LastStageName;
    let dataToPass: KeyValuePair = { key: "LeadId", value: Id };
    this.previewId = dataToPass.value;
    //console.log('dataToPass', this.previewId);
    this._crmService.getLeadById(dataToPass).subscribe(response => {
      if (response.IsSuccess) {
        this.followUpShow = response.Data;
        this.showList = true;
        this.actionMode = ActionMode.Edit;
       // console.log('this.followUpShow', this.followUpShow)
      }
      else {
        this.followUpShow = [];
      }
    });

  }

}
