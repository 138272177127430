import { Component, OnInit } from '@angular/core';
import { KeyValuePair } from 'src/app/models/KeyValuePair.model';
import { MenuService } from '../menu.service';
import { MenuPermission, MenuPermissionSave } from 'src/app/models/commonDTO/MenuPermission.model';
import { CommonDropdownModel } from 'src/app/models/CommonDropdownModel.model';
import { Validators } from '@angular/forms';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-menu-permission',
  templateUrl: './menu-permission.component.html',
  styleUrls: ['./menu-permission.component.css']
})
export class MenuPermissionComponent implements OnInit {
  public menuTree: Array<MenuPermission>;
  public menuList: Array<MenuPermission>;
  public menuPermissionSave: MenuPermissionSave = new MenuPermissionSave();
  public userRoleList: Array<CommonDropdownModel>;
  public menuForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private _toasterService: ToasterService,
    private _menuService: MenuService) {
  }

  ngOnInit(): void {
    this.InitializeForm();
    this.BindUserRoleTypeDDL();
  }
  InitializeForm() {
    this.menuForm = this.formBuilder.group({
      UserRoleId: ['0', Validators.required],
    });
  }
  changeMenu(e) {
    if (e.target.value > 0) {
      this.getMenuList(e.target.value);
    }
    else {
      this.menuTree = []
    }
  }
  getMenuList(Id: number) {
    let dataToPass: KeyValuePair = { key: "userRoleId", value: String(Id) };
    this._menuService.allMenuList(dataToPass).subscribe(response => {
      if (response.IsSuccess) {
        this.menuList = response.Data;
        this.menuTree = this.list_to_tree(this.menuList);
      }
    });
  }
  onCheckboxChange(e) {
    var item = this.menuList.find(x => x.Id == e.target.value);
    let index = this.menuList.indexOf(item);
    item.IsAlreadyAccess = e.target.checked;
    this.menuList[index] = item;
    if (item.ParentMenuId != 0 && e.target.checked) {
      item = this.menuList.find(x => x.Id == item.ParentMenuId);
      index = this.menuList.indexOf(item);
      item.IsAlreadyAccess = true;
      this.menuList[index] = item;
      if (item.ParentMenuId != 0 && e.target.checked) {
        item = this.menuList.find(x => x.Id == item.ParentMenuId);
        index = this.menuList.indexOf(item);
        item.IsAlreadyAccess = true;
        this.menuList[index] = item;
      }
    }
  }

  list_to_tree(list) {
    var map = {}, node, roots = [], i;

    for (i = 0; i < list.length; i += 1) {
      map[list[i].Id] = i; // initialize the map
      list[i].ChildMenu = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.ParentMenuId !== 0) {
        list[map[node.ParentMenuId]].ChildMenu.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  }

  Save() {
    this.menuPermissionSave.UserRoleId = this.menuForm.get('UserRoleId').value;
    this.menuPermissionSave.MenuPermissionList = this.menuList;
    this._menuService.savePermission(this.menuPermissionSave).subscribe((response) => {
      if (response.IsSuccess) {
        this._toasterService.pop('success', "Success", response.Message);
      }
      else {
        this._toasterService.pop('error', 'Error', response.Message);
      }
    });
  }

  BindUserRoleTypeDDL() {
    var self = this;
    self._menuService.getUserRolesForddl().subscribe((response) => {
      self.userRoleList = (response.IsSuccess && response.Data != null && response.Data.length > 0) ? response.Data : [];
      self.userRoleList.splice(1, 1);
    });
  }
}

