export class CompanyPaymentAccount {
    Id: number;
    PaymentAccountName: string;
    AccountTypeId: number | null;
    AccountNumber: string;
    OpeningBalance: number;
    Note: string;
    Label1: string;
    Value1: string;
    Label2: string;
    Value2: string;
    Label3: string;
    Value3: string;
    Label4: string;
    Value4: string;
    Label5: string;
    Value5: string;
    IsActive: boolean;
}