import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/modules/dashboard/service/dashboard.service';
import { Menu } from 'src/app/models/commonDTO/Menu.model';
import { KeyValuePair } from 'src/app/models/KeyValuePair.model';
import { UserRole } from 'src/app/models/enum';
import { UserRoles } from 'src/app/models/commonDTO/userRoles.model';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-manage-access',
  templateUrl: './manage-access.component.html',
  styleUrls: ['./manage-access.component.css']
})
export class ManageAccessComponent implements OnInit {
 
  public menu: Menu = new Menu();
  public userRoles: UserRoles = new UserRoles();
  constructor(private _dashboardService: DashboardService,private _commonService: CommonService) { }

  ngOnInit() {
    this.BindUserRolesDDL();
}
BindUserRolesDDL() {
  var self = this;
  self._commonService.getUserRolesForddl().subscribe((response) => {
    self.userRoles = (response.IsSuccess && response.Data != null && response.Data.length > 0) ? response.Data : [];
   // console.log('self.userRoles : ',self.userRoles);
  });
}
modo(value: any){
  debugger;
 // console.log(value,'value');
  alert(value);
  
  switch(value) {
    case "mod1":
       // if modo 1 is selected do something.
       break;
    case "mod2":
       // if modo 2 is selected do something.
       break;
    case "mod3":
       // if modo 3 is selected do something.
       break;
  }
}
GetAllMenus() {
  let dataToPass: KeyValuePair = { key: "userRoleId", value: "3" };
    this._dashboardService.getAllMenus(dataToPass).subscribe(res => {
      if (res.IsSuccess) {
        this.menu = res.Data;
       // console.log('Menu Response - GetAllMenus :', res,'this.menu',this.menu);
      }
    });
  }

  
}
