<select id="test">
    <option value="1">American Black Bear</option>
    <option value="2">Asiatic Black Bear</option>
    <option value="3">Brown Bear</option>
    <option value="4">Giant Panda</option>
    <option value="5">Sloth Bear</option>
    <option value="6">Sun Bear</option>
    <option value="7">Polar Bear</option>
    <option value="8">Spectacled Bear</option>
  </select>
<form>
        <div class="card-header ">Add Role</div>

    <div class="card   f-class">
        <div class="form-group">
            <label>Role Name*</label>
            <select class="form-control select2 select2-hidden-accessible"  data-select2-id="1"
                tabindex="-1" aria-hidden="true">
                <option selected="selected" data-select2-id="3">Alabama</option>
                <option data-select2-id="18">Search</option>
                <option data-select2-id="19">User</option>
                <option data-select2-id="20">Roles</option>
                <option data-select2-id="21">Supplier</option>
                <option data-select2-id="22">Customer</option>
                <option data-select2-id="23">Product</option>
                <option data-select2-id="22">Sell</option>
                <option data-select2-id="23">Shipments</option>
                <option data-select2-id="22">Cash Register</option>
                <option data-select2-id="23">Crm</option>
                <option data-select2-id="22">Essentials</option>

            </select>
        </div>

        <div class="form-group">
            <label>Permission:</label>
            <h4>User</h4>
            <input type="checkbox" name="vehicle1" value="Bike">
            <label for="vehicle1">View user</label><br>
            <input type="checkbox" name="vehicle2" value="Car">
            <label for="vehicle2"> Add user</label><br>
            <input type="checkbox" name="vehicle3" value="Boat">
            <label for="vehicle3">Edit user</label><br>
            <input type="checkbox" name="vehicle3" value="Boat">
            <label for="vehicle3">Delete user</label><br><br>

        </div>
        <hr class="new1">
        <div class="form-group">

            <h4>Roles</h4>
            <input type="checkbox" name="vehicle1" value="Bike">
            <label for="vehicle1">View role</label><br>
            <input type="checkbox" name="vehicle2" value="Car">
            <label for="vehicle2"> Add Role</label><br>
            <input type="checkbox" name="vehicle3" value="Boat">
            <label for="vehicle3">Edit Role</label><br>
            <input type="checkbox" name="vehicle3" value="Boat">
            <label for="vehicle3">Delete role</label><br><br>

        </div>
            <hr class="new1">
        <div class="form-group">

            <h4>Supplier</h4>
            <input type="checkbox" name="vehicle1" value="Bike">
            <label for="vehicle1">View all supplier</label><br>
            <input type="checkbox" name="vehicle2" value="Car">
            <label for="vehicle2"> View own supplier</label><br>
            <input type="checkbox" name="vehicle3" value="Boat">
            <label for="vehicle3">Add supplier</label><br>
            <input type="checkbox" name="vehicle3" value="Boat">
            <label for="vehicle3">Edit supplier</label><br>
            <input type="checkbox" name="vehicle3" value="Boat">
            <label for="vehicle3">Delete supplier</label><br><br>

        </div>
            <hr class="new1">
        <div class="form-group">

            <h4>Customer</h4>
            <input type="checkbox" name="vehicle1" value="Bike">
            <label for="vehicle1">View all customer</label><br>
            <input type="checkbox" name="vehicle2" value="Car">
            <label for="vehicle2"> View own customer</label><br>
            <input type="checkbox" name="vehicle3" value="Boat">
            <label for="vehicle3">Add customer</label><br>
            <input type="checkbox" name="vehicle3" value="Boat">
            <label for="vehicle3">Edit customer</label><br>
            <input type="checkbox" name="vehicle3" value="Boat">
            <label for="vehicle3">Delete customer</label><br><br>

        </div>
            <hr class="new1">
        <div class="form-group">

            <h4>Product</h4>
            <input type="checkbox" name="vehicle1" value="Bike">
            <label for="vehicle1">View product</label><br>
            <input type="checkbox" name="vehicle2" value="Car">
            <label for="vehicle2"> Add product</label><br>
            <input type="checkbox" name="vehicle3" value="Boat">
            <label for="vehicle3">Edit product</label><br>
            <input type="checkbox" name="vehicle3" value="Boat">
            <label for="vehicle3">Delete product</label><br>
            <input type="checkbox" name="vehicle3" value="Boat">
            <label for="vehicle3">Add Opening Stock</label><br>
            <input type="checkbox" name="vehicle3" value="Boat">
            <label for="vehicle3">View Purchase Price</label><br><br>
        </div>
            <hr class="new1">
    </div>
</form>