import { Component, OnInit } from '@angular/core';
import { UserModel } from 'src/app/models/UserModel';
import { CommonService } from 'src/app/services/common.service';
import { ToasterService } from 'angular2-toaster';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/modules/authentication/service/login.service';
import { HttpDataService } from 'src/app/services/http-data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public userModel: UserModel = new UserModel();
  public response: string;
  public systemIp: string;
  constructor(private _toasterService: ToasterService,
    private router: Router,
    private service: LoginService,
    private _httpService: HttpDataService,
    private _spinner: NgxSpinnerService,
    private _commonService: CommonService) { }
  username: null;
  password: null


  ngOnInit() {

  }

  canLogIn() {
    return this.username && this.password;
  }

  async doLogin(username: string, password: string) {

    if (username && password) {
      this._commonService.parentSpinnerShow();
      let userModelSelected = new UserModel();
      // await $.getJSON('https://api.ipify.org?format=json', function (data) {
      //   userModelSelected.IpAddress = data.ip;
      // });
      userModelSelected.UserName = username;
      userModelSelected.Password = password;
      userModelSelected.UserAgent = window.navigator.userAgent;

      this.service.Login(userModelSelected).subscribe(res => {

        if (res.IsSuccess) {
          localStorage.setItem("UserName", userModelSelected.UserName);
          localStorage.setItem("Password", userModelSelected.Password);
          localStorage.setItem("Id", res.Data.Id);
          localStorage.setItem("Email", res.Data.Email);
          this.router.navigateByUrl('/Verification');




        }
        else {
          this.response = res.Message;
          this._commonService.parentSpinnerHide();
        }
      });
    }
    else {
      this.response = "Please enter username and password!";
      this._commonService.parentSpinnerHide();
    }
  }

  goToForgotPassword() {
    this.router.navigate(['./ForgotPassword']);
  }

}
