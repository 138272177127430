import { Component, OnInit } from '@angular/core';
import { RoleService } from '../../role/role.service';
import { rolemodel } from 'src/app/models/role.model';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { FilterUserRoles } from 'src/app/models/commonDTO/userRoles.model';
import { ActionMode } from 'src/app/models/enum';
import { ToasterService } from 'angular2-toaster';
import  swal from 'sweetalert';
import { Route } from '@angular/router';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { KeyValuePair } from 'src/app/models/KeyValuePair.model';

@Component({
  selector: 'app-test-manage-roles',
  templateUrl: './test-manage-roles.component.html',
  styleUrls: ['./test-manage-roles.component.css']
})
export class TestManageRolesComponent implements OnInit {
  public RoleModelList :Array<rolemodel>;
  public RoleModel: rolemodel =new rolemodel();
  public filterUserRoles: FilterUserRoles =new FilterUserRoles()

  public inquiryData : any =[];
  public showList: boolean =false;
  public actionMode: ActionMode =ActionMode.Add;
  p:number =1;
  pagesize :number =15;
  public addRoleForm : FormGroup;
  public submitted =false;
  UserfilterForm :FormGroup;
  PageSize: any;

  constructor(private _roleService: RoleService,
    private formBuilder: FormBuilder,
    private _toasterService: ToasterService) { }


  ngOnInit(): void {
    this.BindRoleList();
    this.InitializeForm();
    this.filterForm();
  }
  filterForm(){
    this.UserfilterForm =this.formBuilder.group({
      UserRoleName: ['',Validators.nullValidator],
      IsActive: ['null',Validators.nullValidator],
    });
  }
  InitializeForm(){
    this.addRoleForm =this.formBuilder.group({
      UserRoleName :['',Validators.required],
      Description: ['',Validators.required],
      IsActive :['',Validators.nullValidator],
    });
    this.actionMode =ActionMode.Add;
  }
  get f() {
    return this.addRoleForm.controls;
  }
  public get ActionModeEnum(): typeof ActionMode{
    return ActionMode;
  }
  Save() {
    this.submitted = true;
    if (this.addRoleForm.invalid) {
      this.addRoleForm.markAsTouched();
      return;
    }
    else {
      var self = this;
      self.RoleModel = this.setFormDataToModel(this.RoleModel);
      self._roleService.saveUserRoles(self.RoleModel).subscribe((response) => {
        //console.log('postData : ', response);
        if (response.IsSuccess) {
          this.BindRoleList();
          this._toasterService.pop('success', "Success", response.Message);
          this.Reset();
          this.ClosePopup();
        }
        else {
          this._toasterService.pop('error', 'Error', response.Message);
        }
      });

    }

  }
  setFormDataToModel(Rolemodule: rolemodel) {
    Rolemodule.UserRoleName = this.addRoleForm.get('UserRoleName').value;
    Rolemodule.Description = this.addRoleForm.get('Description').value;
    Rolemodule.IsActive = this.addRoleForm.get('IsActive').value;
    return Rolemodule;
  }
  ConvertJsonToModel(jsonStr) {
    return new Map(JSON.parse(jsonStr));
  }


  Reset() {
    this.submitted = false;
    this.UserfilterForm.reset();
    this.addRoleForm.reset();
    this.filterForm();
    this.RoleModel = new rolemodel();
    this.BindRoleList();
  }
  BindRoleList() {
    this.filterUserRoles.Id = null;
    this._roleService.getUserRoles(this.filterUserRoles).subscribe(response => {
      if (response.IsSuccess) {
        this.inquiryData = response.Data;
      }
      else {
        this.inquiryData = [];
      }
    });
  }
  SearchFilter() {
    var self = this;
    self.filterUserRoles = self.setRoleFormData(self.filterUserRoles);
    self.BindRoleList();
  }
  setRoleFormData(filterUserRoles: FilterUserRoles) {
    filterUserRoles.Id = this.UserfilterForm.get('Id') != null ? this.UserfilterForm.get('Id').value : null;
    filterUserRoles.UserRoleName = this.UserfilterForm.get('UserRoleName') != null ? this.UserfilterForm.get('UserRoleName').value : null;
    filterUserRoles.Status = this.UserfilterForm.get('IsActive').value == "null" ? null : this.UserfilterForm.get('IsActive').value == "true" ? true : false;
    return filterUserRoles;
  }
  AddRole() {
    this.showList = true;
    this.InitializeForm();
  }
  ClosePopup() {
    this.showList = false;
  }
  PreviewRecord(currentRow: rolemodel) {
    var self = this;
    //let dataToPass: KeyValuePair = { key: "id", value: String(currentRow.Id) };
    self.filterUserRoles.Id = currentRow.Id;
    self._roleService.getUserRoles(self.filterUserRoles).subscribe((response) => {
      console.log('PreviewRecord : ', response);
      if (response.IsSuccess) {
        self.RoleModel = response.Data[0];
        self.showList = true;
        self.addRoleForm.patchValue({
          UserRoleName: self.RoleModel.UserRoleName,
          Description: self.RoleModel.Description,
          IsActive: self.RoleModel.IsActive,
        });
        self.actionMode = ActionMode.Preview;
        self.addRoleForm.disable();
      }
    });
  }
  EditRecord(currentRow: rolemodel) {
    var self = this;
    // let dataToPass: KeyValuePair = { key: "id", value: String(currentRow.Id) };
    self.filterUserRoles.Id = currentRow.Id;
    self._roleService.getUserRoles(self.filterUserRoles).subscribe((response) => {
      console.log('EditRecord : ', response);
      if (response.IsSuccess) {
        self.RoleModel = response.Data[0];
        self.showList = true;
        self.addRoleForm.patchValue({
          UserRoleName: self.RoleModel.UserRoleName,
          Description: self.RoleModel.Description,
          IsActive: self.RoleModel.IsActive,
        });
        self.actionMode = ActionMode.Edit;
      }
    });
  }
  DeleteRecord(id: number) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      dangerMode: true
    })
      .then((willDelete) => {
        if (willDelete) {
          let dataToPass: KeyValuePair = { key: "id", value: String(id) };
          this._roleService.deleteUserRoles(dataToPass).subscribe(response => {
            if (response.IsSuccess) {
              this._toasterService.pop('success', "Success", response.Message);
              this.BindRoleList();
            }

            else {
              this._toasterService.pop('error', 'Error', response.Message);
            }
          });
          // swal("Functionality Not Implemented !", {
          //   icon: "success",
          // });

        } else {
          swal("Your file is safe!");
        }
      });
  }
}
