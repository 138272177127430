import { importType } from '@angular/compiler/src/output/output_ast';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import{MenuPermissionComponent} from './menu-permission/menu-permission.component';
import{MenuComponent} from './menu/menu.component';
import{MenuListComponent} from './menu-list/menu-list.component';
//import { AdminGuardGuard } from 'src/app/guard/admin-guard.guard';import{MenuPermissionComponent} from './menu-permission/menu-permission.component';
const routes: Routes = [
  {
    path: 'menu/menu-permission',
    component: MenuPermissionComponent//,canActivate:[AdminGuardGuard]
  },
  {
    path: 'menu',
    component: MenuComponent//,canActivate:[AdminGuardGuard]
  },
  {
    path: 'menu/menu-list',
    component: MenuListComponent//,canActivate:[AdminGuardGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MenuRoutingModule { }
