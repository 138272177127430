<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Menu</h1>
                <h5 class="modal-title">
                </h5>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active" *ngIf="actionMode == ActionModeEnum.Add">Add Menu</li>
                    <li class="breadcrumb-item active" *ngIf="actionMode == ActionModeEnum.Edit">Edit Menu</li>
                    <li class="breadcrumb-item active" *ngIf="actionMode == ActionModeEnum.Preview">Preview Menu</li>
                    <div class="box-tools pull-right  ml-lg-2">
                        <a [routerLink]="'/menu/menu-list'" class="btn bg-navy btn-sm" title="Show List"><i
                                class="fa fa-reply"></i> Show List</a>
                    </div>
                </ol>
            </div>
        </div>
    </div>
</section>

<section class="content add-detail">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div [formGroup]="menuForm" class="card collapsed-card">
                    <div class="card-body" style="display: block;"
                        [ngClass]="actionMode == ActionModeEnum.Preview ? 'previewModeColor':'defaultModeColor'">

                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Select Level</label><span class="redStar">*</span>
                                    <select class="form-control" (change)="addlevel2($event.target.value)"
                                        formControlName="FirstLevelId" id="FirstLevelId"
                                        [ngClass]="{ 'is-invalid': submitted && f.FirstLevelId.errors }">
                                        <option value="">-- Select --</option>
                                        <option value="1">Level 1 </option>
                                        <option value="2">Level 2 </option>
                                        <option value="3">Level 3 </option>
                                    </select>
                                </div>

                                <div class="form-group" *ngIf="ShowSecondLevelId">
                                    <label>Select Level 1</label><span class="redStar">*</span>
                                    <select class="form-control" (change)="childLevel($event.target.value)"
                                        formControlName="SecondLevelId" id="SecondLevelId"
                                        [ngClass]="{ 'is-invalid': submitted && f.SecondLevelId.errors }">
                                        <option value="">-- Select --</option>
                                        <option *ngFor="let data of firstMenuList" [value]="data.Id">{{data.Name}}</option>

                                    </select>

                                </div>
                                <div class="form-group"
                                    *ngIf="ShowThirdLevelId && level == 3 && childMenuList && childMenuList.length>0">
                                    <label>Select Level 2</label><span class="redStar">*</span>
                                    <select class="form-control" (change)="lastchild($event.target.value)"
                                        formControlName="ThirdLevelId" id="ThirdLevelId"
                                        [ngClass]="{ 'is-invalid': submitted && f.ThirdLevelId.errors }">
                                        <option value="">-- Select --</option>
                                        <option *ngFor="let child of childMenuList" [value]="child.Id">{{child.Name}}</option>
                                    </select>

                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Menu Name</label><span class="redStar">*</span>
                                    <input type="text" class="form-control" placeholder="Menu Name"
                                        formControlName="MenuName" maxlength="50"
                                        [ngClass]="{ 'is-invalid': submitted && f.MenuName.errors }">
                                    <div *ngIf="submitted && f.MenuName.errors" class="invalid-feedback">
                                        <div *ngIf="f.MenuName.errors.required">
                                            Menu Name is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Menu Description</label><span class="redStar">*</span>
                                    <input type="text" class="form-control" placeholder="Menu Description"
                                        formControlName="MenuDescription" maxlength="50"
                                        [ngClass]="{ 'is-invalid': submitted && f.MenuDescription.errors }">
                                    <div *ngIf="submitted && f.MenuDescription.errors" class="invalid-feedback">
                                        <div *ngIf="f.MenuDescription.errors.required">
                                            Menu Description is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Menu Icon</label><span class="redStar">*</span>
                                    <input type="text" class="form-control" placeholder="Menu Icon"
                                        formControlName="Icon" [ngClass]="{ 'is-invalid': submitted && f.Icon.errors }">
                                    <div *ngIf="submitted && f.Icon.errors" class="invalid-feedback">
                                        <div *ngIf="f.Icon.errors.required">
                                            Menu Icon is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Menu Url</label><span class="redStar">*</span>
                                    <input type="text" class="form-control" placeholder="Menu Url" formControlName="Url"
                                        [ngClass]="{ 'is-invalid': submitted && f.Url.errors }">
                                    <div *ngIf="submitted && f.Url.errors" class="invalid-feedback">
                                        <div *ngIf="f.Url.errors.required">
                                            Menu Url is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-4">
                                <div class="form-group">
                                    <label>Select Status</label><span class="redStar">*</span>
                                    <select class="form-control" formControlName="status" id="status"
                                        [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                                        <option value="">-- Select --</option>
                                        <option>Active </option>
                                        <option>Inactive </option>

                                    </select>
                                </div>
                            </div> -->
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Status</label>
                                    <input type="checkbox" name="my-checkbox"
                                        class="form-check-input chkMargin" id="statusSwitch" formControlName="status"
                                        data-bootstrap-switch data-off-color="danger"  
                                        >
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-footer" style="display: block;"
                        *ngIf="actionMode == ActionModeEnum.Add || actionMode == ActionModeEnum.Edit">
                        <div class="row">
                            <div class="col-9">
                                <button type="button" (click)="Reset()" class="btn btn-secondary float-left">
                                    <i class="fas fa-times"></i>
                                    Reset
                                </button>
                            </div>
                            
                            <div class="col-3">
                                <button type="button" (click)="Save()" class="btn btn-primary float-right"> <i
                                        class="fas fa-save"></i>
                                    Save</button>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>
<!-- /.content -->