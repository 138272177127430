import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuardGuard } from '../../guard/admin-guard.guard';
import{ManageRoles} from 'src/app/modules/role/manage-roles/manage-roles.component';
const routes: Routes = [
 
  { path: 'role', component: ManageRoles}//canActivate:[AdminGuardGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoleRoutingModule { }