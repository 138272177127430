import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ToasterConfig } from 'angular2-toaster';
import { NavigationCancel, NavigationError, Router, ActivatedRoute, RouterEvent, NavigationStart, NavigationEnd } from '@angular/router';
import { HttpDataService } from './services/http-data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { CommonService } from './services/common.service';
import {enableProdMode} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'CraftsModa';
  readyToLoad: boolean = true;
  isToken: boolean = false;
  param: string;

  constructor(private cdRef: ChangeDetectorRef,
    private _dataService: HttpDataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _spinner: NgxSpinnerService,
    private _commonService: CommonService) {
    this.router.events.subscribe((event: RouterEvent) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this._commonService.parentSpinnerShow();
          break;
        }

        case event instanceof NavigationEnd:
          {
            // let isTokenExist = localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : false;
            // if (!isTokenExist) {
            //   this.router.navigateByUrl('/Login');
            // }
            // else {
            //   this._dataService.SetHeader();
            //   this.readyToLoad = false;
            // }
            //TODO:
            this._commonService.parentSpinnerHide();

            break;
          }
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this._commonService.parentSpinnerShow();
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
       // console.log('url',event.url);
        if (event.url.indexOf('/Login') > -1 || event.url.indexOf('/Verification') > -1   || event.urlAfterRedirects.indexOf('/Login') > -1) {
          this.readyToLoad = true;
        } else {
          this.readyToLoad = false;
        }
      }
    });

  }



  //Toaster Configuration
  public toasterConfig: ToasterConfig = new ToasterConfig({
    showCloseButton: false,
    tapToDismiss: true,
    timeout: 4000
  });
}
