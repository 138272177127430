<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Menu List</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">Menu List</li>
        </ol>
      </div>
    </div>
  </div><!-- /.container-fluid -->
</section>

<section class="content add-detail">
  <div class="container-fluid">
    <div class="row">
      <!-- <div class="col-12">
        <div [formGroup]="searchForm" class="card collapsed-card">

          <div class="card-header b-t-info">
            <h3 class="card-title" data-card-widget="collapse" data-toggle="tooltip">Search</h3>

            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                title="Collapse">
                <i class="fas fa-search"></i></button>
            </div>
          </div>
          <div class="card-body" style="display: block;">
            <div class="row">
              <div class="col-5">
                <div class="form-group">
                  <label>Menu Level</label>
                  <select class="form-control" formControlName="MenuLevel">
                    <option selected="true" value=""> -- Select --</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <div class="col-4">
              <div class="form-group">
                <label>MenuName</label>
                <input type="text" class="form-control" placeholder="Menu Name" formControlName="MenuName">
              </div>
            </div>
            <div class="col-2 row btn-res-1">
              <div class="form-group btn-res">
                <button type="button" (click)="SearchFilter()" class="btn btn-info" > <i class="fas fa-search"></i>
                  Search</button>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div> -->
      <div class="card col-12">
        <div class="card-header b-t-olive">
          <h2 class="card-title">Menu List</h2>
        </div>
        <div class="card-body">
          <div class="panel-body">

            <table class="table table-bordered">
              <thead>
                <tr>
                  <th></th>
                  <th scope="col" class="text-center">MenuName</th>
                  <th scope="col" class="text-center">MenuDescription</th>
                  <th scope="col" class="text-center">Url</th>
                  <th scope="col" class="text-center">Icon</th>
                  <th scope="col" class="text-center">status</th>
                  <th scope="col" class="text-center">Action</th>

                </tr>
              </thead>

              <!-- <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="todo"
              [cdkDropListConnectedTo]="doneList" class="list" (cdkDropListDropped)="drop($event)">
            <div class="list-item" *ngFor="let item of todo" cdkDrag>{{item}}</div>
          </div>
        </div> -->


              <tbody *ngFor="let x of menulist;let i=index "  >
                <tr class=" cursror-pointer text-center dropdown rowFirst"  draggable="true">
                  <td class="accordion-toggle" data-toggle="collapse" aria-expanded="false"
                    [attr.data-target]="'#game4Details' + i"><i class="fa fa-plus" *ngIf="x.ChildMenu?.length > 0"
                      aria-hidden="true"></i></td>
                  <td>{{x.MenuName}}</td>
                  <td>{{x.MenuDescription}}</td>
                  <td>{{x.Url}}</td>
                  <td>{{x.Icon}}</td>

                  <td> <span *ngIf="x.IsActive" class="badge bg-success">Active</span>
                    <span *ngIf="!x.IsActive" class="badge bg-gradient-danger">InActive</span>
                  </td>
                  <td class="text-center py-0 align-middle  ">
                    <div class="btn-group btn-group-sm">
                      <a href="javascript:void(0);" (click)="PreviewRecord(x)" title="Preview"
                        class="btn btn-secondary"><i class="fas fa-eye"></i></a>
                      <a href="javascript:void(0);" (click)="EditRecord(x)" title="Edit" class="btn btn-info"><i
                          class="fas fa-edit"></i></a>

                    </div>
                  </td>
                </tr>


                
                <ng-container *ngFor="let childItem of x.ChildMenu;let j=index">
                  <tr [attr.id]="'game4Details' + i" class="accordian-body rowSecond collapse text-center">
                    <td class="accordion-toggle" data-toggle="collapse" aria-expanded="false"
                      [attr.data-target]="'#game5Details' + i + j"><i class="fa fa-plus"
                        *ngIf="childItem.ChildMenu?.length > 0" aria-hidden="true"></i></td>
                    <td>{{childItem.MenuName}}</td>
                    <td>{{childItem.MenuDescription}}</td>
                    <td>{{childItem.Url}}</td>
                    <td>{{childItem.Icon}}</td>

                    <td> <span *ngIf="childItem.IsActive" class="badge bg-success">Active</span>
                      <span *ngIf="!childItem.IsActive" class="badge bg-gradient-danger">InActive</span>
                    </td>
                    <td class="text-center py-0 align-middle  ">
                      <div class="btn-group btn-group-sm">
                        <a href="javascript:void(0);" (click)="PreviewRecord(childItem)" title="Preview"
                          class="btn btn-secondary"><i class="fas fa-eye"></i></a>
                        <a href="javascript:void(0);" (click)="EditRecord(childItem)" title="Edit"
                          class="btn btn-info"><i class="fas fa-edit"></i></a>

                      </div>
                    </td>
                  </tr>
                  <tr [attr.id]="'game5Details' + i + j " class="accordian-body collapse text-center rowLast"
                    *ngFor="let lastChild of childItem.ChildMenu">
                    <td></td>
                    <td class=" cursr-4 text-center accordion-toggle" data-toggle="collapse">{{lastChild.MenuName}}</td>
                    <td>{{lastChild.MenuDescription}}</td>
                    <td>{{lastChild.Url}}</td>
                    <td>{{lastChild.Icon}}</td>
                    <td> <span *ngIf="lastChild.IsActive" class="badge bg-success">Active</span>
                      <span *ngIf="!lastChild.IsActive" class="badge bg-gradient-danger">InActive</span>
                    </td>
                    <td class="text-center py-0 align-middle">
                      <div class="btn-group btn-group-sm">
                        <a href="javascript:void(0);" (click)="PreviewRecord(lastChild)" title="Preview"
                          class="btn btn-secondary"><i class="fas fa-eye"></i></a>
                        <a href="javascript:void(0);" (click)="EditRecord(lastChild)" title="Edit"
                          class="btn btn-info"><i class="fas fa-edit"></i></a>

                      </div>


                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="x.ChildMenu.length > 0">

                </ng-container>

              </tbody>
            </table>
            <!-- <pagination-controls class="table-pagination" (pageChange)="p=$event" maxSize="9" directionLinks="true"
                autoHide="true" responsive="true" previousLabel="Previous" nextLabel="Next"
                screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                screenReaderCurrentLabel="You're on page">
              </pagination-controls>
              <div class="dtastyle" *ngIf="menulist?.length == 0">
                <span class="search_no_results  ">
                  No data found
                </span>
              </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>


</section>