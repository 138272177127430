export class CompanyPaymentTransactions {
    CreditAccountId: number;
    DebitAccountId: number;
    Amount: number;
    PaymentDate: date;
    Note: string;
}
export class date {
    day: number;
    month: number;
    year: number;
}